import React from "react";
import Layout from "../../Components";

function Blog() {
  return (
    <div>
      <Layout>
        <section class="relative md:py-10">
          <img
            class="hidden lg:block absolute top-0 left-0 mt-20"
            src="zeus-assets/icons/dots/blue-dot-left-bars.svg"
            alt=""
          />
          <img
            class="hidden lg:block absolute top-0 right-0 mt-52"
            src="zeus-assets/icons/dots/yellow-dot-right-shield.svg"
            alt=""
          />
          <div class="container md:px-36 mx-auto">
            <div class="max-w-3xl mb-20 mx-auto text-center">
              {/* <span class="text-xs text-blue-400 font-semibold">What&apos;s new at Shuffle</span> */}
              <h2 class="mt-8 mb-10 text-4xl font-semibold font-heading">
              Discovering the Power of Emmuniti
              </h2>
              <p class="text-xl text-gray-500">
                "Unlock wellness with Emmuniti – a fusion of ancient Ayurvedic
                wisdom and modern science, designed to bolster your immunity and
                promote holistic well-being."
              </p>
            </div>
            <div class="flex flex-wrap -m-3 mb-16">
              <div class="w-full md:w-1/2 lg:w-1/3 p-3">
                <div class="p-6 border rounded-xl">
                  <div class="relative h-52 mb-6">
                    <span class="absolute top-0 right-0 mt-4 mr-4 text-xs text-white px-2 py-1 font-semibold bg-gray-600 bg-opacity-50 rounded-md uppercase">
                      HEALTH
                    </span>
                    <img
                      class="w-full h-full object-cover rounded-lg"
                      src="https://images.unsplash.com/photo-1544717297-fa95b6ee9643?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80"
                      alt=""
                    />
                  </div>
                  {/* <span class="inline-block mb-4 text-xs text-gray-500">10 jun 2020 19:40</span> */}
                  <h2 class="mb-4 text-xl font-semibold font-heading">
                    Sipping Your Way to Better Health: The Health Benefits of
                    Instant Green Coffee
                  </h2>
                  <p class="mb-4 text-gray-500 leading-relaxed">
                    Obesity has become a significant health concern globally,
                    affecting millions of people worldwide. In addition
                  </p>
                  <a
                    class="text-lg font-medium text-red-500 underline hover:no-underline"
                    href="#"
                  >
                    Read more
                  </a>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 p-3">
                <div class="p-6 border rounded-xl">
                  <div class="relative h-52 mb-6">
                    <span class="absolute top-0 right-0 mt-4 mr-4 text-xs text-white px-2 py-1 font-semibold bg-gray-600 bg-opacity-50 rounded-md uppercase">
                      JOINT PAIN
                    </span>
                    <img
                      class="w-full h-full object-cover rounded-lg"
                      src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80"
                      alt=""
                    />
                  </div>
                  {/* <span class="inline-block mb-4 text-xs text-gray-500">10 jun 2020 19:40</span> */}
                  <h2 class="mb-4 text-xl font-semibold font-heading">
                    Natural solutions for joint pain: An Ayurvedic approach!
                  </h2>
                  <p class="mb-4 text-gray-500 leading-relaxed">
                    Health experts say that more than 80% of Indians suffer from
                    knee pain, and they
                  </p>
                  <a
                    class="text-lg font-medium text-red-500 underline hover:no-underline"
                    href="#"
                  >
                    Read more
                  </a>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 p-3">
                <div class="p-6 border rounded-xl">
                  <div class="relative h-52 mb-6">
                    <span class="absolute top-0 right-0 mt-4 mr-4 text-xs text-white px-2 py-1 font-semibold bg-gray-600 bg-opacity-50 rounded-md uppercase">
                      STRESS
                    </span>
                    <img
                      class="w-full h-full object-cover rounded-lg"
                      src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&amp;ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80"
                      alt=""
                    />
                  </div>
                  {/* <span class="inline-block mb-4 text-xs text-gray-500">10 jun 2020 19:40</span> */}
                  <h2 class="mb-4 text-xl font-semibold font-heading">
                    Break Free from the Cycle of Depression and Irresponsibility
                  </h2>
                  <p class="mb-4 text-gray-500 leading-relaxed">
                    Depression is a mental disorder that affects millions of
                    people worldwide. It can profoundly impact
                  </p>
                  <a
                    class="text-lg font-medium text-red-500 underline hover:no-underline"
                    href="#"
                  >
                    Read more
                  </a>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 p-3">
                <div class="p-6 border rounded-xl">
                  <div class="relative h-40 mb-6">
                    <span class="absolute top-0 right-0 mt-4 mr-4 text-xs text-white px-2 py-1 font-semibold bg-gray-600 bg-opacity-50 rounded-md uppercase">
                      UNCATEGORIZED
                    </span>
                    <img
                      class="w-full h-full object-cover rounded-lg"
                      src="https://images.unsplash.com/photo-1557804506-d8017c1e4856?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1001&amp;q=80"
                      alt=""
                    />
                  </div>
                  {/* <span class="inline-block mb-4 text-xs text-gray-500">10 jun 2020 19:40</span> */}
                  <h2 class="mb-4 text-xl font-semibold font-heading">
                    The Future of Herbal Medicines: How Technology is Changing
                    The Way We Use Herbs for Health?
                  </h2>
                  <p class="mb-4 text-gray-500 leading-relaxed">
                    Herbal medicines have been popular for centuries for
                    treating a variety of ailments. With the
                  </p>
                  <a
                    class="text-lg font-medium text-red-500 underline hover:no-underline"
                    href="#"
                  >
                    Read more
                  </a>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 p-3">
                <div class="p-6 border rounded-xl">
                  <div class="relative h-40 mb-6">
                    <span class="absolute top-0 right-0 mt-4 mr-4 text-xs text-white px-2 py-1 font-semibold bg-gray-600 bg-opacity-50 rounded-md uppercase">
                      WEIGHT MANAGEMENT
                    </span>
                    <img
                      class="w-full h-full object-cover rounded-lg"
                      src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80"
                      alt=""
                    />
                  </div>
                  {/* <span class="inline-block mb-4 text-xs text-gray-500">10 jun 2020 19:40</span> */}
                  <h2 class="mb-4 text-xl font-semibold font-heading">
                    Can losing weight reverse your diabetes?
                  </h2>
                  <p class="mb-4 text-gray-500 leading-relaxed">
                    For many individuals, weight is a delicate topic, and
                    attaining a healthy, ideal weight is
                  </p>
                  <a
                    class="text-lg font-medium text-red-500 underline hover:no-underline"
                    href="#"
                  >
                    Read more
                  </a>
                </div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 p-3">
                <div class="p-6 border rounded-xl">
                  <div class="relative h-40 mb-6">
                    <span class="absolute top-0 right-0 mt-4 mr-4 text-xs text-white px-2 py-1 font-semibold bg-gray-600 bg-opacity-50 rounded-md uppercase">
                      WOMEN'S FASHION
                    </span>
                    <img
                      class="w-full h-full object-cover rounded-lg"
                      src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&amp;ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80"
                      alt=""
                    />
                  </div>
                  {/* <span class="inline-block mb-4 text-xs text-gray-500">10 jun 2020 19:40</span> */}
                  <h2 class="mb-4 text-xl font-semibold font-heading">
                    How to Bring Natural Glow to Your Skin With 100% Herbal
                    Ingredients?
                  </h2>
                  <p class="mb-4 text-gray-500 leading-relaxed">
                    Turmeric has existed for centuries, known for its healing
                    properties traditionally used in Asian cuisines.
                  </p>
                  <a
                    class="text-lg font-medium text-red-500 underline hover:no-underline"
                    href="#"
                  >
                    Read more
                  </a>
                </div>
              </div>
            </div>
            <div class="text-center">
              <a
                class="px-6 py-4 text-sm text-white font-semibold bg-[#0B8CAD] hover:bg-[#90154B] rounded transition duration-200"
                href="#"
              >
                View More Articles
              </a>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
}

export default Blog;
