import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import Image from '../../Assets/Duplicate/Desk---INTL-3-jan-2021.jpeg'
import { Form, Input } from "antd";
import { checkAuth, authenticateSelector } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components";
import { cartProductSelector } from "../../api/cartSlice";
import { createOrder } from "../../api/order";
import { orderSelector } from "../../api/order";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import AddCoupon from "./addcoupon";
import { couponSelector } from "../../api/coupon";
import moment from "moment";
import axios from "axios";
import { keyUri } from "../../key";
import { fetchUserOrder, fetchOneOrder } from "../../api/order";
import { ShippingAmount } from "../../api/order";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const OrderAddress = () => {
  const nav = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { cartProducts, total } = useSelector(cartProductSelector);
  const { shippingcharge } = useSelector(orderSelector);
  // const { user } = useSelector(authenticateSelector);
  const { coupons } = useSelector(couponSelector);
  const [selectedOption, setSelectedOption] = useState("payOnline");
  const { order, loading } = useSelector(orderSelector);

  console.log(coupons)
  console.log(order);


  const lastOrder = order && order.length > 0 ? order[order.length - 1] : null;

  console.log(lastOrder);
  const totalamount = total + (shippingcharge?.shippingAmount || 0) - (coupons?.discountAmount || 0);


  const [user, setUser] = useState('')
  console.log(user);


  useEffect(() => {
    const userInfo = localStorage.getItem("userinfo")
      ? JSON.parse(localStorage.getItem("userinfo"))
      : null;

    setUser(userInfo);
  }, [])



console.log(totalamount)
  useEffect(() => {
    dispatch(fetchUserOrder(user?._id));
  }, [user]);

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  const [formData, setFormData] = useState({
    delivred_username: "",
    delivred_district: "",
    delivred_pincode: "",
    delivred_addrees: "",
    delivred_phonenumber: "",
    delivred_town: "",
    delivred_state: "",
  });
  console.log(formData);

  // Mock data for demonstration purposes, replace it with your actual data

  useEffect(() => {
    if (lastOrder) {
      const {
        delivred_username,
        delivred_district,
        delivred_pincode,
        delivred_addrees,
        delivred_phonenumber,
        delivred_town,
        delivred_state,
      } = lastOrder;

      setFormData({
        delivred_username,
        delivred_district,
        delivred_pincode,
        delivred_addrees,
        delivred_phonenumber,
        delivred_town,
        delivred_state,
      });

      // Set initial form values using form.setFieldsValue
      form.setFieldsValue({
        delivred_username,
        delivred_district,
        delivred_pincode,
        delivred_addrees,
        delivred_phonenumber,
        delivred_town,
        delivred_state,
      });
      dispatch(ShippingAmount({ pincode: delivred_pincode }));
    }
  }, [lastOrder, form,dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "delivred_pincode") {
      // Dispatch the shipping API call with the new pin code
      dispatch(ShippingAmount({ pincode: value }));
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   setFormData({ ...formData, [name]: value });
  // };

  const handleOptionChange1 = (event) => {
    setSelectedOption(event.target.value);
  };

  //   const data ={
  //     name: "suhas",
  //     amount: totalamount,
  //      number: "9858",
  //     // MUID: "M1WSO74VWVN3" ,
  //     // transactionId: "emmuniti12345"+ Date.now()
  // }

  // const handlePayOnline = async (e) => {
  //   e.preventDefault();

  //   if (selectedOption === "payOnline") {
  //     // Check if any of the address fields are empty
  //     if (Object.values(formData).some((value) => value === "")) {
  //       alert("Please fill in all address fields to pay online");
  //       return;
  //     }

  //     try {
  //       const orderData = {
  //         orderProducts: cartProducts,
  //         user: user?._id,
  //         address: user?.address,
  //         status: "unpaid",
  //         payment: selectedOption,
  //         total: coupons?.amount ? coupons.amount : totalamount,
  //         sub_total: total,
  //         coupons: coupons?.coupon?._id,
  //         shipping_charge: shippingcharge?.shippingAmount,
  //         deliverd_date: shippingcharge?.deliveryDate,
  //         delivred_username: formData?.delivred_username,
  //         delivred_district: formData?.delivred_district,
  //         delivred_pincode: formData?.delivred_pincode,
  //         delivred_addrees: formData?.delivred_addrees,
  //         delivred_phonenumber: formData?.delivred_phonenumber,
  //         delivred_town: formData?.delivred_town,
  //         delivred_state: formData?.delivred_state,
  //       };

  //       localStorage.setItem("orderData", JSON.stringify(orderData));
  //       const response = await axios.post(keyUri.BACKEND_URI + "/payment", {
  //         ...orderData,
  //       });

  //       console.log(response.data);

  //       const paymentUrl = response.data.paymentRedirectUrl;

  //       window.location.href = paymentUrl;

  //       if (response.data.success) {
  //         // Dispatch createOrder action after a successful payment
  //         // You may need to pass the orderData to createOrder depending on your action definition
  //         useDispatch()(createOrder(orderData));
  //       }

  //       // dispatch(createOrder(orderData));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };


  const handlePayOnline = async (e) => {
    e.preventDefault();
    if (selectedOption === "payOnline") {
      if (Object.values(formData).some((value) => value === "")) {
        alert("Please fill in all address fields to pay online");
        return;
      }
      // const API_URL = keyUri.BACKEND_URI + `/razorpayp/`
      // const orderUrl = `${API_URL}orderp`;
      const orderData = {
        orderProducts: cartProducts,
        user: user?._id,

        address: user?.address,
        status: "Paid",
        payment: selectedOption,
        total: coupons?.amount ? coupons.amount : totalamount,
        sub_total: total,
        coupons: coupons?.coupon ? coupons._id : null,
        delivred_state: shippingcharge?.shipping,
        deliverd_date: shippingcharge?.deliveryDate,
        delivred_username: formData?.delivred_username,
        delivred_district: formData?.delivred_district,
        delivred_pincode: formData?.delivred_pincode,
        delivred_addrees: formData?.delivred_addrees,
        delivred_phonenumber: formData?.delivred_phonenumber,
        deliverd_town: formData?.deliverd_town,
        delivred_state: formData?.delivred_state,
        // delivred_email: formData?.delivred_email,
      };
      const response = await axios.post(
        keyUri.BACKEND_URI + `/product-order/`,
        orderData
      );
      const { data } = response;
      console.log("App -> razorPayPaymentHandler -> data", data);

      const options = {
        key: "rzp_live_QgrIQDbLYRWc2j",
        name: "Emmuniti",
        order_id: data.id,
        handler: async (response) => {
          try {
            const paymentId = response.razorpay_payment_id;
            const url = keyUri.BACKEND_URI + `/capture-product/${paymentId}`;
            const captureResponse = await axios.post(url, { total });
            console.log({ captureResponse });
            const successObj = JSON.parse(captureResponse.data);
            const captured = successObj.captured;
            console.log(
              "App -> razorPayPaymentHandler -> captured",
              successObj
            );

            //  if(captured){
            //      console.log('success')

            //     await axios.post(keyUri.BACKEND_URI + '/orderp', {user,title, info, total, successObj, productId

            //     }, config)
            //  }

            // toast.success("Payment success!", { autoClose: 2000 });
            dispatch(createOrder(orderData));
          } catch (err) {
            console.log(err);
          }
        },
        prefill: {
          name: orderData?.formData?.delivred_username,
          // email: user.email,
          contact: orderData?.formData?.delivred_phonenumber,
        },
        theme: {
          color: "#314387",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  };

  const handlePlaceOrder = async () => {
    if (selectedOption === "cashOnDelivery") {
      if (Object.values(formData).some((value) => value === "")) {
        alert("Please fill in all address fields.");
        return;
      }
    }

    const orderData = {
      orderProducts: cartProducts,
      user: user?._id,
      address: user?.address,
      status: "unpaid",
      payment: selectedOption,
      total: coupons?.amount ? coupons.amount : totalamount,
      sub_total: total,
      coupons: coupons?.coupon?._id,
      shipping_charge: shippingcharge?.shippingAmount,
      deliverd_date: shippingcharge?.deliveryDate,
      delivred_username: formData?.delivred_username,
      delivred_district: formData?.delivred_district,
      delivred_pincode: formData?.delivred_pincode,
      delivred_addrees: formData?.delivred_addrees,
      delivred_phonenumber: formData?.delivred_phonenumber,
      delivred_town: formData?.delivred_town,
      delivred_state: formData?.delivred_state,
    };
    console.log(orderData)
    dispatch(createOrder(orderData));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish1 = (values) => {
    // Handle coupon submission here, e.g., validate and apply the coupon code.
    console.log("Coupon Code:", values.delivered_pincode);
  };

  return (
    <Layout>
      <OrderWrap className="mb-20 md:mb-0">
        <section
          className="h-full bg-slate-50  "
          style={{ fontFamily: "Lato" }}
        >
          <div className="px-6   h-full text-gray-800">
            <Form
              {...layout}
              name="nest-messages"
              initialValues={{ remember: true }}
              onFinishFailed={onFinishFailed}
              className=""
              style={{ textAlign: "start" }}
              form={form}
            >
              <div className=" md:relative  md:max-w-screen-xl md:min-h-[78vh] w-full md:flex    px-4 mx-auto gap-52  lg:px-32">
                <div>
                  <div className=" flex justify-start gap-4 ">
                    <HiOutlineArrowNarrowLeft
                      className=" text-3xl text-[#0B8CAD] mt-1  md:hidden"
                      onClick={() => nav(-1)}
                    />
                    <h2
                      class="md:mb-8 mb-2 md:text-[24px] text-2xl font-bold text-[#2B9BB8] font-heading text-left"
                      style={{ fontFamily: "Lato" }}
                    >
                      Billing address
                    </h2>
                  </div>

                  {/* <h2 class="mb-8 text-3xl font-bold font-heading text-start  text-[#855252]" style={{fontFamily:'Lato', }} >Billing Address</h2> */}

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500  text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      First name
                    </h1>
                    <Form.Item
                      name="delivred_username"
                      rules={[{ required: true, message: "Name required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 items-center py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_username"
                        onChange={handleChange}
                        value={formData?.delivred_username}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Phone number
                    </h1>
                    <Form.Item
                      rules={[
                        { required: true, message: "Phone number is required" },
                        {
                          pattern: /^\d{10}$/,
                          message: "Please enter a valid 10-digit phone number",
                        },
                      ]}
                      name="delivred_phonenumber"
                    >
                      <Input
                        type="text"
                        inputMode="numeric"
                        className="block w-full sm:w-full px-4 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_phonenumber"
                        onChange={handleChange}
                        value={formData?.delivred_phonenumber}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Address 1
                    </h1>
                    <Form.Item
                      name="delivred_addrees"
                      rules={[{ required: true, message: "Address required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_addrees"
                        onChange={handleChange}
                        value={formData?.delivred_addrees}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Address 2
                    </h1>
                    <Form.Item
                      name="delivred_town"
                      rules={[{ required: true, message: "Address required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_town"
                        onChange={handleChange}
                        value={formData?.delivred_town}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      District
                    </h1>
                    <Form.Item
                      name="delivred_district"
                      rules={[
                        { required: true, message: "District required!" },
                      ]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_district"
                        onChange={handleChange}
                        value={formData?.delivred_district}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Pincode
                    </h1>
                    <Form.Item
                      name="delivred_pincode"
                      rules={[{ required: true, message: "Pincode required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_pincode"
                        onChange={handleChange}
                        value={formData?.delivred_pincode}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      State
                    </h1>
                    <Form.Item
                      name="delivred_state"
                      rules={[{ required: true, message: "State required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_state"
                        onChange={handleChange}
                        value={formData?.delivred_state}
                      />
                    </Form.Item>
                  </div>

                  {/* <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Pin code
                    </h1>
                    <Form.Item
                      name="delivred_pincode"
                      rules={[{ required: true, message: "Pincode required!" }]}
                    >
                      <Input
                        type="number"
                        className=" block w-full sm:w-full px-4 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        id="exampleFormControlInput2"
                        placeholder=""
                      />
                    </Form.Item>
                  </div> */}
{/* 
                  <h2
                    class="mb-4 md:text-[20px] text:xl font-bold text-[#2B9BB8] text-left "
                    style={{ fontFamily: "Lato" }}
                  >
                    Payment option
                  </h2> */}
                  <div className="md:flex  mx-3 gap-20 text-start">
                    {/* <div className="cursor-pointer">
                       <div className=" text-yellow-500 grid grid-cols-2 p-0 text-lg m-auto py-2"><FaCoins/><FaCoins/> </div> 
                      <p
                        className=" md:mb-10 col-span-6 my-auto text-gray-500 "
                        style={{ fontFamily: "Lato" }}
                      >
                        <input
                          className=" mr-2"
                          type={"radio"}
                          name="paymentMethod"
                          value="cashOnDelivery"
                          checked={selectedOption === "cashOnDelivery"}
                          onChange={handleOptionChange1}
                        />
                        Cash on delivery
                      </p>

                      <FiChevronRight className=" text-gray-400 ml-auto my-auto"/>
                    </div> */}

                    <div className=" cursor-pointer md:mb-3 text-start hidden">
                      {/* <p className="mx-auto px-1 rounded font-semibold border-2 border-gray-500  italic">   UPI 
                    <hr className="m-0 mb-1 border-none bg-yellow-400" style={{ height:'2px'}}/>
                    </p> */}
                      <p
                        className="col-span-6  my-auto  text-gray-500"
                        style={{ fontFamily: "Lato" }}
                      >
                        {" "}
                        <input
                          className=" mr-1"
                          type={"radio"}
                          name="paymentMethod"
                          required
                          // disabled
                          value="payOnline"
                          checked={selectedOption === "payOnline"}
                          onChange={handleOptionChange1}
                        />
                        Pay online{" "}
                      </p>

                      {/* <FiChevronRight className=" text-gray-400 ml-auto my-auto"/> */}
                    </div>
                  </div>
                </div>
                <div className=" md:mt-24 mb-10 ">
                  <div class="" style={{ fontFamily: "Lato" }}>
                    <h2 class=" flex items-start mb-4 mt-5 sm:text-[20px] text-[18px] font-bold font-heading text-[#0B8CAD]">
                      Order Summary
                    </h2>
                    <div class="p-4 rounded-md shadow-lg border  border-[#0B8CAD] w-80 text-[#2B9BB8] ">
                      <div class="flex justify-between">
                        <span class="md:text-[14px] font-bold mt-4 mb-2 text-[#2B9BB8]">
                          Sub total
                        </span>
                        <span class="sm:text-[14px] font-bold mt-4 font-heading text-[#2B9BB8]">
                          {" "}
                          Rs {total}/-
                        </span>
                      </div>
                      <div class="flex justify-between">
                        <span class="sm:text-[14px] mt-2 font-bold text-[#2B9BB8]">
                          Shipping charges
                        </span>
                        <span class="sm:text-xl md:text-[14px] mt-2 font-bold font-heading text-[#2B9BB8]">
                          {shippingcharge?.shippingAmount} /-
                        </span>
                      </div>
                      <div class="border-b border-blue-100 mb-6">
                        <div class="flex justify-between ">
                          <span class="md:text-[14px] mt-2 font-bold text-[#2B9BB8]">
                            Coupon discount
                          </span>
                          <span class="sm:text-xl md:text-[14px] mt-2 font-bold font-heading text-[#2B9BB8]">
                            - {coupons?.discountAmount} /-
                          </span>
                        </div>
                        {coupons?.amount && (
                          <h1 className="mb-3">
                            You have received {coupons?.coupon?.discount} %
                          </h1>
                        )}
                      </div>
                      <div class="flex mb-2 justify-between items-center">
                        <span class="text-[16px] font-bold font-heading text-[#2B9BB8]">
                          Total
                        </span>
                        <span class="text-[16px] font-bold font-heading text-[#2B9BB8]">
                          {" "}
                          Rs{" "}
                          
                             {totalamount}/-
                        </span>
                      </div>
                      <div className="mt-10">
                        <p>Discount code</p>
                        <Form
                          name="coupon_form"
                          onFinish={onFinish1}
                          layout="inline"
                        >
                          <Form.Item
                            name="delivered_pincode"
                            rules={[
                              {
                                required: false,
                                message: "Enter your coupon",
                              },
                            ]}
                          >
                            <AddCoupon user={user} total={totalamount} />
                          </Form.Item>
                        </Form>
                      </div>
                    </div>

                    {/* {shippingcharge && (
                      <h1 className="text-[15px]">
                        This order delivery is expected on{" "}
                        {moment(shippingcharge?.deliveryDate).format(
                          "DD/MM/YYYY"
                        )}
                      </h1>
                    )} */}
                    {/* <div
                      className="text-right lg:text-right py-4 mt-5 rounded"
                      style={{ fontFamily: "Lato" }}
                    >
                      <button
                        className="btn px-2 py-2  rounded shadow-md text-[14px]  w-full flex justify-center"
                        style={{
                          color: "white",
                          backgroundColor: ["#0B8CAD"],
                          fontWeight: "500",
                        }}
                        type="primary"
                        htmlType="submit"
                      >
                        Place Order
                      </button>
                    </div> */}
                    <div class=" mx-auto w-full">
                      {selectedOption === "cashOnDelivery" ? (
                        <button
                          className="block mb-10 px-10 mt-4 w-full py-2 text-xl leading-6 font-medium tracking-tighter font-heading text-center text-white bg-[#2B9BB8] hover:bg-sky-600  rounded-full"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePlaceOrder();
                            // handleSubmit(e);
                          }}
                        >
                          Place order
                        </button>
                      ) : (
                        <button
                          className="block mb-10 px-10 mt-4 w-full py-2 text-xl leading-6 font-medium tracking-tighter font-heading text-center text-white bg-[#2B9BB8] hover:bg-sky-600  rounded-full"
                          type="button"
                          onClick={handlePayOnline}
                        >
                          Pay Now
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </section>
      </OrderWrap>
    </Layout>
  );
};

export default OrderAddress;

const OrderWrap = styled.div`
  .total {
    background-color: #fcfcfc;
    font-size: 0.8rem;
    letter-spacing: 0.3px;
    line-height: 2.3;

    input,
    optgroup,
    select {
      background-color: transparent;
      font-size: 0.72rem;
      border: 1px solid grey;
      border-radius: 0.2rem;
      padding: 0rem;
      margin: 0;
    }
  }

  .payment {
    p {
      font-size: 0.8rem;
      letter-spacing: 0.3px;
      color: grey;
    }
  }

  .address {
    font-size: 0.82rem;
    letter-spacing: 0.3px;
    line-height: 1.8;
    font-weight: 600;
    p {
      font-size: 0.7rem;
      color: grey;
      letter-spacing: 0.3px;
      line-height: 1.65;
      font-weight: 400;
    }
  }
`;
