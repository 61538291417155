import React from 'react';

function ErrorPage() {
  return (
    <div>
      <h1>Error Occurred</h1>
      <p>Sorry, an unexpected error occurred. Please try again later.</p>
      {/* Add additional content or components as needed */}
    </div>
  );
}

export default ErrorPage;
