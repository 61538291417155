import React,{useState, useLayoutEffect} from 'react'
import styled from 'styled-components'
import {  useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';


export default function Productsearch({data}) {

    const dispatch = useDispatch()



console.log(data)

    return (
        <DealWrap >
          {  

        <div className="mx-2 mt-1 pb-2">

            <h1 className="pt-1">Productsearch</h1>

            {
                data.map((items, i)=>{

                return <Link to={`/Product/${items?._id}`}>

                            <div key={i} className=" special grid grid-cols-4  p-1 my-1 mx-0  rounded" >

                            <div  >
                                    <img className="block my-auto rounded bg-gray-200" src={items?.product_info.image } alt="product_image" />
                            </div>
                            

                            <div className=" col-span-3 productdata px-1 py-auto my-auto"> 
                                        <h3 className=" capitalize text-gray-900 " >{items?.product_info?.product_name}</h3>
                                        <p className="  text-gray-900" > Price: &#x20B9; {items?.product_info?.selling_price}/-</p>
                                </div>
                            </div> 

                        </Link>
                })

           }    
           </div>}
            

        </DealWrap>
    )
}


const DealWrap = styled.div`

    .productdata { 
        letter-spacing: 0.3px;
        line-height: 1.8;              
        p{
            font-size: 0.75rem;
        }
        h3{
            font-size: 0.9rem;
        }
    }

    img{
       width:90%; 
       height: 3.1rem;
       object-fit: cover;
    }

    .special{
        background-color: #F6F6F6;
    }

 


`