import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri } from "../key";
import { data } from "browserslist";

export const initialState = {
  loading: false,
  coupons: [],
  currentCoupon: null,
  allcoupon: [],

};

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    getCoupon: (state) => {
      state.loading = true;
    },
    getCouponSuccess: (state, { payload }) => {
      state.loading = false;
      state.coupons = payload;
    },

    getCurrentCoupon: (state, { payload }) => {
      state.loading = false;
      state.currentCoupon = payload;
    },
    getCouponFailure: (state, { payload }) => {
      state.loading = false;
      state.coupons = payload;
    },
    getAllCoupon: (state, { payload }) => {
      state.loading = false;
      state.allcoupon = payload;
    },
  },
});

export const {
  getCoupon,
  getCurrentCoupon,
  getCouponSuccess,
  getCouponFailure,
  getAllCoupon
} = couponSlice.actions;

export const couponSelector = (state) => state.coupon;

export default couponSlice.reducer;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const VerifyCoupon = (couponData) => async (dispatch) => {
  const key = "coupon";
  dispatch(getCoupon());
  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/varifycoupon",couponData,config
    );
console.log(data)


    dispatch(getCouponSuccess(data));
  } catch ({ response }) {
    dispatch(getCouponFailure(response.data));
    // response.data && message.error({ content: response.data.msg, key, duration: 4 });
  }
};


export const fetchAllCoupons = () => async (dispatch) => {
  const key = "coupon";
  dispatch(getCoupon());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + "/coupon");
    
    console.log(data)

    dispatch(getAllCoupon(data));
  } catch ({ response }) {
    dispatch(getAllCoupon());
    // response.data && message.error({ content: response.data.msg, key, duration: 2 });
  }
};
