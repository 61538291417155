import React, { useEffect, useState, useLayoutEffect } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCartProducts,
  qtyupdate,
  cartProductSelector,
} from "../../api/cartSlice";
import Calendar from "react-calendar";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import Upsell from "./Upsell";
import { Interweave } from "interweave";
import { BiRupee } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import Magnifier from "react-magnifier";
import {
  productSelector,
  fetchOneProduct,
  fetchAllProducts,
} from "../../api/products";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const ProductSectionone = ({ data, id }) => {
  const dispatch = useDispatch();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [date, setDateState] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [time, setTime] = useState(null);
  const { cartProducts, total } = useSelector(cartProductSelector);
  const { currentProduct } = useSelector(productSelector);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const exist = cartProducts.findIndex((isthere) => isthere._id === id);

  console.log(data);

  const handleOptionClick = (id) => {
    console.log(id);
    setSelectedOption(id);
  };

  useEffect(() => {
    const exist = cartProducts.findIndex((isthere) => isthere._id === id);

    if (exist !== -1) {
      setDateState(moment(cartProducts[exist].date)._d);
      setTime(cartProducts[exist].time);
    }
  }, [cartProducts]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onAdd = (data) => {
    let newdata = { ...data, selectedVariant, date, time };

    console.log(newdata);

    dispatch(addCartProducts(newdata));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(fetchOneProduct(id));
  }, [id]);

  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch]);

  useEffect(() => {
    setFeaturedImage(currentProduct?.featured_image);
  }, [currentProduct]);

  const changeFeaturedImage = (image) => {
    // Update the currentImageIndex when an image is clicked
    const newIndex = currentProduct?.product_images.indexOf(image);
    console.log(newIndex);
    setCurrentImageIndex(newIndex);
    setFeaturedImage(image);
  };
  console.log(featuredImage);

  useEffect(() => {
    const exist = cartProducts.findIndex((isthere) => isthere._id === id);
  }, [cartProducts]);

  const description = data?.description || "";

  // Split the description into words
  const words = description.split(" ");

  // Take the first 40 words and join them back into a string
  const truncatedDescription1 = words.slice(0, 40).join(" ");

  const findVariantById = (id) => {
    return data?.product_variants.find((variant) => variant.id === id);
  };

  const selectedVariant =
    selectedOption !== null
      ? findVariantById(selectedOption)
      : data?.product_variants[0];
  console.log(selectedVariant);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-slate-50">
      <div
        class=" relative  max-w-screen-xl container mx-auto px-4 md:pt-8 "
        style={{ fontFamily: "Lato" }}
      >
        <div class="flex flex-wrap -mx-4 ">
          <div className="w-full   lg:w-1/2 px-4 mb-2 md:mb-16 lg:mb-0">
            <div className=" -mx-4   items-center justify-between lg:justify-start lg:items-start xl:items-center">
              <Swiper
                slidesPerView={1}
                spaceBetween={1}
                navigation={true}
                loop={true}
                pagination={{ clickable: true }}
                // autoplay={{
                //   delay: 3500,
                //   disableOnInteraction: false,
                // }}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  1000: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
              >
                {currentProduct?.product_images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <Magnifier
                    
                      className="rounded-lg"
                      src={image}
                      alt={`Product Image ${index + 1}`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <div className="flex items-center md:gap-4 md:mr-60 justify-center">
                    {currentProduct?.product_images.map((image, index) => (
                      <a
                        key={index}
                        className={`h-30 block mb-4 ${
                          index !== currentImageIndex
                            ? "mr-2 sm:mr-0"
                            : "mr-4 sm:mr-0"
                        } ${
                          index === 2
                            ? "rounded-xl border-2 border-blueGray-500"
                            : ""
                        }`}
                        href="#"
                        onClick={() => changeFeaturedImage(image)}
                      >
                        <img
                          className="h-16 w-16 rounded-lg "
                          src={image}
                          alt={`Product Image ${index + 1}`}
                        />
                      </a>
                    ))}
                  </div> */}
            </div>
          </div>

          <div class="w-full md:w-1/2 px-4 text-left">
            <div class="lg:pl-20">
              <div class="mb-2 pb-10 border-b">
                <span class="text-gray-500 mr-24 text-xs">
                  <b>#</b>
                  {data?.product_id}
                </span>
                <h2 class="pt-2 max-w-xl mb-2 sm:text-2xl text-xl font-bold text-black uppercase">
                  {" "}
                  {data?.product_name}
                </h2>

                {/* <div class=" mr-16 font-medium sm:text-[14px] text-sm text-gray-800 line-through">{data?.price}</div> */}
                <div className="mb-2 sm:text-2xl text-md font-semibold w-full text-[#2B9BB8]">
                  <p>
                    <BiRupee className="inline-block mb-1" />
                    {selectedOption !== null &&
                    findVariantById(selectedOption)?.total_price
                      ? findVariantById(selectedOption).total_price
                      : data?.product_variants[0]?.total_price}{" "}
                    /- &nbsp;&nbsp;
                    <span className="text-sm text-gray-500">
                      MRP{" "}
                      <span className="line-through">
                        <BiRupee className="inline-block text-sm mb-1" />
                        {selectedOption !== null &&
                        findVariantById(selectedOption)?.price
                          ? findVariantById(selectedOption).price
                          : data?.product_variants[0]?.price}
                      </span>
                    </span>
                  </p>
                  <p className="text-sm font-bold text-red-500">
                    Inclusive of all taxes
                  </p>
                </div>
                {/* <span className='text-red-500 text-md'>{data?.price - data?.total_price} </span> */}

                <p className="text-sky-700 font-medium text-xl mt-6">
                  Product Description
                </p>
                <p
                  class="max-w-md text-gray-500 text-left mt-3 "
                  style={{ fontFamily: "Lato" }}
                >
                  {" "}
                  <Interweave className="text-gray-500" content={truncatedDescription1} />
                </p>
                <div className=" md:ml-0 mt-0 md:mt-2  ">
                  <button
                    className={` w-80 md:w-[600px] pl-4 lg:pl-6 pr-2 py-1 md:py-4 mt-4 md:mt-0  leading-7 rounded-2xl border-2 border-blueGray-200 hover:border-blueGray-300`}
                    onClick={toggleAccordion}
                  >
                    <h3 className="text-lg font-heading  text-sky-700 flex justify-between text-left font-medium">
                      Product Benefits 
                      <svg
                        width="12"
                        height="8"
                        className="mt-4"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.4594 0.289848C10.8128 -0.096616 11.3841 -0.096616 11.7349 0.289848C12.0871 0.676312 12.0897 1.30071 11.7349 1.68718L6.63794 7.21015C6.28579 7.59662 5.71584 7.59662 5.36108 7.21015L0.264109 1.68718C-0.0880363 1.30215 -0.0880363 0.676312 0.264109 0.289848C0.617558 -0.096616 1.18882 -0.096616 1.53966 0.289848L6.00147 4.81927L10.4594 0.289848Z"
                          fill="black"
                        ></path>
                      </svg>
                    </h3>

                    {isOpen && (
                      <div className="w-full text-gray-700   py-4  text-justify">
                        {/* Content to display when the accordion is open */}
                        <Interweave content={currentProduct?.description1} />
                      </div>
                    )}
                  </button>
                </div>
              </div>

              {data?.product_variants.length > 1 && (
                <div className="container my-4 w-20 md:w-96 text-xs">
                  <p className="py-2 text-sm text-sky-600">Pack Sizes</p>
                  {data?.product_variants.map((item, i) => (
                    <div
                      key={i}
                      className={`flex md:gap-20 border-[#0B8CAD] border p-2 my-2 rounded-md shadow w-80 md:w-96 ${
                        selectedOption === item.id ? "bg-gray-200" : "bg-white"
                      } cursor-pointer`}
                      onClick={() => handleOptionClick(item.id)}
                    >
                      <div className="grid grid-cols-3 gap-10 text-xs text-sky-700">
                        <span>
                          {item.variant_qty}
                          {item.stock_units}
                        </span>
                        <p className="w-96">
                        
                          <span className="text-lg">
                            <BiRupee className="inline-block text-xs" />
                            {item.total_price}
                          </span>
                          &nbsp;&nbsp;&nbsp;{" "}
                          <span className="line-through text-[10px]">
                            MRP{item.price}
                          </span>
                        </p>
                        <span className="ml-4 text-red-700 text-end">
                          {item.discount}%Off
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex justify-start mt-3">
                {exist === -1 ? (
                  <button
                    onClick={() => onAdd(data, selectedVariant)}
                    type="button"
                    className={`mr-4 md:w-40 w-28 text-[14px] sm:text-[14px] xl:mb-0 block flex justify-center bg-gradient-to-r from-[#2B9BB8] via-[#2B9BB8] to-[#2B9BB8] hover:bg-sky-200 cursor-pointer text-center text-white font-bold font-heading sm:py-3 py-2 rounded-md transition duration-200`}
                  >
                    Add to cart
                  </button>
                ) : (
                  <button
                    style={{ fontFamily: "Lato" }}
                    type="button"
                    className={`mr-4 md:w-40 w-28 text-[14px] sm:text-[16px] xl:mb-0 block bg-[#2B9BB8] cursor-pointer hover:bg-sky-700 text-center text-white font-bold font-heading sm:py-3 py-2 rounded-md transition duration-200`}
                  >
                    In cart
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Upsell cartProducts={cartProducts} />
    </div>
  );
};

export default ProductSectionone;
