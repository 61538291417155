
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Form, Input, message, } from "antd";
import { MobileOutlined  } from '@ant-design/icons';
import { fetchlogin, authenticateSelector } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Install from "../pwa/install";
import Top from "../../Assets/logo.png"
export default function Login() {
  const nav = useNavigate();

  const id = useParams();

  console.log(id);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const profile = new URLSearchParams(useLocation().search).get(`from`);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  const { isAuthenticate } = useSelector(authenticateSelector);

  const onFinish = (values) => {
    console.log("Success:", values);

    // Assuming you have a fetchlogin action creator that takes phone_number and password
    dispatch(fetchlogin(values));
  };

  useEffect(() => {
    if (isAuthenticate && profile) {
      return profile === "cart" ? nav("/cart") : nav("/");
    }

    if (!isAuthenticate && profile) {
      return profile === "cart" ? nav("/profile?from=cart") : nav("/profile");
    }

    if (isAuthenticate && !profile) {
      return nav("/");
    }

    if (!isAuthenticate && !profile) {
      return nav("/profile");
    }
  }, [isAuthenticate, profile]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <section>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div class="px-4 py-4 mb-10 md:mb-0 mx-auto max-w-7xl min-h-[78vh] sm:px-6 md:px-6 lg:px-10 lg:py-24">
            <div class="justify-center md:mx-32 text-left align-bottom transition-all transform bg-slate-50 border border-[#FDF2F8] rounded-lg sm:align-middle ">
              <div class="grid w-full flex-wrap md:w-full items-center justify-center grid-cols-1 mx-auto shadow-xl lg:grid-cols-2 rounded-xl">
                <div class="md:w-full px-4 md:px-10 sm:ml-4">
                  <div>
                    <div class="mt-3  text-center">
                      <div
                        class="inline-flex items-center justify-center w-full"
                        style={{ fontFamily: "Lato" }}
                      >
                        <h3 class="text-[24px] font-semibold text-center text-sky-700 leading-4 lg:text-[32px]">
                          User Login
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div
                    class="mt-4 text-gray-900 space-y-2 text-start"
                    style={{ fontFamily: "Lato" }}
                  >
                    <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      style={{ borderColor: "white" }}
                      rules={[
                        { required: true, message: "Phone number is required" },
                        {
                          pattern: /^\d{10}$/,
                          message: "Please enter a valid 10-digit phone number",
                        },
                      ]}
                    >
                      <Input
                       addonBefore={<MobileOutlined className="text-gray-500" />}

                        id="phone_number"
                        name="phone_number"
                        type="text"
                        inputMode="numeric"
                        autoComplete="tel"
                        style={{ height: "40px" }}
                        className="rounded mb-2"
                        required={true}
                        placeholder="Your Phone Number"
                        suffix={null}
                        maxLength={10}
                        pattern="\d{10}"
                      />
                    </Form.Item>

                    <Form.Item
                      className="md:ml-2"
                      label="Password &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      for="password"
                    >
                      <Input.Password
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required=""
                        placeholder="Password"
                        className="rounded mb-4"
                      
                      />
                    </Form.Item>

                    <div class="flex flex-col mt-2 md:mr-6 mr-0 items-center ">
                      <button
                        type="primary"
                        htmlType="submit"
                        style={{
                          color: "white",
                          fontWeight: "500",
                          height: "35px",
                          width: "35%",
                        }}
                        className="rounded bg-sky-500 "
                      >
                        Log in
                      </button>

                      <Install />
                    </div>

                    <div className="flex w-full">
                      <Link
                        to="/login-otp"
                        className="inline-flex justify-center w-full py-1 text-base font-medium text-sky-700 focus:outline-none hover:text-sky-800 focus:text-[#855252] sm:text-md"
                      >
                        Login with OTP
                      </Link>

                      <div className="mt-1">
                        {" "}
                        {/* Add margin between the two items */}
                        <span className="text-gray-500 ">|</span>
                      </div>

                      <Link
                        to="/signup"
                        
                        className="inline-flex text-end  md:justify-center w-full py-1 text-base font-medium text-sky-700 focus:outline-none hover:text-sky-800 focus:text-[#855252] sm:text-md"
                      >
                        Create a new Account
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="order-first w-full ">
                  <img
                    class="object-cover h-50% sm:ml-3 sm:my-3 bg-cover rounded-l-lg"
                    src={Top}
                    alt="/"
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </div>
  );
}




// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
// import { Form, Input, message, } from "antd";
// import { MobileOutlined  } from '@ant-design/icons';
// import { fetchlogin, authenticateSelector } from "../../api/authSlice";
// import { useDispatch, useSelector } from "react-redux";
// import Install from "../pwa/install";
// import Logo1 from "../../Assets/logo.png"
// import LoginWithOtp from '../Shared/LoginwithOTP'

// export default function Login() {
//   const [showOTPLogin, setShowOTPLogin] = useState(false);
//   const nav = useNavigate();

//   const id = useParams();

//   console.log(id);

//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const profile = new URLSearchParams(useLocation().search).get(`from`);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleOk = () => {
//     setIsModalOpen(false);
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const dispatch = useDispatch();
//   const { isAuthenticate } = useSelector(authenticateSelector);

//   const onFinish = (values) => {
//     console.log("Success:", values);

   
//     dispatch(fetchlogin(values));
//   };

//   useEffect(() => {
//     if (isAuthenticate && profile) {
//       return profile === "cart" ? nav("/cart") : nav("/");
//     }

//     if (!isAuthenticate && profile) {
//       return profile === "cart" ? nav("/profile?from=cart") : nav("/profile");
//     }

//     if (isAuthenticate && !profile) {
//       return nav("/");
//     }

//     if (!isAuthenticate && !profile) {
//       return nav("/profile");
//     }
//   }, [isAuthenticate, profile]);

//   const onFinishFailed = (errorInfo) => {
//     console.log("Failed:", errorInfo);
//   };

//   return (
//     <div>
    
    
//         <section > 
//         <div className=" flex justify-center lg:gap-24 lg:py-32 ">

      
//           {
//             showOTPLogin ?(
//               <LoginWithOtp switchToLogin={() => setShowOTPLogin(false)} ></LoginWithOtp>
//             ):(
//               <>
//               <Form
//               name="basic"
//               initialValues={{ remember: true }}
//               onFinish={onFinish}
//               onFinishFailed={onFinishFailed}
//             >
//               <div 
               
             
//               >
//                 <div className="border border-solid border-block-900 p-5 rounded-xl">
//                   <div >
//                     <div >
//                       <div>
//                         <div class="pt-3 text-center ">
//                           <div
//                             class="inline-flex items-center justify-center w-full "
//                             style={{ fontFamily: "Lato" }}
//                           >
//                             <h3 class="text-[16px] font-semibold text-center  text-[inline-flex justify-center w-full py-1 text-base font-medium text-[#2B9BB8] focus:outline-none hover:text-sky-800 focus:text-[#855252] sm:text-md] leading-4 lg:text-[28px]">
//                               User Login
//                             </h3>
//                           </div>
//                         </div>
//                       </div>
    
//                       <div
//                         class="mt-8 text-gray-900 space-y-2 text-start mr-30 lg:pr-30 lg:pl-30"
//                         style={{ fontFamily: "Lato" }}
//                       >
//                         <Form.Item
//                           label="Phone Number"
//                           name="phone_number"
//                           style={{ borderColor: "white" }}
//                           rules={[
//                             { required: true, message: "Phone number is required" },
//                             {
//                               pattern: /^\d{10}$/,
//                               message: "Please enter a valid 10-digit phone number",
//                             },
//                           ]}
//                         >
//                           <Input
//                            addonBefore={<MobileOutlined className="text-gray-500" />}
    
//                             id="phone_number"
//                             name="phone_number"
//                             type="text"
//                             inputMode="numeric"
//                             autoComplete="tel"
//                             style={{ height: "40px" }}
//                             className="rounded mb-2"
//                             required={true}
//                             placeholder="Your Phone Number"
//                             suffix={null}
//                             maxLength={10}
//                             pattern="\d{10}"
//                           />
//                         </Form.Item>
    
//                         <Form.Item
//                           className="md:ml-2"
//                           label="Password &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
//                           name="password"
//                           rules={[
//                             {
//                               required: true,
//                               message: "Please input your password!",
//                             },
//                           ]}
//                           for="password"
//                         >
//                           <Input.Password
//                             id="password"
//                             name="password"
//                             type="password"
//                             autoComplete="current-password"
//                             required=""
//                             placeholder="Password"
//                             className="rounded mb-4"
                          
//                           />
//                         </Form.Item>
    
//                         <div class="flex flex-col mt-2 md:mr-6 ml-8 items-center ">
//                           <button
//                             type="primary"
//                             htmlType="submit"
//                             style={{
//                               color: "white",
//                               fontWeight: "500",
//                               height: "35px",
//                               width: "45%",
//                             }}
//                             className="rounded bg-[#2B9BB8] "
//                           >
//                             Log in
//                           </button>
    
//                           <Install />
//                         </div>
    
//                         <div className="flex w-full">
//                           <Link
//                             // to="/login-otp"
//                             onClick={() => setShowOTPLogin(true)}
//                             className="inline-flex justify-center w-full py-1 text-base font-medium text-[#2B9BB8] focus:outline-none hover:text-sky-800 focus:text-[#855252] sm:text-md"
//                           >
//                             Login with OTP
//                           </Link>
    
//                           <div className="mt-1">
//                             {" "}
//                             {/* Add margin between the two items */}
//                             <span className="text-gray-500 ">|</span>
//                           </div>
    
//                           <Link
//                             to="/signup"
                            
//                             className="inline-flex text-end  md:justify-center w-full py-1 text-base font-medium text-[inline-flex justify-center w-full py-1 text-base font-medium text-[#2B9BB8] focus:outline-none hover:text-sky-800 focus:text-[#855252] sm:text-md] focus:outline-none hover:text-sky-800 focus:text-[#855252] sm:text-md"
//                           >
//                             Create a new Account
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                     {/* <div class="order-first w-full">
//                       <img
//                         class="object-cover lg:w-96 sm:ml-3 sm:my-3 bg-cover rounded-l-lg lg:pl-20"
//                         src={Logo1}
//                         alt=""
//                       />
//                     </div> */}
//                   </div>
//                 </div>
//               </div>
//             </Form>
//             </>
//             )
//           }
      
//         <div class="order-first py-8">
//                   <img
//                     class="object-cover lg:w-96 sm:ml-3 sm:my-3 bg-cover rounded-l-lg lg:pl-20"
//                     src={Logo1}
//                     alt=""
//                   />
//                 </div>
//                 </div>
//       </section>
     
//     </div>
//   );
// }