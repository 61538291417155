import Image1 from "../../Assets/Homeimages/chocolate-truffle-cream-cake-half-kg_2.jpg";
import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Image5 from "../../Assets/virus.png";
import Image2 from "../../Assets/bone.png";
import Image3 from "../../Assets/women.png";
import Image4 from "../../Assets/kids.png";
import Image6 from "../../Assets/diabetic.png";
import Image7 from "../../Assets/top.png";
import Imag1 from "../../Assets/images/p1.svg"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./index.css";

import { keyUri } from "../../key";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { fetchOneCategory, categorySelector } from "../../api/catagories";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Sectiontwo() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [category, setCategory] = useState(null);
  const [data, setData] = useState(null);
  const [card, cardData] = useState(null);

  const ImageData = [Image5, Image2, Image3, Image4, Image6, Image7];

  useEffect(() => {
    const fetchData = async () => {
      const categorydata = await fetch(keyUri.BACKEND_URI + `/category`, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });
      const category = await categorydata.json();
      setCategory(category);
    };
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(fetchOneCategory(id));
  }, [dispatch, id]);

  useEffect(() => {
    const fetchcardData = async () => {
      const carddata = await fetch(keyUri.BACKEND_URI + `/card`, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });

      const cardImage = await carddata.json();
      cardData(cardImage);
    };

    fetchcardData();
  }, []);



  return (
    <div className="bg-[#f5f3f4] " >
      {/* <div class="w-full text-md font-semibold text-gray-800 leading-relaxed">
          <div style={{ fontFamily: "Lato" }} class="max-w-xs mx-auto text-center">
            <img class="mx-auto h-20 mb-2 border rounded-full p-4 w-20 " src={Imag1} alt=""/>
       
            FSSAI APPROVED
          </div>
        </div> */}
    <div
      className="container  px-2 PY-2 bg-[#eee7ea]   mx-auto lg:w-4/5 block  "
      style={{ fontFamily: "Lato" }}
    >
      <div className=" py-1 mx-auto ">
        <h1 className=" text-2xl md:text-4xl mb-1 py-2  md:py-4  text-block-700">
          Our Popular Categories
        </h1>

        {/* <div className=" hidden md:block md:grid  md:grid-cols-3">
          {category?.map((Newdata, index) => (
            index < 6 &&(
            <Link to={`/catagories/${Newdata._id}`}>
              <div key={Newdata._id}>
                <div className="flex justify-center pt-16 pb-4 cursor-pointer">
                  <div className="w-80   bg-sky-800  hover:bg-sky-600 focus:border-block-900 focus:bg-sky-700 transition duration-200 ease-in-out h-20 md:w-82 md:h-62 -mt-16 mx-auto overflow-hidden text-center rounded-xl">
                    <div className="flex justify-start text-start gap-3 text-sm md:text-xl text-[#f8f8f8] pl-2 items-center h-full">
                      <img
                        src={ImageData[index]}
                        alt="Category Icon"
                        className=" w-10 text-white bg-white p-1"
                      />
                      <span style={{ fontFamily: "Lato" }} className="uppercase text-lg">{Newdata?.category_name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            )
          ))}
        </div> */}

        {/* <div className=" block md:hidden grid  grid-cols-3">
          {category?.map((Newdata, index) => (
           index <6 && ( <Link to={`/catagories/${Newdata._id}`}>
              <div key={Newdata._id}>
                <div className="flex justify-center pt-16 pb-4 cursor-pointer">
                  <div className="  h-20  -mt-16 mx-auto overflow-hidden text-center ">
                    <div className="  text-center  text-sm  text-gray-900  items-center h-full">
                      <img
                        src={ImageData[index]}
                        alt="Category Icon"
                        className="border w-8 ml-10 rounded-full"
                      />
                      <p style={{ fontFamily: "Lato" }} className="w-28 overflow-hidden  uppercase">
                        {Newdata?.category_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            )
          ))}
        </div> */}
        {/* </Swiper> */}
      </div>

      {/* <div className="flex flex-wrap w-full md:mt-1">
        {card?.map((Cardimg) => (
          <div className=" p-1 w-1/2 h-50% overflow-hidden rounded-md  border-solid border-2 border-[#F5F3F4] ">
            <Link to={`/catagories/${Cardimg?.category}`}>
              <img
                alt="gallery"
                className="w-full object-cover cursor-pointer   transition duration-300 hover:scale-[1.1] rounded-md shadow-sm h-[16h] md:h-[26vh] lg:h-[36vh] object-center block"
                src={Cardimg?.card_image}
              />
            </Link>
          </div>
        ))}
      </div> */}

      <div className=" hidden md:block md:grid mt-12 gap-10  md:grid-cols-6 border">
          {category?.map((Newdata, index) => (
            index < 6 &&(
            <Link to={`/catagories/${Newdata._id}`}>
              <div key={Newdata._id}>
                <div className="flex justify-center pt-16 pb-3 cursor-pointer">
                  <div className="w-60 h-72   border-solid border-2  transition duration-200 ease-in-out h-30 md:w-82 md:h-62 -mt-16 mx-auto overflow-hidden text-center rounded-xl ">
                    <div className="bg-white justify-center text-center gap-3 text-sm md:text-xl text-[#f8f8f8] items-center">
                     <div className="justify-center flex items-center">
                     <img
                        src={ImageData[index]} 
                        alt="Category Icon"
                        className=" w-32 text-white    lg:pt-5 lg:mb-5  bg-white p-1"
                      />
                     </div>
                  
                      <div class='bg-[#0B8CAD]  hover:bg-sky-600 w-full h-10'>
                      <span style={{ fontFamily: "Lato" }} className="uppercase text-sm">{Newdata?.category_name}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </Link>
            )
          ))}
        </div>
        <div className=" block md:hidden grid mt-   grid-cols-2">
          {category?.map((Newdata, index) => (
           index <6 && ( <Link to={`/catagories/${Newdata._id}`}>
              <div key={Newdata._id}>
                <div className="flex justify-center pt-16 pb-4 cursor-pointer">
                  <div className="  h-20 -mt-16 mx-auto overflow-hidden text-center  ">
                    <div className="  text-center  text-sm  text-black  items-center h-full">
                      <img
                        src={ImageData[index]}
                        alt="Category Icon"
                        className="border w-8 ml-10 rounded-full"
                      />
                      <p style={{ fontFamily: "Lato" }} className="w-28 overflow-hidden  uppercase">
                        {Newdata?.category_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            )
          ))}
        </div>


        {/* <div className="flex flex-wrap w-full md:mt-1">
        {card?.map((Cardimg) => (
          <div className=" p-1 w-1/2 h-50% overflow-hidden rounded-md  border-solid border-2 border-[#F5F3F4] ">
            <Link to={`/catagories/${Cardimg?.category}`}>
              <img
                alt="gallery"
                className="w-full object-cover cursor-pointer   transition duration-300 hover:scale-[1.1] rounded-md shadow-sm h-[16h] md:h-[26vh] lg:h-[36vh] object-center block"
                src={Cardimg?.card_image}
              />
            </Link>
          </div>
        ))}
      </div> */}


    </div>
    </div>
  );
}
