import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import "./index.css";

import { keyUri } from "../../key";
import { Link } from "react-router-dom";   

const SectionOne = () => {
  const [data, setData] = useState(null);
  const [isdataloaded, setisdataloaded] = useState(false);
  const [card, cardData] = useState(null);

  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      const bannerdata = await fetch(keyUri.BACKEND_URI + `/banner`, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });
      const bannerImage = await bannerdata.json();
      setData(bannerImage);
      setisdataloaded(true);
    };
    fetchData();
  }, []);

  const [data1, setData1] = useState(null);
  const [isdataloaded1, setisdataloaded1] = useState(false);
  console.log(data1);

  useEffect(() => {
    const fetchData = async () => {
      const bannerdata1 = await fetch(keyUri.BACKEND_URI + `/mobbanner`, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });
      const bannerImage1 = await bannerdata1.json();
      setData1(bannerImage1);
      setisdataloaded1(true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchcardData = async () => {
      const carddata = await fetch(keyUri.BACKEND_URI + `/card`, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });

      const cardImage = await carddata.json();
      cardData(cardImage);
    };

    fetchcardData();
  }, []);

  return (
    <>
  <div className="hidden md:block " style={{ width: '100%'}}>
  {isdataloaded && (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      loop={true}
      effect={"fade"}
      loopFillGroupWithBlank={true}
      grabCursor={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        "@0.00": {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "@0.75": {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "@1.00": {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        "@1.50": {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      }}
      modules={[Pagination, Autoplay, EffectFade]}
      className="mySwiper w-full " // Add w-full class to make the carousel full-width
    >
      {data?.map((banner) => (
        <SwiperSlide key={banner.id}>
          <Link
            to={
              banner?.banner_type === "Category"
                ? `/categories/${banner?.category}`
                : `/Product/${banner?.products}`
            }
          >
            <img
              alt="gallery"
              className="w-full cursor-pointer object-fit lg:h-[600px] object-center block inset-0"
                      
              src={banner?.banner_image}
            />
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  )}
</div>


      <div className="md:hidden block container   ">
        {isdataloaded1 && (
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            effect={"fade"}
            loopFillGroupWithBlank={true}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              "@1.50": {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
            modules={[Pagination, Autoplay, EffectFade]}
            className="mySwiper"
          >
            {data1?.map((banner1) => (
              <SwiperSlide>
                <Link
                  to={
                    banner1?.banner_type === "Category"
                      ? `${`/catagories/${banner1?.category}`}`
                      : `${`/Product/${banner1?.products}`}`
                  }
                >
                  <img
                    alt="gallery"
                    className="w-full cursor-pointer object-cover h-fit object-center block  inset-0"
                    src={banner1?.banner_image}
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </>
  );
};

export default SectionOne;
