import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

let deferredPrompt;

export default function Install() {
  const [installable, setInstallable] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener("appinstalled", () => {
      // Log install to analytics
      console.log("INSTALL: Success");
    });
  }, []);

  const handleInstallClick = () => {
    // Hide the app-provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt && deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt &&
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
      });
  };

  const handleCloseClick = () => {
    // Hide the installation banner
    setInstallable(false);
  };

  return (
    <InstallWrap className="block md:hidden">
      {installable && (
        <Fade top>
          <div className="install-banner ">
            <h1 className="text-[16px] text-block-900 font-medium">
              For a better experience <br /> use Emmuniti Lite{" "}
            </h1>
            <button
              className="text-white bg-[#2B9BB8] rounded-full p-2 px-5 text-[14px] mr-4"
              onClick={handleInstallClick}
            >
              Install
            </button>
            <button
              className="text-red-800 text-lg mb-24"
              onClick={handleCloseClick}
            >
              &#10005; {/* Close icon (X) */}
            </button>
          </div>
        </Fade>
      )}
    </InstallWrap>
  );
}

const InstallWrap = styled.div`
  .install-banner {
    background-color: #f6cfe0;
    border-radius: 0 0 1rem 1rem;
    width: 100%;
    height: 8.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
    z-index: 50;
  }
`;
