import React, { useLayoutEffect, useState } from "react";
import Layout from "../../Components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { createContactEnquiry } from "../../api/contact";

export default function Contactus() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(createContactEnquiry(data));
    console.log(data);
  };

  return (
    <Layout>
      <div>
        <section
          class="relative  bg-white"
          style={{ fontFamily: "Lato" }}
        >
          <div class="relative container px-4 mx-auto">
            <div class="text-center my-6 ">
              <span class="text-xl text-[#2B9BB8]  font-bold">
                Have a Question?
              </span>
            </div>
            <div class="max-w-4xl mx-auto">
              <div class="flex flex-wrap -mx-4 mb-20">
                <div class="w-full lg:w-1/2 px-4 mb-4 lg:mb-0">
                  <div class="bg-[#FDF2F8] rounded-b-lg">
                    <img
                      class="h-64 w-full rounded-lg object-cover"
                      src="https://image.freepik.com/free-photo/contacting-with-friends-via-computer_329181-14587.jpg"
                      alt=""
                    />
                    <div class="px-14 py-8">
                      <div class="mb-6  bg-[#FDF2F8]"></div>
                      <h3
                        class="mb-4 lg:mb-2 md:text-md text-[#2B9BB8] font-bold"
                        style={{ fontFamily: "Lato" }}
                      >
                        Have any question? Send a message!
                      </h3>

                      <div class="md:text-right">
                        <a
                          class="inline-block md:px-12 px-7 md:py-4 py-2 border border-[#0B8CAD] hover:border-[#0B8CAD] rounded-full font-bold text-[#2B9BB8] hover:bg-[#2B9BB8] hover:text-white "
                          onClick={() => setIsMenuOpen(true)}
                          href="#"
                          style={{ fontFamily: "Lato" }}
                        >
                          Message
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="w-full lg:w-1/2 px-4 relative"
                  style={{ fontFamily: "Lato" }}
                >
                  <div class="py-6 px-8 mb-4 bg-[#FDF2F8] rounded-lg">
                    <div class="flex items-center">
                      <span class="flex items-center justify-center md:w-12 md:h-12 w-7 h-7 mr-8 bg-[#2B9BB8] rounded-lg">
                        <svg
                          class="w-4 h-4"
                          width="18"
                          height="18"
                          viewbox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.9347 13.3167C17.811 12.6058 17.2022 12.0401 16.4551 11.94L12.3077 11.3855C11.555 11.2832 10.8101 11.6825 10.4984 12.3528C10.4185 12.5237 10.3521 12.7014 10.2981 12.8848C9.13582 12.4 8.09277 11.7004 7.19375 10.8018C6.29473 9.90315 5.59374 8.86053 5.10992 7.6987C5.29332 7.64471 5.4711 7.57835 5.64213 7.4985C6.31274 7.18695 6.70993 6.44351 6.60866 5.69108L6.05394 1.54536C5.9538 0.797426 5.38784 0.188953 4.66434 0.0641091C4.40105 0.0202451 4.18164 0 3.97348 0C2.61201 0 1.32368 0.71982 0.612565 1.87716C-0.157059 3.13122 -0.0501675 4.73507 0.128736 5.9925C0.533802 8.84029 1.99766 11.7038 4.14451 13.8498C5.74002 15.4446 7.71246 16.6402 9.84693 17.306C10.9339 17.6457 12.2942 18 13.605 18C14.5851 18 15.537 17.802 16.3235 17.2498C17.3733 16.5109 18 15.3052 18 14.0196C18 13.8115 17.9797 13.5911 17.9347 13.3167Z"
                            fill="white"
                          ></path>
                        </svg>
                      </span>
                      <p class="md:text-lg text-sm font-bold text-[#2B9BB8]">
                        +91 9980178584
                      </p>
                    </div>
                  </div>
                  <div class="py-6 px-8 mb-10 bg-[#FDF2F8] rounded-lg">
                    <div class="flex items-center">
                      <span class="flex items-center justify-center md:w-12 md:h-12 w-7 h-7 mr-8 bg-[#2B9BB8] rounded-lg">
                        <svg
                          class="w-5 h-3"
                          width="21"
                          height="14"
                          viewbox="0 0 21 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.8109 0H2.1878C0.981688 0 0 0.980499 0 2.18676V11.8119C0 13.0182 0.981688 14 2.1878 14H18.8122C20.0183 14 21 13.0182 21 11.8119V2.18676C20.9987 0.980499 20.017 0 18.8109 0ZM19.2492 4.73317L11.716 8.67092C11.3393 8.86387 10.9193 8.96887 10.4993 8.96887C10.0794 8.96887 9.66858 8.86387 9.28273 8.67092L1.74945 4.73317V2.75511L10.0872 7.11288C10.3405 7.24414 10.6555 7.24414 10.9101 7.11288L19.2479 2.75511V4.73317H19.2492Z"
                            fill="white"
                          ></path>
                        </svg>
                      </span>
                      <p class="md:text-lg text-sm font-bold text-[#2B9BB8]">
                      emmuniti@gmail.com
                      </p>
                    </div>
                  </div>
                  <div class="  bg-[#FDF2F8] rounded-lg">
                    <iframe
                      // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14535.334502626436!2d54.7227073!3d24.3870905!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e358a42afe0c7%3A0x83b83240062f86ee!2sElegant%20flowers%20Makani%20mall!5e0!3m2!1sen!2sin!4v1668501694063!5m2!1sen!2sin"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5981287469!2d77.54024797551462!3d12.933530915710937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15bd0c1eb6fb%3A0xa6dd2b9e4182522!2sPlexihealth!5e0!3m2!1sen!2sin!4v1718344482529!5m2!1sen!2sin"
                      className="md:w-[430px] w-[350px] h-[185px] rounded-lg"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>

                  {isMenuOpen && (
                    <div class=" top-0 bottom-0 bg-[#FDF2F8]  w-[95%] px-10  py-3  rounded-lg absolute ">
                      <SidebarWrap
                        key="first"
                        initial={{ x: -250, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -280, opacity: 1 }}
                        transition={{
                          duration: 0.36,
                          type: "tween",
                          ease: "easeInOut",
                        }}
                      >
                        <div class=" bg-[#FDF2F8] ">
                          <h2 className="text-[#0B8CAD] text-lg mb-4 mt-3 font-bold   text-start title-font">
                            Contact Us
                          </h2>
                          <form className="h-40 md:h-full" form="form" onSubmit={handleSubmit(onSubmit)}>
                            <div class=" -mx-4 mb-1.5">
                              <div class="w-full  px-4 mb-1.5 lg:mb-0">
                                <div>
                                  <label
                                    class="block text-start mb-1.5 text-sm font-semibold"
                                    for=""
                                  >
                                    <span class="text-sm text-gray-700">
                                      Full Name
                                    </span>
                                    <span class="text-red-600">*</span>
                                  </label>
                                  <input
                                    {...register("name")}
                                    class="w-full py-1 md:py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                                    type="text"
                                    placeholder="Enter your name"
                                    required="true"
                                  />
                                </div>
                              </div>
                              <div class="w-full px-4">
                                <div className="mb-1.5">
                                  <label
                                    class="block text-start mb-1.5 text-sm font-semibold"
                                    for=""
                                  >
                                    <span class="text-sm text-gray-700">
                                      Email
                                    </span>
                                    <span class="text-red-600">*</span>
                                  </label>
                                  <input
                                    {...register("email")}
                                    class="w-full py-1 md:py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                                    type="text"
                                    placeholder="Type your email address"
                                    required="true"
                                  />
                                </div>
                              </div>

                              <div class="w-full px-4">
                                <div className="mb-1.5 md:mb-0">
                                  <label
                                    class="block text-start mb-1.5 text-sm font-semibold"
                                    for=""
                                  >
                                    <span class="text-sm text-gray-700">
                                      Phone
                                    </span>
                                    <span class="text-red-600">*</span>
                                  </label>
                                  <input
                                    {...register("phone")}
                                    class="w-full py-1 md:py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                                    type="number"
                                    placeholder="Type your phone number"
                                    required="true"
                                  />
                                </div>
                              </div>
                              <div class="w-full px-4">
                                <div class="mb-0 ">
                                  <label
                                    class="text-start block mb-1.5 text-sm font-semibold"
                                    for=""
                                  >
                                    <span class="text-sm text-gray-700 ">
                                      Message
                                    </span>
                                    <span class="text-red-600">*</span>
                                  </label>
                                  <textarea
                                    {...register("message")}
                                    class="w-full h-12 py-1 md:py-3 px-4 resize-none text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg"
                                    type="text"
                                    placeholder="Type your message"
                                    required="true"
                                  ></textarea>
                                </div>
                              </div>
                            </div>

                            <button
                              class="relative group mb-20 md:mb-0 inline-block  md:block flex-shrink-0 w-32 md:w-full sm:w-auto py-2 px-1 md:px-10 text-sm font-semibold text-orange-50 bg-[#2B9BB8] hover:bg-sky-500 rounded-full overflow-hidden"
                              type="submit"
                            >
                              <span class="relative text-md md:text-lg">Submit</span>
                            </button>
                          </form>
                        </div>
                      </SidebarWrap>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div class="relative container px-4 mx-auto mt-5">
          <span class="text-2xl text-[#2B9BB8]  font-bold">
                Address
              </span>
          </div> */}
          <div>
          <div className="flex items-center justify-center mb-2 ">
                {/* <p className="flex items-center justify-center w-10 h-10  text-lg font-bold text-black rounded-full bg-deep-purple-accent-400">
              3.
            </p> */}
                <p className="text-2xl font-bold leading-5  text-[#2B9BB8] text-center">
                  Address
                </p>
              </div>
              <p className="text-lg font-bold text-gray-900 mb-16">
              582, 1st floor, 2nd Main Rd, 2nd Phase,<br/> 6th Block, Banashankari 3rd stage,
Banashankari
Bengaluru, Karnataka 560085
              </p>
              </div>
        </section>
      </div>
    </Layout>
  );
}

const SidebarWrap = styled(motion.aside)`
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
`;
