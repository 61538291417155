import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../api/authSlice";
import { authenticateSelector } from "../../api/authSlice";
import Component from '../../Components'


const EditProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(authenticateSelector);

  // Initialize form state with user data
  const [formData, setFormData] = useState({
    user_name: user?.user_name || "",
    email: user?.email || "",
    phone_number: user?.phone_number || "",
    address: user?.address || "",
    password: "", // Include password field
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch an action to update the user profile
    dispatch(updateUser(formData));
    // Redirect the user to their profile page after updating
    // You can use React Router for navigation
  };

  return (
    <div>
     <Component>
      <div className="container mx-auto mt-2">
        <header className="text-center mb-2">
          <h1 className="text-3xl font-bold capitalize text-sky-600">Edit Profile</h1>
        </header>
        <form onSubmit={handleSubmit}>
          <section className="bg-white rounded-md mb-4 shadow-md p-4">
            <h2 className="text-xl font-semibold mb-4 text-start">Profile Information</h2>
            <div className="mb-4 text-start">
              <label
                htmlFor="user_name"
                className="block text-gray-700 font-bold"
              >
                User Name
              </label>
              <input
                type="text"
                id="user_name"
                name="user_name"
                value={formData.user_name}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            <div className="mb-4 text-start">
              <label htmlFor="email" className="block text-gray-700 font-bold">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            <div className="mb-4 text-start">
              <label
                htmlFor="phone_number"
                className="block text-gray-700 font-bold"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone_number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
        
            <div className="mb-4 text-start">
              <label
                htmlFor="password"
                className="block text-gray-700 font-bold"
              >
                New Password
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>


            <div className="mb-4 text-start">
              <label
                htmlFor="password"
                className="block text-gray-700 font-bold"
              >
                New Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md "
            >
              Update Profile
            </button>
          </section>
        </form>
      </div>
      </Component>
    </div>
  );
};

export default EditProfile;
