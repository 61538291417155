import React from 'react'
import Layout from '../../Components'
import Profileone from './Profileone'

const Index = () => {
  return (
    <Layout>
        <Profileone />
    </Layout>
  )
}

export default Index