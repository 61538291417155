import React, { useEffect, useState } from "react";
// import Image from '../../Assets/Duplicate/Desk---INTL-3-jan-2021.jpeg'
import { Form, Input, message } from "antd";
import { fetchUserRegister, authenticateSelector } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { couponSelector, fetchAllCoupons } from "../../api/coupon";
import { toast } from "react-toastify";
import Banner from "../../Assets/banners-04.png"

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const Signinpage = (history) => {
  const nav = useNavigate();

  const { isAuthenticate, loading } = useSelector(authenticateSelector);

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  // const { allcoupon } = useSelector(couponSelector);

  // console.log(allcoupon);

  // useEffect(() => {
  //   dispatch(fetchAllCoupons());
  // }, [dispatch]);

  useEffect(() => {
    if (isAuthenticate) {
      nav("/");
    } else {
      nav("/signup");
    }
  }, [isAuthenticate]);

  const onFinish = (values) => {
    const data = {
      user_name: values.user_name,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password,
      phone_number: values.phone_number,
    };
  
    dispatch(fetchUserRegister(data))
      .then(() => {
        toast.success("User created successfully!", {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error("Registration failed:", error);
      });
  };
  

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setVisible(true);
  };


  
  

  return (
    <section className="h-full">
      
      <div className="lg:px-4 py-4 h-full mt-0 md:mt-20  text-gray-800 min-h-[78vh]">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
          <div className="grow-0 flex justify-center items-center shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
            <img
              src={Banner}
              className="lg:w-[600px]"
              alt="Sample image"
            />
          </div>
       
          <div
            className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0"
            style={{ fontFamily: "Lato" }}
          >
                <h3 class="text-[16px] text-start font-semibold mb-6 text-inline-flex justify-center w-full py-1 text-base font-medium text-[#2B9BB8] focus:outline-none hover:text-sky-800 focus:text-[#855252] sm:text-md leading-4 lg:text-[24px]">
                         Create a new account
                        </h3>
            <Form
              {...layout}
              name="nest-messages"
              onFinish={onFinish}
              initialValues={{ remember: true }}
              onFinishFailed={onFinishFailed}
              className="mt-4"
            >
              <div className="mb-6">
                <Form.Item
                  name="user_name"
                  className="text-start"
                  rules={[{ required: true, message: "Full Name required!" }]}
                >
                  <Input
                    type="text"
                    className="block w-full sm:w-3/4 px-4 py-2 sm:text-[16px] font-normal text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Full name"
                  />
                </Form.Item>
              </div>

              <div className="mb-6">
                <Form.Item
                    rules={[
                      { required: true, message: "Phone number is required" },
                      {
                        pattern: /^\d{10}$/,
                        message: "Please enter a valid 10-digit phone number",
                      },
                    ]}
                  name="phone_number"
                  className="text-start"
                  
                >
                  <Input
                        type="text"
                        inputMode="numeric"
                        required={true}
                    className=" block w-full sm:w-3/4 px-4 py-2 sm:text-[16px] font-normal text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                    placeholder="Phone number"
                  />
                </Form.Item>
              </div>

              <div className="mb-6">
                <Form.Item
                  name="email"
                  className="text-start"
                  rules={[{ required: true, message: "Username required!" }]}
                >
                  <Input
                    // type="number"
                    className=" block w-full sm:w-3/4 px-4 py-2 sm:text-[16px] font-normal text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Email"
                  />
                </Form.Item>
              </div>

              {/* <div className="mb-6">
                <Form.Item
                  name="address"
                  className="text-start"
                  rules={[{ required: true, message: "Address required!" }]}
                >
                  <Input
                    type="text"
                    className=" block w-full sm:w-1/2 px-4 py-2 sm:text-[16px] font-normal text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Address"
                  />
                </Form.Item>
              </div> */}

              {/* <div  className="mb-6">
              <Form.Item  name='address2'
               
              rules={[{ required: true,message: 'Address2 required!' }]}>
              <Input
              type="text"
              className=" block w-full sm:w-1/2 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
              id="exampleFormControlInput2"
              placeholder="Address2"
              />
              </Form.Item>
            </div> */}

              {/* <div className="mb-6">
                <Form.Item
                  name="pincode"
                  className="text-start"
                  rules={[{ required: true, message: "Pincode required!" }]}
                >
                  <Input
                    type="number"
                    className=" block w-full sm:w-1/2 px-4 py-2 sm:text-[16px] font-normal text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Pincode"
                  />
                </Form.Item>
              </div> */}

              <div className="mb-6">
                <Form.Item
                  name="password"
                  className="text-start"
                  placeholder= "Please enter your password!"
                  rules={[
                    {
                      required: true,
                   
                    },
                  ]}
                  hasFeedback
                >
                  <input
                    type="text"
                    className=" block w-full sm:w-3/4 px-4 py-2 sm:text-[16px] font-normal text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="password"
                  />
                </Form.Item>
              </div>

              <div className="mb-6">
                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  className="text-start"
                  placeholder= "Please confirm your password!"

                  rules={[
                    {
                      required: true,
                    
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <input
                    className=" block w-full sm:w-3/4 px-4 py-2 sm:text-[16px] font-normal text-gray-500 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                    placeholder="Confirm password"
                  />
                </Form.Item>
              </div>

              <div className="text-center flex w-52 lg:text-center  rounded-lg ">
                <button
                  className="btn px-4 py-2 shadow-md rounded bg-[#2B9BB8] sm:mr-0 block w-36 sm:full sm:text-[16px] font-normal text-gray-500 bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none "
                  style={{
                    color: "white",
               
                    fontWeight: "500",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Register
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signinpage;
