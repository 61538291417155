import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import { keyUri, config } from '../key'


const token =  localStorage.getItem('token') ?
localStorage.getItem('token') : null

const initialState = {

    all_admin:[],
    loading:false,
    hasError:false,
    current_admin:[],
    isAuthenticate:  token? true : false,
    Resetpassword:false

}


export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {

    getadmin: state => {
      state.loading = true;
    },

    getAll_admin_success: (state, {payload})  =>{

        state.loading = false
        state.all_admin = payload.admin

    },

    get_admin_reset_password:(state,{payload})=>{
         state.loading = false
        state.Resetpassword = true
        state.all_admin = payload.admin
    },


    getCurrentSuccess: (state, {payload}) =>{
        state.loading = false
        state.current_admin = payload.user
        state.isAuthenticate = true 
    
    },

    get_admin_Failure: (state) => {

      state.loading = false
      state.hasError = true
    },

  },
})


export const { getadmin ,getAll_admin_success, getCurrentSuccess, get_admin_Failure,get_admin_reset_password } = adminSlice.actions;



export const adminSelector = state => state.admin;   





export const fetchAllAdmin = () => async dispatch => {
  dispatch(getadmin())
 
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/admin`, config)


   dispatch(getAll_admin_success(data));
    
  } catch (error) {
 
 dispatch(get_admin_Failure())

  }
 };


 export const deleteAdmin = (id, company) => async dispatch => {

  dispatch(getadmin())
  const key = 'create';
  message.loading({ content: 'loading...', key })
  try {
 
   const {data} = await axios.delete(keyUri.BACKEND_URI +`/admin/${id}`)
  data && message.success({ content: data.msg, key, duration: 2 });
   dispatch(fetchAllAdmin());
    
  } catch (error) {


 dispatch(get_admin_Failure())
 
  }
 };

 export const createAdmin = (values) => async dispatch => {

  dispatch(getadmin())
  const key = 'create';
  message.loading({ content: 'loading...', key })
  try {
 
   const {data} = await axios.post(keyUri.BACKEND_URI +`/admin`, values, config)

   data && message.success({ content: data.msg, key, duration: 2 });
   dispatch(fetchAllAdmin());

  } 
  catch ({response}) {
response.data && message.error({ content: response.data.msg, key, duration: 2 })
 dispatch(get_admin_Failure())

  }
 };

 export const fetchOneAdmin = (id) => async dispatch => {

  dispatch(getadmin())
 
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/admin/${id}`)
   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_admin_Failure())
  }
 };


 export const  updateAdminProfile = (id, values) => async dispatch =>{
  const key = "admin"
  dispatch(getadmin())
  message.loading({ content: 'loading...', key })

try {
    const {data} = await axios.put(keyUri.BACKEND_URI +`/admin/${id}`, values, config);
 
    
    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(get_admin_reset_password(data))

} catch ({response}) {

    dispatch(get_admin_Failure())
    // response.data && message.success({ content: response.data.msg, key, duration: 2 });

}
}

export const findUserOTP = (values) =>async dispatch =>{
  console.log(values)
    dispatch(getadmin())
    try{
      const {data} = await axios.get(keyUri.BACKEND_URI+`/findOneUser/${values.phone_number}`)
      console.log(data)
      dispatch(getCurrentSuccess(data));
      localStorage.setItem('token', data.accessToken)
      localStorage.setItem("userinfo", JSON.stringify(data.user));
      data && message.success({ content: `OTP Sent Succesfully to ${data?.user?.phone_number}`,duration: 2 });

    }catch(error){
      error && message.error({ content: error.response.data.msg, duration: 5 });
      dispatch(get_admin_Failure())
     }
}


 

export default adminSlice.reducer;
