import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import store from './api';
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <React.StrictMode>
   
    <Provider store={store}>
      <BrowserRouter>
      
      <App />

      <ToastContainer />
      </BrowserRouter>
      </Provider>
    
  </React.StrictMode>
);

// serviceWorker.unregister();