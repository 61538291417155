import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import Image from '../../Assets/Duplicate/Desk---INTL-3-jan-2021.jpeg'
import {  Form, Input } from "antd";
import { checkAuth, authenticateSelector } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {  useNavigate, useParams } from "react-router-dom";
import Layout from "../../Components";
import {
  cartProductSelector,
} from "../../api/cartSlice";
import { createOrder } from "../../api/order";
import { orderSelector, } from "../../api/order";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import AddCoupon from "./addcoupon";
import { couponSelector } from "../../api/coupon";
import moment from "moment";
import axios from "axios";
import { keyUri } from "../../key";



const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const OrderAddress = () => {
  const nav = useNavigate();

  const dispatch = useDispatch();
  const { cartProducts, total } = useSelector(cartProductSelector);
  const {  shippingcharge } = useSelector(orderSelector);
  const { user } = useSelector(authenticateSelector);
  const { coupons } = useSelector(couponSelector);
  const [selectedOption, setSelectedOption] = useState("cashOnDelivery");
  const totalamount = total + shippingcharge?.shippingAmount;
  const {emmuniti} = useParams()

  console.log(emmuniti)

  
  useEffect(() => {
    const orderData = localStorage.getItem('orderData');
    if (orderData) {
      dispatch(createOrder(orderData));
    }
  }, []); 









//   useEffect(() => {
//     dispatch(checkAuth());
//   }, []);

  const [formData, setFormData] = useState({
    delivred_username: "",
    delivred_district: "",
    delivred_pincode: "",
    delivred_addrees: "",
    delivred_phonenumber: "",
    deliverd_town: "",
    delivred_state: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleOptionChange1 = (event) => {
    setSelectedOption(event.target.value);
  };

//   const data ={
//     name: "suhas",
//     amount: totalamount,
//      number: "9858",
//     // MUID: "M1WSO74VWVN3" ,
//     // transactionId: "emmuniti12345"+ Date.now()
// }

const handlePayOnline = async (e) => {
  e.preventDefault();

  if (selectedOption === "payOnline") {
    // Check if any of the address fields are empty
    if (Object.values(formData).some((value) => value === "")) {
      alert("Please fill in all address fields to pay online");
      return;
    }

    try {   
      const orderData = {
        orderProducts: cartProducts,
        user: user?._id,
        address: user?.address,
        status: "unpaid",
        payment: selectedOption,
        total: coupons?.amount ? coupons.amount : totalamount,
        sub_total: total,
        coupons: coupons?.coupon ? coupons._id : null,
        delivred_state: shippingcharge?.shipping,
        deliverd_date: shippingcharge?.deliveryDate,
        delivred_username: formData?.delivred_username,
        delivred_district: formData?.delivred_district,
        delivred_pincode: formData?.delivred_pincode,
        delivred_addrees: formData?.delivred_addrees,
        delivred_phonenumber: formData?.delivred_phonenumber,
        deliverd_town: formData?.deliverd_town,
        delivred_state: formData?.delivred_state,
      };

      const response = await axios.post(keyUri.BACKEND_URI + '/payment', {...orderData});

  
      console.log(response.data);


      const paymentUrl = response.data.paymentRedirectUrl;

      window.location.href = paymentUrl;
       
     

      // dispatch(createOrder(orderData));
    } catch (error) {
      console.error(error);
    }
  }
};

  

  const handlePlaceOrder = async () => {
    if (selectedOption === "cashOnDelivery") {
    if (Object.values(formData).some((value) => value === "")) {
        alert("Please fill in all address fields.");
        return;
      }
    }

    const orderData = {
      orderProducts: cartProducts,
      user: user?._id,
      address: user?.address,
      status: "unpaid",
      payment: selectedOption,
      total: coupons?.amount ? coupons.amount : totalamount,
      sub_total: total,
      coupons: coupons?.coupon ? coupons._id : null,
      delivred_state: shippingcharge?.shipping,
      deliverd_date: shippingcharge?.deliveryDate,
      delivred_username: formData?.delivred_username,
      delivred_district: formData?.delivred_district,
      delivred_pincode: formData?.delivred_pincode,
      delivred_addrees: formData?.delivred_addrees,
      delivred_phonenumber: formData?.delivred_phonenumber,
      deliverd_town: formData?.deliverd_town,
      delivred_state: formData?.delivred_state,
    };

    // dispatch(createOrder(orderData));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish1 = (values) => {
    // Handle coupon submission here, e.g., validate and apply the coupon code.
    console.log("Coupon Code:", values.delivered_pincode);
  };

 

  return (
    <Layout>
      <OrderWrap>
        <section className="h-full " style={{ fontFamily: "Lato" }}>
          <div className="px-6   h-full text-gray-800">
            <Form
              {...layout}
              name="nest-messages"
              initialValues={{ remember: true }}
              onFinishFailed={onFinishFailed}
              className=""
              style={{ textAlign: "start" }}
            >
              <div className=" md:relative  md:max-w-screen-xl md:min-h-[78vh] w-full md:flex bg-white z-30 shadow-md px-4 mx-auto gap-52  lg:px-32">
                <div>
                  <div className=" flex justify-start gap-4 ">
                    <HiOutlineArrowNarrowLeft
                      className=" text-3xl text-[#2B9BB8] mt-1  md:hidden"
                      onClick={() => nav(-1)}
                    />
                    <h2
                      class="md:mb-8 mb-2 md:text-[24px] text-2xl font-bold text-[#2B9BB8] font-heading text-left"
                      style={{ fontFamily: "Lato" }}
                    >
                      Billing address
                    </h2>
                  </div>

                  {/* <h2 class="mb-8 text-3xl font-bold font-heading text-start  text-[#855252]" style={{fontFamily:'Lato', }} >Billing Address</h2> */}

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500  text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      First name
                    </h1>
                    <Form.Item
                      name="delivred_username"
                      rules={[{ required: true, message: "Name required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-[420px] px-4 items-center py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                          id="exampleFormControlInput2"
                          name="delivred_username"
                          onChange={handleChange}
                          value={formData?.delivred_username}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500  text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Phone number
                    </h1>
                    <Form.Item name="delivred_phonenumber">
                      <Input
                        type="number"
                        className="block  w-full sm:w-full px-4 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_phonenumber"
                        onChange={handleChange}
                        value={formData?.delivred_phonenumber}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Address 1
                    </h1>
                    <Form.Item
                      name="delivred_addrees"
                      rules={[{ required: true, message: "Address required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_addrees"
                        onChange={handleChange}
                        value={formData?.delivred_addrees}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Address 2
                    </h1>
                    <Form.Item
                      name="delivred_town"
                      rules={[{ required: true, message: "Address required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="deliverd_town"
                        onChange={handleChange}
                        value={formData?.deliverd_town}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      District
                    </h1>
                    <Form.Item
                      name="delivred_district"
                      rules={[
                        { required: true, message: "District required!" },
                      ]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_district"
                        onChange={handleChange}
                        value={formData?.delivred_district}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Pincode
                    </h1>
                    <Form.Item
                      name="delivred_pincode"
                      rules={[
                        { required: true, message: "Pincode required!" },
                      ]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_pincode"
                        onChange={handleChange}
                        value={formData?.delivred_pincode}
                      />
                    </Form.Item>
                  </div>

                  <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      State
                    </h1>
                    <Form.Item
                      name="delivred_state"
                      rules={[{ required: true, message: "State required!" }]}
                    >
                      <Input
                        type="text"
                        className=" block w-full sm:w-full px-4 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        required={true}
                        id="exampleFormControlInput2"
                        name="delivred_state"
                        onChange={handleChange}
                        value={formData?.delivred_state}
                      />
                    </Form.Item>
                  </div>

                  {/* <div className="mb-4">
                    <h1
                      className="text-start text-gray-500 text-sm mb-2 font-bold"
                      style={{ fontFamily: "Lato" }}
                    >
                      Pin code
                    </h1>
                    <Form.Item
                      name="delivred_pincode"
                      rules={[{ required: true, message: "Pincode required!" }]}
                    >
                      <Input
                        type="number"
                        className=" block w-full sm:w-full px-4 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
                        id="exampleFormControlInput2"
                        placeholder=""
                      />
                    </Form.Item>
                  </div> */}

                  <h2
                    class="mb-4 md:text-[20px] text:xl font-bold text-[#0B8CAD] text-left"
                    style={{ fontFamily: "Lato" }}
                  >
                    Payment option
                  </h2>
                  <div className="md:flex  mx-3 gap-20 text-start">
                    <div className="cursor-pointer">
                      {/* <div className=" text-yellow-500 grid grid-cols-2 p-0 text-lg m-auto py-2"><FaCoins/><FaCoins/> </div> */}
                      <p
                        className=" md:mb-10 col-span-6 my-auto text-gray-500 "
                        style={{ fontFamily: "Lato" }}
                      >
                        <input
                          className=" mr-2"
                          type={"radio"}
                          name="paymentMethod"
                          value="cashOnDelivery"
                          checked={selectedOption === "cashOnDelivery"}
                          onChange={handleOptionChange1}
                        />
                        Cash on delivery
                      </p>

                      {/* <FiChevronRight className=" text-gray-400 ml-auto my-auto"/> */}
                    </div>

                    <div className=" cursor-pointer md:mb-3 text-start">
                      {/* <p className="mx-auto px-1 rounded font-semibold border-2 border-gray-500  italic">   UPI 
                    <hr className="m-0 mb-1 border-none bg-yellow-400" style={{ height:'2px'}}/>
                    </p> */}
                      <p
                        className="col-span-6  my-auto  text-gray-500"
                        style={{ fontFamily: "Lato" }}
                      >
                        {" "}
                        <input
                          className=" mr-1"
                          type={"radio"}
                          name="paymentMethod"
                          required
                          value="payOnline"
                          checked={selectedOption === "payOnline"}
                          onChange={handleOptionChange1}
                        />
                        Pay online{" "}
                      </p>

                      {/* <FiChevronRight className=" text-gray-400 ml-auto my-auto"/> */}
                    </div>
                  </div>
                </div>
                <div className=" md:mt-24 mb-10 ">
                  <div class="" style={{ fontFamily: "Lato" }}>
                    <h2 class=" flex items-start mb-4 mt-5 sm:text-[20px] text-[18px] font-bold font-heading text-[#0B8CAD]">
                      Order Summary
                    </h2>
                    <div class="p-4 rounded-md shadow-lg border  border-[#0B8CAD] w-80 ">
                      <div class="flex justify-between">
                        <span class="text-purple-800 md:text-[14px] font-bold mb-2">
                          Sub total
                        </span>
                        <span class="sm:text-[14px] font-bold font-heading text-[#0B8CAD]">
                          {" "}
                          Rs {total}/-
                        </span>
                      </div>
                      <div class="flex justify-between">
                        <span class="text-purple-800 sm:text-[14px] font-bold">
                          Shipping charges
                        </span>

                        <span class="sm:text-xl md:text-[14px] font-bold font-heading text-[#0B8CAD]">
                          {shippingcharge?.shippingAmount} /-
                        </span>
                      </div>

                      <div class=" border-b border-blue-100 mb-6">
                        <div class="flex  justify-between ">
                          <span class="text-purple-800 md:text-[14px] font-bold">
                            Coupon discount
                          </span>

                          <span class="sm:text-xl md:text-[14px] font-bold font-heading text-[#0B8CAD]">
                            - {coupons?.discountAmount} /-
                          </span>
                        </div>
                        {coupons?.amount && (
                          <h1 className="mb-3">
                            You have received {coupons?.coupon?.discount} %
                          </h1>
                        )}
                      </div>

                      <div class="flex mb-2 justify-between items-center">
                        <span class="text-[16px] font-bold font-heading text-[#0B8CAD]">
                          Total
                        </span>
                        <span class="text-[16px] font-bold font-heading text-[#0B8CAD]">
                          {" "}
                          Rs {coupons?.amount ? coupons.amount : totalamount}/-
                        </span>
                      </div>

                      <div className="mt-10 ">
                        <p>Discount code</p>
                        <Form
                          name="coupon_form"
                          onFinish={onFinish1}
                          layout="inline"
                        >
                          <Form.Item
                            name="delivered_pincode"
                            rules={[
                              {
                                required: false,
                                message: "Enter your coupon",
                              },
                            ]}
                          >
                            <AddCoupon user={user} total={totalamount} />
                          </Form.Item>
                        </Form>
                      </div>
                    </div>

                    {shippingcharge && (
                      <h1 className="text-[15px]">
                        This order delivery is expected on{" "}
                        {moment(shippingcharge?.deliveryDate).format(
                          "DD/MM/YYYY"
                        )}
                      </h1>
                    )}
                    {/* <div
                      className="text-right lg:text-right py-4 mt-5 rounded"
                      style={{ fontFamily: "Lato" }}
                    >
                      <button
                        className="btn px-2 py-2  rounded shadow-md text-[14px]  w-full flex justify-center"
                        style={{
                          color: "white",
                          backgroundColor: ["#0B8CAD"],
                          fontWeight: "500",
                        }}
                        type="primary"
                        htmlType="submit"
                      >
                        Place Order
                      </button>
                    </div> */}
                      <div class="px-6 mx-auto md:max-w-max">
                    {selectedOption === "cashOnDelivery" ? (
                      <button
                        className="block mb-10 px-10 mt-4 w-full py-2 text-xl leading-6 font-medium tracking-tighter font-heading text-center text-white bg-[#0B8CAD] hover:bg-sky-600  rounded-full"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePlaceOrder();
                          // handleSubmit(e);
                        }}
                      >
                        Place order
                      </button>
                    ) : (
                      <button
                        className="block mb-10 px-10 mt-4 w-full py-2 text-xl leading-6 font-medium tracking-tighter font-heading text-center text-white bg-[#0B8CAD] hover:bg-sky-600  rounded-full"
                        type="button"
                        onClick={handlePayOnline}
                      >
                        Pay Now
                      </button>
                    )}
                  </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </section>
      </OrderWrap>
    </Layout>
  );
};

export default OrderAddress;

const OrderWrap = styled.div`
  .total {
    background-color: #fcfcfc;
    font-size: 0.8rem;
    letter-spacing: 0.3px;
    line-height: 2.3;

    input,
    optgroup,
    select {
      background-color: transparent;
      font-size: 0.72rem;
      border: 1px solid grey;
      border-radius: 0.2rem;
      padding: 0rem;
      margin: 0;
    }
  }

  .payment {
    p {
      font-size: 0.8rem;
      letter-spacing: 0.3px;
      color: grey;
    }
  }

  .address {
    font-size: 0.82rem;
    letter-spacing: 0.3px;
    line-height: 1.8;
    font-weight: 600;
    p {
      font-size: 0.7rem;
      color: grey;
      letter-spacing: 0.3px;
      line-height: 1.65;
      font-weight: 400;
    }
  }
`;
