import React, { useEffect, useLayoutEffect } from "react";
import Hero from '../../Assets/people1.png'

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllTestimonial,
  testimonialSelector,
} from "../../api/testimonial";
import { Interweave } from "interweave";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./index.css";
import Testi from '../../Assets/images/bg-testi.jpg'
import Imag4 from "../../Assets/images/p3.svg"
import People from "../../Assets/people1.png"

export default function Section5() {
  const { testimonial } = useSelector(testimonialSelector);
  const dispatch = useDispatch();

  // console.log(testimonial)

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllTestimonial());
  }, []);

  return (
    <div>
       <section
        className="hidden md:block relative  lg:pb-44 h-[550px] py-10 lg:py-16 overflow-hidden"
        style={{
          // backgroundImage: `url(${Testi})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div class="relative container h-[300px]">
          <div class=" lg:max-w-full mx-auto">
          <div className="flex w-full mb-2 md:mb-10 text-center justify-center flex-wrap">
          
        <h1
          className=" text-2xl md:text-4xl lg:ml-60 lg:mb-5 font-medium text-center title-font text-block-700  mb-4 "
          style={{ fontFamily: "Lato" }}
        >
          Testimonials
        </h1>
        {/* <p className="lg:pl-6 lg:w-1/2 mx-auto hidden sm:block leading-relaxed text-right px-14 text-base">View More</p> */}
      </div>

            <Swiper
              slidesPerView={1}
              spaceBetween={1}
              navigation={true}
              loop={true}
              // autoplay={{
              //   delay: 3500,
              //   disableOnInteraction: false,
              // }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                1000: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
              }}
            >
              {testimonial?.map((item, i) => (
                <SwiperSlide className="" key={i}>
                  {/* <div class="flex -mx-4 items-center justify-center w-full ">
                   
                      <div class="lg:max-w-md xl:max-w-lg">
                        <img
                          class="block w-full rounded-lg"
                          src={item?.image}
                          alt=""
                        />
                      </div>

                      <div class="w-full lg:w-[800px] px-4 text-center">
                      <div class="w-full lg:w-4/12">
                <img class="block h-full w-full rounded-4xl object-cover" src={People} alt=""/>
              </div>
                        <div class="">
                        
                          <p class="text-base text-gray-700 text-center ">
                            <Interweave    style={{ fontFamily: "Lato" }} content={item?.description} />
                          </p>
                          <span class="block text-xl font-semibold mt-2 text-center text-gray-800">
                            {item?.author}
                          </span>
                        </div>
                      </div>
                      
                    </div> */}
<section class='lg:ml-40'>
  <div class="container mx-auto flex flex-wrap justify-between px-16">
    <div class="flex flex-wrap items-center">
      <div class="w-full md:w-1/2 mb-12 md:mb-0">
        <div class="max-w-lg mx-auto md:mx-0 overflow-hidden">
          <div  class="">
            <img class="block flex-shrink-0 h-full w-[440px]" 
              src={item?.image}
              alt=""
            />
            {/* <img class="block flex-shrink-0 h-96 w-full" src={Hero}alt=""/>
            <img class="block flex-shrink-0 h-96 w-full" src={Hero}alt=""/> */}
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 ">
        <div class=" mx-auto md:mr-0 overflow-hidden">
          <div  class="" >
            <div class="flex-shrink-0 w-full pr-16">
              {/* <h4 class="text-3xl lg:text-3xl font-medium mb-10">{item?.description}</h4> */}
              <Interweave class="text-3xl lg:text-3xl  font-medium mb-10"   style={{ fontFamily: "Lato" }} content={item?.description} />
              <span class="block text-2xl mt-9 font-medium">{item?.author}</span>
              {/* <span class="block mb-12 text-lg text-gray-700">Solar energy service</span> */}
            </div>
            {/* <div class="flex-shrink-0 w-full">
              <h4 class="text-3xl lg:text-4xl font-medium mb-10">“Efficient, green tech, outstanding service”</h4>
              <span class="block text-xl font-medium">John Jones</span>
              <span class="block mb-12 text-lg text-gray-700">CE0 Solar Company</span>
            </div> */}
            {/* <div class="flex-shrink-0 w-full">
              <h4 class="text-3xl lg:text-4xl font-medium mb-10">“Flow transformed my energy use, efficient, green tech, outstanding service.”</h4>
              <span class="block text-xl font-medium">James Harrison</span>
              <span class="block mb-12 text-lg text-gray-700">Developer</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</SwiperSlide>
              ))}
            </Swiper>
          </div> 
        </div>
      </section>
      

      {/* -----------------------------------------------------mobile---------------------------------------------- */}

      <section class="block md:hidden">
      {/* <div class="w-full text-md font-semibold text-gray-800 leading-relaxed  ">
          <div style={{ fontFamily: "Lato" }} class="max-w-xs mx-auto text-center">
            <img class="mx-auto mb-2 border rounded-full p-4 w-20" src={Imag4} alt=""/>
       
            SAFE TO USE
          </div>
        </div> */}
        <div class=" container mx-auto">
        <div class="max-w-lg lg:max-w-7xl mx-auto mb-20">
          <div className="flex w-full mb-2 md:mb-10 justify-center items-center flex-wrap">
        <h1
          className=" text-2xl md:text-4xl font-medium text-center title-font text-sky-700 lg:w-1/2 lg:mb-0 mb-4 "
          style={{ fontFamily: "Lato" }}
        >
          Testimonials
        </h1>
  
      </div>

            <Swiper
              slidesPerView={1}
              spaceBetween={1}
              navigation={true}
              loop={true}
              // autoplay={{
              //   delay: 3500,
              //   disableOnInteraction: false,
              // }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                1000: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
              }}
            >
              {testimonial?.map((item, i) => (
                <SwiperSlide className="" key={i}>
                  {/* <div class="flex items-center justify-center w-full ">
                   
                      <div class="lg:max-w-md xl:max-w-lg">
                        <img
                          class="block w-full rounded-lg"
                          src={item?.image}
                          alt=""
                        />
                      </div>

                      <div class="w-full lg:w-full px-4 text-center">
                        <div class="">
                          <p class="text-base text-gray-700 text-center ">
                            <Interweave    style={{ fontFamily: "Lato" }} content={item?.description} />
                          </p>
                          <span class="block text-lg font-semibold mt-2 text-center text-gray-800">
                            {item?.author}
                          </span>
                        </div>
                      </div>
                    </div> */}


<section class='flex flex-wrap justify-between'>
  <div class="container mx-auto flex flex-wrap justify-between px-4 h-full">
    <div class="flex flex-wrap items-center">
      <div class="w-full md:w-1/2 mb-12 md:mb-0">
        <div class="max-w-lg mx-auto md:mx-0 overflow-hidden">
          <div  class=" ml-20">
            {/* <img class="block flex-shrink-0 h-96 w-full" src={Hero}alt=""/> */}
            <img
              class="block flex-shrink-0 rounded-lg w-60 items-center"
              src={item?.image}
              alt=""
              />
            {/* <img class="block flex-shrink-0 h-96 w-full" src={Hero}alt=""/>
            <img class="block flex-shrink-0 h-96 w-full" src={Hero}alt=""/> */}
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:pt-20">
        <div class=" mx-auto md:mr-0 overflow-hidden">
          <div  class="item-center justify-center lg:pt-10" >
            <div class="flex-shrink-0 w-full">
              {/* <h4 class="text-3xl lg:text-4xl font-medium mb-10">{item?.description}</h4> */}
              <Interweave  class="text-3xl lg:text-4xl pl-10 pr-20 font-medium mb-10"  style={{ fontFamily: "Lato" }} content={item?.description} />
              <span class="block text-xl font-medium">{item?.author}</span>
              {/* <span class="block mb-12 text-lg text-gray-700">Solar energy service</span> */}
            </div>
            {/* <div class="flex-shrink-0 w-full">
              <h4 class="text-3xl lg:text-4xl font-medium mb-10">“Efficient, green tech, outstanding service”</h4>
              <span class="block text-xl font-medium">John Jones</span>
              <span class="block mb-12 text-lg text-gray-700">CE0 Solar Company</span>
            </div> */}
            {/* <div class="flex-shrink-0 w-full">
              <h4 class="text-3xl lg:text-4xl font-medium mb-10">“Flow transformed my energy use, efficient, green tech, outstanding service.”</h4>
              <span class="block text-xl font-medium">James Harrison</span>
              <span class="block mb-12 text-lg text-gray-700">Developer</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
                
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
}
