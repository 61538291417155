import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Form, Input, Button } from "antd";
import { fetchUserOrder, orderSelector } from "../../api/order";
import { authenticateSelector, checkAuth } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { MdRemoveShoppingCart } from "react-icons/md";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Components";
import { FiChevronRight } from "react-icons/fi";
import styled from "styled-components";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

export default function Order() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const { user } = useSelector(authenticateSelector);
  const [user, setUser] = useState('')

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  useEffect(() => {
    const userInfo = localStorage.getItem("userinfo")
      ? JSON.parse(localStorage.getItem("userinfo"))
      : null;

    setUser(userInfo);
  }, []);

  const { id } = useParams();
  const { order, loading } = useSelector(orderSelector);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (!user) {
  //   return <div>User not found</div>;
  // }


  const [orders, setOrder] = useState([]);
  const nav = useNavigate();

  console.log(order);

 

 

  useEffect(() => {
    dispatch(fetchUserOrder(user?._id));
  }, [user]);

  const getStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case "Ordered":
        return "text-blue-700 "; 
      case "Order recived":
        return "text-pink-700"; 
      case "Order shipped":
        return "text-yellow-700"; 
      case "Order Delivered":
        return "text-green-700"; 
      default:
        return ""; 
    }
  };

  // useLayoutEffect(()=>{
  //   let orders = order?.filter((item)=>{

  //   })
  //   setOrder([])

  //   console.log(orders)

  //   }, [order])

  return (
    <Layout>
      {order?.length === 0 ? (
        <div className="relative max-w-screen-xl min-h-[78vh] px-4 py-8  mx-auto">
          <div
            className="flex justify-center items-center flex-col mt-32"
            style={{ fontFamily: "Lato" }}
          >
            <MdRemoveShoppingCart
              className="text-zinc-300 opacity-30 mb-4 "
              style={{ fontSize: "9.7rem" }}
            />
            <h1
              className=" text-md font-medium text-sky-700"
              style={{ fontFamily: "Lato" }}
            >
              Your Order is empty
            </h1>
          </div>
          <div className="flex justify-center w-full">
            <div className=" rounded items-center bg-[#2B9BB8] my-4 w-[230px] py-2 ">
              <Link to="/">
                {" "}
                <h1 className="text-white" style={{ fontFamily: "Lato" }}>
                  SHOP NOW!
                </h1>{" "}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div class="relative max-w-screen-xl px-4 py-8 mx-auto min-h-[72vh]">
          <div
            className="header flex justify-start p-2 mt-2 items-center"
            style={{ height: "45px" }}
          >
            <HiOutlineArrowNarrowLeft
              className=" text-2xl m-0 text-[#0B8CAD]"
              onClick={() => nav("/")}
            />
            <h1
              className="mx-2 m-0 md:text-[24px] text-[16px] text-[#0B8CAD]"
              style={{ fontFamily: "Lato" }}
            >
              Your orders
            </h1>
          </div>

          {!loading && order?.length === 0 ? (
            <div>
              <div
                className="flex justify-center items-center flex-col mt-32"
                style={{ fontFamily: "Lato" }}
              >
                <MdRemoveShoppingCart
                  className="text-zinc-300 opacity-30 mb-4 "
                  style={{ fontSize: "9.7rem" }}
                />
                <h1
                  className=" text-md font-medium text-[#FDF2F8]"
                  style={{ fontFamily: "Lato" }}
                >
                  You have no orders - Shop now!{" "}
                </h1>
              </div>
              <div className="flex justify-center w-full">
                <div className=" rounded items-center bg-[#FDF2F8] my-4 w-[230px] py-2 ">
                  <Link to="/">
                    {" "}
                    <h1 className="text-white" style={{ fontFamily: "Lato" }}>
                      SHOP NOW!
                    </h1>{" "}
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <>
              {order?.map((item, i) => {
                return (
                  <Link to={`/orderdetails/${item._id}`} key={i}>
                    <div className="my-3" style={{ fontFamily: "Lato" }}>
                      <div className="  py-2 orderid px-4 border  rounded-lg">
                        {/* <p>Status: {item?.orderStatus === 'Delivered' ? 'Delivered'
              : moment().isBetween(item?.advertisement?.basic_info?.sale_date[0] , item?.advertisement?.basic_info?.sale_date[1]) ? 'Odered' : 'Shipped'   }</p> */}

                        <div
                          className="flex justify-between  md:text-[12px] text-[10px] items-center text-black"
                          style={{ fontFamily: "Lato" }}
                        >
                          <div>
                            <h1
                              className=" text-black text-start "
                              style={{ fontFamily: "Lato" }}
                            >
                              Order No. : {item?.orderId}
                            </h1>
                          </div>
                          <div>
                            <p className="mr-1">
                              Date : {moment(item?.date).format("MMMM Do YYYY")}
                            </p>
                          </div>
                        </div>

                        <div className="flex justify-between md:text-[12px] text-[10px] items-center text-black">
                          <div>
                            <p className="">No. of items : {item?.quantity}</p>
                          </div>
                          <div>
                            <p className=" pr-3">Total : Rs {item?.total}/-</p>
                          </div>
                          <div>
                            <p className={`uppercase   text-base font-semibold ${getStatusColor(item?.order_status)}`}>
                              Status : {item?.order_status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </>
          )}
        </div>
      )}
    </Layout>
  );
}
