import { createSlice } from "@reduxjs/toolkit";

const cart = localStorage.getItem("emmuniticart")
  ? JSON.parse(localStorage.getItem("emmuniticart"))
  : [];

console.log(cart);

// const total =  localStorage.getItem('total') ?
// JSON.parse(localStorage.getItem('total')) : 0

let total = cart.reduce((total, num) => {
  return total + num?.selectedVariant?.total_price * num?.selectedVariant?.qty;
}, 0);

// let shippingtotal = cart.reduce((total, ship)=>{
//     return total + (num.selectedVariant.total_price * num.selectedVariant.qty)
// }, 0)

console.log(total);

export const initialState = {
  loading: false,
  hasErrors: false,
  cartProducts: cart,
  subTotal: 0,
  total: Number(total),
  isUsedWalletBallance: 0,
  isUsedWallet: false,
};

console.log(total);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    getCartProducts: (state) => {
      state.loading = true;
    },

    getTotal: (state) => {
      state.total = state.cartProducts.reduce((total, crr) => {
        return (
          total + crr.selectedVariant.total_price * crr.selectedVariant?.qty
        );
      }, 0);
    },

    //   shippingdate: (state, { payload }) => {
    //     console.log(payload);

    //     // Create variables to store the total shipping amount and the latest delivery date
    //     let totalShippingAmount = 0;
    //     let latestDeliveryDate = new Date();

    //     state.cartProducts.forEach((product) => {
    //       // Calculate the shipping amount and update the product
    //       if (payload.startsWith('56')) {
    //         product.shippingAmount = 30;
    //       } else if (payload.startsWith('57')) {
    //         product.shippingAmount = 40;
    //       } else {
    //         product.shippingAmount = 50;
    //       }

    //       // Calculate the delivery date and find the latest date
    //       const deliveryDate = new Date();
    //       if (payload.startsWith('56')) {
    //         deliveryDate.setDate(deliveryDate.getDate() + 2);
    //       } else if (payload.startsWith('57')) {
    //         deliveryDate.setDate(deliveryDate.getDate() + 3);
    //       } else {
    //         deliveryDate.setDate(deliveryDate.getDate() + 4);
    //       }

    //       if (deliveryDate > latestDeliveryDate) {
    //         latestDeliveryDate = deliveryDate;
    //       }

    //       totalShippingAmount += product.shippingAmount;
    //     });

    //     // Create an object to store the total shipping amount and latest delivery date
    //     const shippingInfo = {
    //       totalShippingAmount,
    //       latestDeliveryDate,
    //     };

    //     // Update local storage with the shipping information
    //     localStorage.setItem('shippingInfo', JSON.stringify(shippingInfo));

    //     state.loading = false;
    //     state.hasErrors = false;
    //     state.total = totalShippingAmount;

    //     // Update local storage with the cart products
    //     localStorage.setItem('emmuniticart', JSON.stringify(state.cartProducts));
    //   },

    discountupdate: (state, { payload }) => {
      console.log(payload);

      // Calculate the new total based on the discount
      const newTotal = state.cartProducts.reduce((total, currentProduct) => {
        // Calculate the discounted price for each product
        const discountedPrice = Math.floor((total * (100 - payload)) / 100);

        console.log(discountedPrice);

        // Add the discounted price of the current product to the running total
        return total + discountedPrice;
      }, 0);

      // Update the state's total with the new total
      state.total = newTotal;
    },

    removeItem: (state, { payload }) => {
      console.log(payload);

      console.log(state.cartProducts);

      state.cartProducts = state?.cartProducts.filter((item) => {
        console.log(item);

        return item?._id !== payload;
      });
      state.total = state.cartProducts.reduce((total, crr) => {
        return (
          total + crr.selectedVariant.total_price * crr.selectedVariant?.qty
        );
      }, 0);

      localStorage.setItem("emmuniticart", JSON.stringify(state.cartProducts));
      // localStorage.setItem('total', JSON.stringify(state.total) )
    },

    getCartProductSuccess: (state, { payload }) => {
      state.cartProducts = [...state.cartProducts, payload];

      state.loading = false;
      state.hasErrors = false;
      state.total = state.cartProducts.reduce((total, crr) => {
        return (
          total + crr.selectedVariant.total_price * crr.selectedVariant?.qty
        );
      }, 0);

      localStorage.setItem("emmuniticart", JSON.stringify(state.cartProducts));
      //  localStorage.setItem('total', JSON.stringify(state.total) )
    },

    increments: (state, { payload }) => {
      console.log(state.cartProducts);

      console.log({ q: payload });
      const exist = state.cartProducts.findIndex(
        (item) => item._id === payload.id
      );

      if (exist !== -1) {
        state.cartProducts[exist].selectedVariant.qty = payload.qty;
      }
      state.loading = false;
      state.hasErrors = false;
      state.total = state.cartProducts.reduce((total, crr) => {
        return (
          total + crr.selectedVariant.total_price * crr.selectedVariant?.qty
        );
      }, 0);

      localStorage.setItem("emmuniticart", JSON.stringify(state.cartProducts));
    },

    getCartProductsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  getCartProductsFailure,
  getCartProductSuccess,
  getCartProducts,
  increments,
  shippingdate,
  getTotal,
  datetime,
  getDatetime,
  removeItem,
  discountupdate,
  getWalletBalance,
} = cartSlice.actions;

export const cartProductSelector = (state) => state.cart;

export default cartSlice.reducer;

export const addCartProducts = (data) => async (dispatch) => {
  console.log(data);
  dispatch(getCartProducts());

  try {
    dispatch(getCartProductSuccess(data));
  } catch (error) {
    dispatch(getCartProductsFailure());
  }
};

export const datetimeUpdate = (id) => async (dispatch) => {
  dispatch(datetime(id));
  dispatch(getDatetime());
};

export const qtyupdate = (id) => async (dispatch) => {
  dispatch(getCartProducts());

  try {
    dispatch(increments(id));
  } catch (error) {
    dispatch(getCartProductsFailure());
  }
};

export const dateupdate = (pincode) => async (dispatch) => {
  console.log(pincode);

  dispatch(getCartProducts());

  try {
    dispatch(shippingdate(pincode));
  } catch (error) {
    dispatch(getCartProductsFailure());
  }
};

export const coupondiscount = (data) => async (dispatch) => {
  console.log(data);

  dispatch(getCartProducts());

  try {
    dispatch(discountupdate(data));
  } catch (error) {
    dispatch(getCartProductsFailure());
  }
};

// export const dateupdate = (id) => async  dispatch =>{
//     console.log(id)

//     dispatch(getCartProducts())

//     try {
//         dispatch(datetime(id))

//     } catch (error) {
//         dispatch(getCartProductsFailure())

//     }
// }

export const deleteCartItem = (data) => async (dispatch) => {
  try {
    console.log(data);

    dispatch(removeItem(data));
    // window.location.reload()
  } catch (error) {
    console.log(error);
  }
};

export const clearCartData = (data) => async (dispatch) => {
  localStorage.removeItem("emmuniticart");
  window.location.reload();
};
