import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { BiRupee } from "react-icons/bi";
import { Button } from "antd";
import { AiOutlineShoppingCart } from "react-icons/ai";

export default function Catagoriespage({ data, products }) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(data);

  console.log(products);

  return (
    <div>
      <section className="bg-slate-50">
        <div className=" min-h-[78vh] mb-4  md:py-10  mx-auto  ">
          <div className="relative  mx-auto text-center  lg:w-4/5">
            <img
              alt="gallery"
              className=" rounded-md cursor-pointer object-cover md:w-full   lg:h-[50vh]  object-center block  inset-0"
              src={data?.category_images}
            />
          </div>
          <div class=" w-full ">
            <h2
              className="relative  inline-block px-4 text-2xl md:text-3xl text-md border-[#0B8CAD] border-b-2  text-center my-8  text-black  bg-[#FFFF]"
              style={{ fontFamily: "Lato" }}
            >
              {data?.category_name}
            </h2>

            <section className="bg-slate-50 mb-32 md:mb-0" style={{ fontFamily: "Lato" }}>
              <div class="container md:px-32   mx-auto ">
                <div class="grid grid-cols-1  lg:grid-cols-3 sm:gap-x-16 gap-x-8 sm:gap-y-8 gap-y-2">
                  {products?.map((items) => (
                    <div class=" w-full ">
                      {items?.stock_qty <= 0 && (
                        <p className=" bg-black  absolute flex justify-center items-end md:pb-20 pb-16 sm:h-[350px] pt-2 h-56 sm:w-[265px] w-full  bg-opacity-30 text-center md:text-2xl text-xl text-white">
                          {" "}
                          <span>Out of stock </span>
                        </p>
                      )}
                      <div class=" h-[480px]  md:h-[500px]   bg-white rounded-3xl ">
                     
                      <a class="block mx-auto max-w-max" >
                    <img
                      class="h-72 w-96 md:h-80 md:w-96 object-cover"
                      src={items?.featured_image}
                      alt="product image"
                    />
                  </a>
                    

                        <div className="w-full mt-4">
                       
                            <p class="mb-2 text-xl md:text-2xl leading-8 text-center text-sky-700 font-heading font-medium uppercase">
                              {items?.product_name}
                            </p>
              
                          <p class=" mb-1 text-center  font-heading font-medium tracking-tighter">
                            <span className="text-red-500 text-sm">
                              % {items?.product_variants[0]?.discount}
                            </span>
                            <span class=" text-xl ml-2 text-sky-600 text-center ">
                              Rs {items?.product_variants[0]?.total_price}
                            </span>
                          </p>
                          <p className="text-center text-sm mb-2 line-through text-gray-700">
                            M.R.P{items?.product_variants[0]?.price}
                          </p>
                          <Link to={`/Product/${items?._id}`} className="mx-0">
                            <div className="flex justify-center items-center w-full">
                              <div class="max-w-max text-center block py-4 px-10 w-full text-lg leading-5 text-white font-medium tracking-tighter font-heading  bg-[#2B9BB8] hover:bg-sky-600 focus:ring-2 focus:ring-sky-500 focus:ring-opacity-50 rounded-xl">
                              
                                  Buy Now
             
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}

const StockWrap = styled.div`
  .text2 {
    position: relative;
    width: 100%;
    /* height: 355px; */
  }
  .text2::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(17, 14, 4, 0.877);
    content: "recent event";
    transform: translate(-50%, -50%);
    color: rgb(255, 216, 44);
    opacity: 0;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    text-align: center;
    padding-top: 50%;
    font-size: 1.5rem;
    text-transform: capitalize;
  }
  .text2:hover::before {
    opacity: 1;
  }
`;
