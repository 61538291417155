import React from "react";
import { Link } from "react-router-dom";
import { authenticateSelector } from "../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { logOut } from "../api/authSlice";
import { CgProfile } from "react-icons/cg";
import styled from "styled-components";
import { cartProductSelector } from "../api/cartSlice";

const Navbar = () => {
  const { isAuthenticate } = useSelector(authenticateSelector);
  const dispatch = useDispatch();
  const { cartProducts } = useSelector(cartProductSelector);
  const user = JSON.parse(localStorage.getItem("userinfo"));
  const isLoggedIn = !!user;
  

  return (
    <header className="hidden  bg-sky-800 text-end w-full">
      <div className="container w-full block" style={{ fontFamily: "Poppins" }}>
        <div className="inline-flex justify-end">
          <div className="hidden md:flex">
            {isLoggedIn ? (
              <div className="flex justify-between gap-3 pt-1 mr-20">
                <div className="flex pb-2 items-center justify-center pb-1">
                  <CgProfile className="text-xl text-white mt-1 mr-2" />
                  <span className="text-md text-white capitalize">
                    {user?.user_name}
                  </span>
                </div>
                <Link
                  to="/"
                  onClick={() => {
                    dispatch(logOut());
                  }}
                >
                  <button className="text-[#0B8CAD] font-semibold  mb-2 px-4 rounded-full transition duration-300 ease-in-out">
                    Logout
                  </button>
                </Link>
              </div>
            ) : (
              <div>
                <Link to="/profile">
                  <button className="text-[#0B8CAD] mr-4 font-semibold py-1 px-4 rounded-full transition duration-300 ease-in-out">
                    Login
                  </button>
                </Link>
              </div>
            )}
            <div className="col-span-1 mt-2 items-center justify-center mr-6 hidden md:block">
              <Link to="/cart">
                <div className="flex justify-between gap-3">
                  <AiOutlineShoppingCart
                    className="text-xl text-[#ffff]"
                    style={{ fontSize: "22px" }}
                  />
                  <span className="text-md text-[#ffff]">Cart</span>
                </div>
                <Badge isVisible={cartProducts.length > 0}>
                  <p>{cartProducts.length}</p>
                </Badge>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const Badge = styled.div`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  position: absolute;
  z-index: auto;
  min-width: 15px;
  height: 15px;
  padding: 0 3px;
  color: #f9f9f9;
  font-size: 10px;
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  font-weight: bold;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
  transform: translate(14px, -28px);
`;

export default Navbar;
