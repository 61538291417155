import React, {useEffect, useState} from 'react'
import {  qtyupdate,  cartProductSelector,deleteCartItem } from '../../api/cartSlice'
import {  useDispatch, useSelector } from 'react-redux';
import {useParams,useNavigate} from 'react-router-dom'
import {fetchOneProduct,} from '../../api/products' 
import { Image } from 'antd';
import {  AiOutlineDelete } from "react-icons/ai";
import moment from 'moment'
import {fetchlogin, authenticateSelector,checkAuth} from '../../api/authSlice'
import {MdRemoveShoppingCart} from 'react-icons/md'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import {Link} from 'react-router-dom'
import {fetchUserOrder,orderSelector,fetchOneOrder } from '../../api/order'
import Layout from '../../Components'
import { ToastContainer } from 'react-toastify';



const OrderSummery = () => {


  const {id} = useParams()
  const dispatch = useDispatch()
  const { isAuthenticate } = useSelector(authenticateSelector)
  const { user } = useSelector(authenticateSelector)
  const nav=useNavigate()
  const {cartProducts, total} = useSelector(cartProductSelector)
  const [productsArray, setProductsArray] = useState([]);
  const {currentOrder, loading} = useSelector(orderSelector)
 


  
  useEffect(()=>{
  dispatch(fetchOneProduct(id))
  }, [ id])

  
  useEffect(()=>{  
    dispatch(checkAuth()) 
    }, [dispatch])



  const increment = (id) =>{
    let data = {id, incr:'incr'}
    dispatch(qtyupdate(data))
  }

 
  
  const decrement = (id) =>{
    let data = {id, decr:'decr'} 
    dispatch(qtyupdate(data))
  }


  useEffect( () => {

    var helper = {};

                  
  let arr1 = cartProducts.map((item)=>{
    return {
      date:item.date,
      time:item.time,
      products:[item]
    }
  })

  
     var resultProducts = arr1.reduce(function(r, obj) {  
     var key = obj.date + '-' + obj.time;
  
      if(!helper[key]) {
        helper[key] = Object.assign({}, {date:obj.date, time:obj.time, products:obj.products}); // create a copy of obj
        r.push(helper[key]);
      } else {
  
        helper[key].products = [...helper[key].products,  ...obj.products];
    }
    return r;
    }, []);
  
    setProductsArray(resultProducts)
  
  }, [cartProducts])



  return (
    <div>
        <Layout>
        <section>

        {
            cartProducts.length === 0 ? 
            <div className='relative max-w-screen-xl  px-4 py-6 mx-auto' > 
                <div className="flex justify-center items-center flex-col mt-32" style={{fontFamily:'Lato'}}>
                    <MdRemoveShoppingCart className="text-gray-300 mb-4" style={{ fontSize:'6.7rem'}}/>
                    <h1 className=" text-xs font-medium text-[#855252]" >CART IS EMPTY - Shop now! </h1>
                </div>
                <div className=" grid grid-cols-1 items-center text-center bg-[#855252] my-4 w-full py-4 ">
                    <Link to='/'> <h1 className='text-white' >SHOP NOW!</h1> </Link>
                </div>
           </div>
                
           :

           <div className='min-h-[76vh]'>
                <div className="header px-2 mt-6 " >
                     {/* <HiOutlineArrowNarrowLeft className=" text-2xl m-0"  onClick={()=>  nav('/')} />  */}
                </div>


                <h3 className="text-[12px] sm:text-[16px] pl-2 text-[#855252]" style={{fontFamily:'Lato'}}>
              <span className=' font-bold  underline '>ORDER SUMMERY </span>---------<Link to="/orderaddress"> ADDRESS</Link> ---------<Link to="/payment"> PAYMENT</Link>
              </h3>




      <h1 class="sr-only">Checkout</h1>
      <div class="relative mx-auto max-w-screen-xl">  
      <div class=" py-6 bg-white shadow-lg mb-8 ">
      <div class=" px-4 mx-auto lg:px-8">
      <div class="flex items-center">
           
          </div>

         

          <div className=' bg-slate-50 z-30'>
            
            <div className="grid grid-cols-5 gap-4 sm:gap-10 font-bold text-[12px] sm:text-[16px] " style={{fontFamily:'Lato', }} >

              <div className="col-span-2"><h6 className="text-light  py-2 sm:pl-5 pl-3" style={{textAlign:'start', }} >Products</h6></div>
              <div className="col-span-1"><h6 className="text-light py-2 mb-0">Price</h6></div>
              <div className="col-span-1"><h6 className="text-light py-2 mb-0">Quantity</h6></div>
              <div className="col-span-1"><h6 className="text-light py-2 mb-0 ">Remove</h6></div>
              
            </div>
          </div>


          {
              productsArray.map((item, i)=>{
                return  <div className='shadow-sm my-3 py-1'>



          {
                item.products?.map(prd=>{
                  return <div key={i} className="grid grid-cols-5 sm:gap-10 gap-3 text-center text-light align-items-center  py-1 ">
                  <div className="col-span-2 flex sm:pl-4 pl-1">
                  
                    <Image    
                    preview={false}               
                   className=" md:w-[64px] w-[50px] md:h-[60px] h-[40px]  block"
                    src={ prd?.featured_image  }
                    placeholder='image'  
                    width="680" height="805"            
                    />              
                    <small className=" my-auto pl-4 text-[11px] sm:text-[16px]  " style={{ textTransform:"capitalize", fontFamily:'Lato'}}><b>{prd.product_name}</b></small>
                    </div>
                 
    
                   <div className="col-span-1 text-[13px] sm:text-[16px] pt-3 sm:pt-4" style={{fontFamily:'Lato'}}><h6>Rs {prd?.total_price}</h6></div>
                   <div className="flex justify-between items-center sm:px-12 py-1" style={{fontFamily:'Lato'}}> 
                   <button className="text-center bg-[#855252] rounded sm:w-8 w-11 h-5 sm:h-8 text-white "  disabled={(prd.qty === 1)? true : false} type="primary" onClick={()=>decrement(prd?._id)}>-</button>
                   <button className=" text-center shadow-sm  rounded sm:w-8 w-12 h-5 sm:h-8 text-[#855252]">{prd?.qty}</button>
                   <button className=" text-center shadow-sm bg-[#855252] rounded sm:w-8 w-11 h-5 sm:h-8 text-white"   onClick={()=>increment(prd?._id)} >+</button>
                   </div>

                   <div className="col-span-1 align-items-center mx-auto pt-3 sm:pt-4 text-xl  cursor-pointer " style={{fontFamily:'Lato'}}>
                   <AiOutlineDelete onClick={()=>dispatch(deleteCartItem(prd._id))} className="close "/>
                   </div>
            
                   </div>
                })
                    
                }
                 <h1 className= "text-[#855252] text-[9.5px] sm:text-[16px] text-start py-2 pl-3" style={{fontFamily:'Lato'}}> Delivery Date and Time :  {moment(item?.date).format('MMMM Do YYYY')} {item?.time} </h1>

                </div>

              })
               }
 
                <div className="grid grid-cols-2 px-2 mt-5 font-medium total  text-[11px] sm:text-[16px] p-4 bg-slate-50"  style={{ fontFamily:'Lato' }} >                                     
                <p >  Total Amount</p>
                <p className=""> {total}/-</p>
                </div>

                
                   <Link to='/orderaddress'>
                   <div className="text-center lg:text-right mt-5 rounded">
                   <button className="btn px-9 py-2  my-1 rounded shadow-md"  style={{color:'white', backgroundColor:'#855252', fontWeight:'500'}} type="primary" htmlType="submit">
                   CONTINUE
                  </button>
                   </div>
                   </Link>
                  
                   <ToastContainer/>
              </div>
              </div>          
              </div>
              </div>
             
       
               }
              </section>
              </Layout>
               </div>
  )
}

export default OrderSummery