// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { adminSelector } from "../../api/admin";
// import {getAuth,RecaptchaVerifier,signInWithPhoneNumber,} from "firebase/auth";
// import { Link, useNavigate } from "react-router-dom";
// import app from "./storage";
// import {checkAuth,authenticateSelector, findUserOTP, fetchPhonelogin} from '../../api/authSlice'

// import "firebase/auth";
// import { Button, Form, Input, message } from "antd";
// const auth = getAuth();
// function LoginWithOTP({ switchToLogin }) {
//   const [flag, setFlag] = useState(true);
//   const dispatch = useDispatch();
//   const [phoneNumber, setPhoneNumber] = useState("");

//   const [otp, setOtp] = useState("");
//   const [confirm, setConfirm] = useState("");
//   const [verified, setVerified] = useState(false);
//   const navigate = useNavigate();
//   const { hasError } = useSelector(adminSelector);
//   const { user, isAuthenticate } = useSelector(authenticateSelector);



//   console.log(verified)

//   useEffect(() => {
//     if (verified) 
//     navigate("/")
//   }, [verified]);

//   console.log(user)
//   console.log(phoneNumber)

//   // useEffect(()=>{
//   //     if(user)navigate("/")
//   // },[user])


//   // useEffect(() => {
//   //   if (!isAuthenticate) {
//   //     navigate("/profile");
//   //   }
//   // }, [isAuthenticate, navigate]);



//   let recaptcha = null;
//   function recaptaVerifier(number) {
//     // Reset reCAPTCHA if it's already rendered
//     if (recaptcha !== null) {
//       recaptcha.clear();
//       recaptcha = null;
//     }
//     recaptcha = new RecaptchaVerifier(auth, "recaptcha-container", {
//       size: "invisible",
//       "expired-callback": () => {},
//     });

//     return new Promise((resolve, reject) => {
//       recaptcha
//         .render()
//         .then(() => {
//           signInWithPhoneNumber(auth, number, recaptcha)
//             .then((result) => {
//               resolve(result); // Resolve the promise with the result
//             })
//             .catch((error) => {
//               reject(error); // Reject the promise with the error
//               message.error({
//                 content: error.message + `. Refresh the page and continue`,
//                 duration: 5,
//               });
//             });
//         })
//         .catch((error) => {
//           reject(error); // Reject the promise if rendering fails
//           message.error({
//             content: error.message + `. Refresh the page and continue`,
//             duration: 5,
//           });
//         });
//     });
//   }

//   const onVerifyPhoneNumber = async (values) => {
//     setPhoneNumber("+91" + values.phone_number);

//     const data = {
//       phone_number: values.phone_number,
//     };

//     dispatch(findUserOTP(data));
//     setTimeout(async () => {
//       recaptaVerifier("+91" + values.phone_number)
//         .then((response) => {
//           // Handle successful response here
//           setConfirm(response);
//           setFlag(true);
//           console.log("Response:", response);
//         })
//         .catch((error) => {
//           // Handle error here
//           console.error("Error:", error);
//           setFlag(false);
//           message.error({
//             content: error.message + `. Refresh the page and continue`,
//             duration: 5,
//           });
//         });
//     }, 3000);
//   };
//   const onVerifyOTP = async (values) => {
//     const OTP = values.verifyOTP;
//     setOtp(values.verifyOTP);

//     try {
//       const result = await confirm.confirm(OTP);
//       console.log(result);
//       setFlag(false);
//       setOtp("");
//       if (result) {
//         setVerified(true);

//       }
//     } catch (err) {
//       console.log(err.message);

//       let errorMessage = "An error occurred. Please try again.";

//       switch (err.message) {
//         case "Firebase: Error (auth/invalid-verification-code).":
//           errorMessage = "Invalid verification code";
//           break;
//         case "Firebase: TOO_SHORT (auth/invalid-phone-number).":
//         case "Firebase: TOO_LONG (auth/invalid-phone-number).":
//         case "Invalid format. (auth/invalid-phone-number).":
//           errorMessage = "Invalid phone number";
//           break;
//         case "Firebase: Error (auth/too-many-requests).":
//           errorMessage =
//             "Verification blocked due to too many attempts. Try again after 24 hours";
//           break;
//         // Add more cases for specific error messages if needed

//         default:
//           break;
//       }

//       message.error({ content: errorMessage, duration: 3 });
//     }
//   };

//   return (
//     <div 
    
//     >
//       <div 
//       className="mx-10 border border-solid border-block-900 p-5 rounded-xl">
        
//         <div className="max-w-md p-4">
//         <h3 class="text-[25px] text-start font-semibold mb-6 ml-[54px] text-[#2B9BB8] leading-4 lg:text-[24px]">
//                          Login with OTP
//                         </h3>
//           <Form onFinish={onVerifyPhoneNumber}>
//             <Form.Item
//               label="Phone Number"
//               name="phone_number"
//               rules={[{ required: true }]}
//             >
//               <Input maxLength={10} placeholder="Enter your phone number" />
//             </Form.Item>
//             <Button
//               className="bg-[#2B9BB8] text-white hover:text-white mr-72 md:mr-0 md:w-32 md:ml-10"
//               htmlType="submit"
//             >
//               Generate OTP
//             </Button>

            
//           </Form>

//           <div style={{ marginBottom: "20px" }} id="recaptcha-container"></div>

//           {!hasError && (
//             <Form onFinish={onVerifyOTP}>
//               <Form.Item
//                 label="Enter your OTP"
//                 name="verifyOTP"
//                 rules={[{ required: true }]}
//               >
//                 <Input maxLength={6} placeholder="Enter OTP" />
//                 {verified && <div>Your OTP is verified</div>}
//               </Form.Item>
              
//               <Button
//                 className="bg-[#2B9BB8] text-white hover:text-white mr-72 md:mr-0 md:w-32 w- md:ml-10"
//                 htmlType="submit"
//               >
//                 Verify
//               </Button>
              
//             </Form>
//           )}
//         </div>
//         <Link
//                         onClick={switchToLogin}
//                         style={{
//                           color: "#2B9BB8",
//                           fontWeight: "500",
//                           height: "35px",
//                           width: "45%",
//                           marginTop: "12px"
                          
//                         }}
                       
//                       >
//                         Back to Login
//                       </Link>
//       </div>
      
//     </div>
//   );
// }

// export default LoginWithOTP;



import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminSelector } from "../../api/admin";
import {getAuth,RecaptchaVerifier,signInWithPhoneNumber,} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import app from "./storage";
import {checkAuth,authenticateSelector, findUserOTP, fetchPhonelogin} from '../../api/authSlice'
import Top1 from "../../Assets/logo.png"
import Top from "../../Assets/logo.png"

import "firebase/auth";
import { Button, Form, Input, message } from "antd";
const auth = getAuth();
function LoginWithOTP() {
  const [flag, setFlag] = useState(true);
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");

  const [otp, setOtp] = useState("");
  const [confirm, setConfirm] = useState("");
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const { hasError } = useSelector(adminSelector);
  const { user, isAuthenticate } = useSelector(authenticateSelector);



  console.log(verified)

  useEffect(() => {
    if (verified) 
    navigate("/")
  }, [verified]);

  console.log(user)
  console.log(phoneNumber)

  // useEffect(()=>{
  //     if(user)navigate("/")
  // },[user])


  // useEffect(() => {
  //   if (!isAuthenticate) {
  //     navigate("/profile");
  //   }
  // }, [isAuthenticate, navigate]);



  let recaptcha = null;
  function recaptaVerifier(number) {
    // Reset reCAPTCHA if it's already rendered
    if (recaptcha !== null) {
      recaptcha.clear();
      recaptcha = null;
    }
    recaptcha = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      "expired-callback": () => {},
    });

    return new Promise((resolve, reject) => {
      recaptcha
        .render()
        .then(() => {
          signInWithPhoneNumber(auth, number, recaptcha)
            .then((result) => {
              resolve(result); // Resolve the promise with the result
            })
            .catch((error) => {
              reject(error); // Reject the promise with the error
              message.error({
                content: error.message + `. Refresh the page and continue`,
                duration: 5,
              });
            });
        })
        .catch((error) => {
          reject(error); // Reject the promise if rendering fails
          message.error({
            content: error.message + `. Refresh the page and continue`,
            duration: 5,
          });
        });
    });
  }

  const onVerifyPhoneNumber = async (values) => {
    setPhoneNumber("+91" + values.phone_number);

    const data = {
      phone_number: values.phone_number,
    };

    dispatch(findUserOTP(data));
    setTimeout(async () => {
      recaptaVerifier("+91" + values.phone_number)
        .then((response) => {
          // Handle successful response here
          setConfirm(response);
          setFlag(true);
          console.log("Response:", response);
        })
        .catch((error) => {
          // Handle error here
          console.error("Error:", error);
          setFlag(false);
          message.error({
            content: error.message + `. Refresh the page and continue`,
            duration: 5,
          });
        });
    }, 3000);
  };
  const onVerifyOTP = async (values) => {
    const OTP = values.verifyOTP;
    setOtp(values.verifyOTP);

    try {
      const result = await confirm.confirm(OTP);
      console.log(result);
      setFlag(false);
      setOtp("");
      if (result) {
        setVerified(true);

      }
    } catch (err) {
      console.log(err.message);

      let errorMessage = "An error occurred. Please try again.";

      switch (err.message) {
        case "Firebase: Error (auth/invalid-verification-code).":
          errorMessage = "Invalid verification code";
          break;
        case "Firebase: TOO_SHORT (auth/invalid-phone-number).":
        case "Firebase: TOO_LONG (auth/invalid-phone-number).":
        case "Invalid format. (auth/invalid-phone-number).":
          errorMessage = "Invalid phone number";
          break;
        case "Firebase: Error (auth/too-many-requests).":
          errorMessage =
            "Verification blocked due to too many attempts. Try again after 24 hours";
          break;
        // Add more cases for specific error messages if needed

        default:
          break;
      }

      message.error({ content: errorMessage, duration: 3 });
    }
  };

  return (
   <div className="px-2 w-[380px] mx-auto lg:max-w-[100%] md:w-full">
     <div className="  lg:flex lg:mx-auto lg:justify-center lg:align-middle ">
      <div className=" lg:flex lg:justify-center lg:items-center md:w-full lg:w-full mt-10 md:mt-60">
      <div class="lg:flex justify-center md:flex p-4  lg:w-[1000px] md:mx-32 text-left align-bottom transition-all transform bg-slate-50 border border-[#FDF2F8] rounded-lg   ">
      <div className="flex justify-center">
                  <img
                    class="object-cover h-48 sm:ml-0 sm:my-3 bg-cover rounded-l-lg"
                    src={Top}
                    alt="/"
                  />
                </div>
       <div className="w-80 lg:w-96">
       <div className="lg:max-w-lg  p-8 flex flex-col">
          <Form onFinish={onVerifyPhoneNumber}>
            <Form.Item
              label="Phone Number"
              name="phone_number"
              rules={[{ required: true }]}
            >
              <Input maxLength={10} placeholder="Enter your phone number" />
            </Form.Item>
            <Button
              className="bg-sky-700 text-white hover:text-white mr-60 md:mr-0 md:w-32 md:ml-0"
              htmlType="submit"
            >
              Generate OTP
            </Button>
          </Form>

          <div style={{ marginBottom: "20px" }} id="recaptcha-container"></div>

          {!hasError && (
            <Form onFinish={onVerifyOTP}>
              <Form.Item
                label="Enter your OTP"
                name="verifyOTP"
                rules={[{ required: true }]}
              >
                <Input maxLength={6} placeholder="Enter OTP" />
                {verified && <div>Your OTP is verified</div>}
              </Form.Item>
              
              <Button
                className="bg-sky-700 w-28 text-white hover:text-white mr-60 md:mr-0 md:w-32 md:ml-0"
                htmlType="submit"
              >
                Verify
                
              </Button>
              
              
            </Form>
          )}
           
        </div>
       </div>
        {/* <div class="order-first ">
                  <img
                    class="object-cover h-48 sm:ml-3 sm:my-3 bg-cover rounded-l-lg"
                    src={Top}
                    alt="/"
                  />
                </div> */}
      </div>
     
    </div>
   
    </div>
   </div>
   
    
  );
}

export default LoginWithOTP;




