import  React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import {Link, useNavigate} from 'react-router-dom'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import {BiSearch} from 'react-icons/bi'
import {MdRemoveShoppingCart} from 'react-icons/md'
import { useDebounce } from "use-debounce";
import {useDispatch, useSelector} from 'react-redux'
import { Input  } from 'antd';
import axios from 'axios'
import {keyUri} from '../../key'
import ReactLoading from 'react-loading';
import {fetchUserRegister, authenticateSelector} from '../../api/authSlice'
// import { useAppSelector } from '../../api/hooks'
import moment from 'moment'
import Product from './productsearch'
import {fetchAllProducts, productSelector} from '../../api/products'


export default function Search() {

    const dispatch = useDispatch()
    const [debouncedText] = useDebounce(search, 2000);
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState([])
    const [category, setCategory] = useState([])
    const {products } = useSelector(productSelector)
    const [search, setSearch] = useState('')
    const [filter,setFilter]=useState([])
    
    // const { user } = useAppSelector(selectAuth)
 
    const nav=useNavigate()
    const { Search } = Input;

console.log(products)


useEffect(()=>{

    axios.get(keyUri.BACKEND_URI +`/products?search=${debouncedText}`).then(({data})=>{
    
      setFilter(data?.filterproduct)
       })
    setLoading(false)
    }, [dispatch, debouncedText])


    useEffect(()=>{
    dispatch(fetchAllProducts())      
    }, [dispatch])
    
    
    useEffect(()=>{     
        if(filter?.length < 1) {
          setSearch('')
        }
         }, [filter])
      
      
      const onSearch = (e) => {
        setLoading(true)
        setSearch(e.target.value)
      
      }
     


    //   let filterProduct = [] as 

    //   category.map((item, i)=>{
      
    //       user?.ads_info.filter((pr) =>{
    //           if(item?._id === pr?.ad_id?._id &&  moment().isBetween(pr?.ad_id?.basic_info?.sale_date[0] , pr?.ad_id?.basic_info?.sale_date[1])) {
    //               return filterProduct.push(pr) 
    //           }
    //           return 
    //       })
    //   })



    return (
        <FaSearchWrap >
            <div className="header flex flex-col px-2 pb-4  shadow-md mb-0 mt-1">          
            <div className=" flex justify-center mt-2 items-center ">
            <HiOutlineArrowNarrowLeft className=" self-start text-4xl m-0"  onClick={()=>  nav(-1)} /> 
            <h1 className="text-center px-16 mb-2  transform -translate-x-1 text-[#FDF2F8]" >What would you like to order today ?</h1>
            </div>
            <div className="input flex mt-2 px-2 rounded mx-6" style={{border:'1px solid var(#FDF2F8)' }}>
            <button><BiSearch /></button>
            <input type="search" onChange={onSearch} className=" outline-none px-1"  placeholder="Search" id="product" name="product"/>              
            </div>
            </div>

      <div className=" ">
            <ReactLoading color="#FDF2F8" type="bubbles" className="mx-auto mt-36" height={50} width={50} />                                   
            <div className="flex justify-center items-center flex-col mt-16">
            <MdRemoveShoppingCart className="text-gray-300 mb-4" style={{ fontSize:'6.7rem'}}/>
            <h1 className=" text-xs font-medium text-[#FDF2F8]">No Items Found</h1>
            </div>: 
            <>                     
           {product.length !== 0 &&   <Product  loading={loading}  data={filter?.length > 0 ? filter : products} />}
            </>                          
     </div>
    </FaSearchWrap>
           )
           }

const FaSearchWrap = styled.div`

.header{
    background-color: var(#FDF2F8);
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.3px;

}


.input {

    input{
    padding:0.3rem 0 0.2rem 0.9rem;
    margin:0 0;
    font-size: 0.95rem;
    border-radius: 0.2rem;
    width:100%;
}
background-color: #fff;
border:1px solid grey;
color:grey;
}

`