import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { fetchAllProducts, productSelector } from "../../api/products";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Components";
import { Button } from "antd";
import { BiRupee } from "react-icons/bi";
import LazyLoad from "react-lazyload";

export default function Allproducts({}) {
  const dispatch = useDispatch();
  const { allproducts } = useSelector(productSelector);

  console.log(allproducts);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch]);

  return (
    <Layout>
    

      <section class=" bg-slate-50 mb-16 md:mb-0 " style={{ fontFamily: "Lato" }}>
        <div class="container md:px-32   mx-auto">
          <div class="grid grid-cols-1  lg:grid-cols-3 sm:gap-x-16 gap-x-8 sm:gap-y-8 gap-y-2">
            {allproducts?.map((items) => (
              <div class="p-3 w-full ">
                {items?.stock_qty <= 0 && (
                  <p className=" bg-black  absolute flex justify-center items-end md:pb-20 pb-16 sm:h-[350px] pt-2 h-56 sm:w-[265px] w-full  bg-opacity-30 text-center md:text-2xl text-xl text-white">
                    {" "}
                    <span>Out of stock </span>
                  </p>
                )}
                <div class=" h-[480px]  md:h-[500px]   bg-white rounded-3xl">
                  <a class="block mx-auto max-w-max">
                    <img
                      class="h-72 w-96 md:h-80 md:w-96 object-cover"
                      src={items?.featured_image}
                      alt="product image"
                    />
                  </a>

                  <div className="w-full mt-4 ">
                    <a href="#">
                      <p class="mb-2 text-xl md:text-2xl leading-8 text-center text-sky-700 font-heading font-medium uppercase">
                        {items?.product_name}
                      </p>
                    </a>
                    <p class=" mb-1 text-center  font-heading font-medium tracking-tighter">
                      <span className="text-red-500 text-sm">
                     {items?.product_variants[0]?.discount} %   <span className="text-[8px]"> OFF</span>
                      </span>
                      <span class=" text-xl ml-2 text-sky-600 text-center ">
                        Rs {items?.product_variants[0]?.total_price}
                      </span>
                    </p>
                    <p className="text-center text-sm mb-2 line-through text-gray-700">
                      M.R.P{items?.product_variants[0]?.price}
                    </p>
                    <Link to={`/Product/${items?._id}`} className="mx-0">
                    <div className="flex justify-center items-center w-full">
                      <div class="max-w-max text-center ">
                        <a
                          class="block py-4 px-10 w-full text-lg leading-5 text-white font-medium tracking-tighter font-heading text-center bg-[#0284C7] hover:bg-sky-600 focus:ring-2 focus:ring-sky-500 focus:ring-opacity-50 rounded-xl"
                         
                        >
                          Buy Now
                        </a>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}
