import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade';
import Component from '../../Components'
import Install from '../pwa/install';
import Sectionfive from './Sectionfive'
import Sectionfour from './Sectionfour'
import SectionOne from './SectionOne'
import Sectionthree from './Sectionthree'
import Sectiontwo from './Sectiontwo'
import Section6 from './Section6';
import { useSelector } from 'react-redux';
import { authenticateSelector } from '../../api/authSlice';
import { useNavigate } from 'react-router-dom';
import Testimonialsp from '../../Components/newtestimonial/testimonialsp';



const Index = () => {
   // const {user,isAutheticate} = useSelector(authenticateSelector)
   // const navigate = useNavigate()

   // useEffect(()=>{
   //    if(isAutheticate) navigate('/')

   // },[isAutheticate,user])


  return (
     <Component>
        <SectionOne />

        <Sectiontwo />
        {/* <Fade bottom> */}
        <Sectionthree />     
        <Sectionfour />
        <Sectionfive /> 
        <Section6/>     
        {/* <Testimonialsp/> */}

        {/* </Fade> */}
        <Install/>
     </Component>
  )
}

export default Index