import React, { useState } from 'react';
import coupon, { VerifyCoupon, couponSelector } from '../../api/coupon';
import { useDispatch, useSelector } from 'react-redux';
import {  coupondiscount } from '../../api/cartSlice'

export default function AddCoupon( {user, total}) {


console.log(total)
console.log(user)


  const [couponCode, setCouponCode] = useState('');
  const { coupons } = useSelector(couponSelector);
  const dispatch = useDispatch();
  


  const handleInputChange = (e) => {
    setCouponCode(e.target.value);
  };



  const onFinish = (e) => {
    console.log(e);
    e.preventDefault()
    const couponData = {

        user_id:user._id,
        coupon_name:couponCode,
        total:total
  
    };

    console.log(coupons);

    dispatch(VerifyCoupon(couponData));
  };


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };




  return (

<div>
  <div style={{ display: 'flex' }}>
    <input
      className="block flex-grow px-4 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-[#855252] focus:outline-none"
      id="exampleFormControlInput2"
      placeholder="Enter Coupon Code"
      value={couponCode}
      onChange={handleInputChange}
      disabled={coupons?.msg === 'Coupon verified successfully'} 
    />
    <button
      className="bg-[#0B8CAD] hover:bg-[#0B8CAD] rounded-lg text-white hover:text-white w-24 "
      onClick={onFinish}
      disabled={coupons?.msg === 'Coupon verified successfully'} 
    >
      Apply
    </button>
  </div>
  <h1 className={`text-${coupons?.msg === 'Coupon verified successfully' ? 'green-500' : 'red-600' }`}>
  {coupons?.msg}
</h1>
</div>
  );
}
