import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

import {keyUri} from '../key'

export const initialState = {

    loading: false,
    categorys:[],
    currentCategory:null,
}

export const categorySlice = createSlice({
    name:"category",
    initialState,
    reducers:{

        getCategory: state =>{

            state.loading = true
        },
        getCategorySuccess: (state, {payload}) =>{

            state.loading = false
            state.categorys = payload
            
        },

        getCurrentCategory: (state, {payload}) =>{

            state.loading = false
            state.currentCategory = payload
            
        },
        getCategoryFailure: (state, {payload}) =>{

            state.loading = false
            state.categorys = payload
            
        },
    }
})


export const {getCategory, 
    getCurrentCategory,
    getCategorySuccess,
    getCategoryFailure, } = categorySlice.actions
      
export const categorySelector = state => state.categorys

export default categorySlice.reducer


const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

       
  

export const  fetchAllCategorys = () => async dispatch =>{
      const key = "category"
      dispatch(getCategory())
      
    try {

        const {data} = await axios.get(keyUri.BACKEND_URI +'/category');
        
        dispatch(getCategorySuccess(data))

    } catch ({response}) {

        dispatch(getCategoryFailure())
        // response.data && message.error({ content: response.data.msg, key, duration: 2 });

    }
    
}



export const  fetchOneCategory = (id) => async dispatch =>{
    const key = "category"
    dispatch(getCategory())
    
  try {
       
      const {data} = await axios.get(keyUri.BACKEND_URI +`/category/${id}`);
      dispatch(getCurrentCategory(data))

  } catch ({response}) {

      dispatch(getCategoryFailure())
    //   response.data && message.error({ content: response.data.msg, key, duration: 2 });

  }
  
}