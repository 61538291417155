import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import Imag3 from "../../Assets/images/p1.svg"
import { fetchOneCategory, categorySelector } from "../../api/catagories";


import { keyUri } from "../../key";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useDispatch } from "react-redux";

const Sectionthree = () => {
  const [layout, setLayout] = useState(null);
  const [isdataloaded, setisdataloaded] = useState(false);
  const [card, cardData] = useState(null);
  const [category, setCategory] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();


  const rout1 = "64d5dcf784e1a862c59d0fb5"
  
  useEffect(() => {
    const fetchData = async () => {
      const categorydata = await fetch(keyUri.BACKEND_URI + `/category`, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });
      const category = await categorydata.json();
      setCategory(category);
    };
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(fetchOneCategory(id));
  }, [dispatch, id]);
  useEffect(() => {
    const fetchData = async () => {
      const LayoutData = await fetch(keyUri.BACKEND_URI + `/layout`, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });
      const Data = await LayoutData.json();
      setLayout(Data);
      setisdataloaded(true);

    };
    fetchData();
  }, []);

  console.log(layout);
console.log(card)

  useEffect(() => {
    const fetchcardData = async () => {
      const carddata = await fetch(keyUri.BACKEND_URI + `/card`, {
        method: "GET",
        headers: {
          accept: "application/json",
        },
      });

      const cardImage = await carddata.json();
      cardData(cardImage);
    };

    fetchcardData();
  }, []);

  return (
    <div className="bg-white">
      {/* <div class="w-full text-md font-semibold text-gray-800 leading-relaxed  ">
          <div style={{ fontFamily: "Lato" }} class="max-w-xs mx-auto text-center">
            <img class="mx-auto h-20 mb-2 border rounded-full p-4 w-20 " src={Imag3} alt=""/>
       
            DERMAT RECOMMENDED
          </div>
        </div> */}
    <div className="container  px-2 py-2 bg-white  mx-auto lg:w-4/5 block ">
      <div className="flex w-full mb-2 md:mb-10 justify-center items-center flex-wrap md:mt-16">
        <h1
          className=" text-2xl md:text-4xl pt-25 font-medium text-center title-font text-black lg:w-1/2 lg:mb-0 mb-4 "
          style={{ fontFamily: "Lato" }}
        >
          Our Products
        </h1>
        {/* <p className="lg:pl-6 lg:w-1/2 mx-auto hidden sm:block leading-relaxed text-right px-14 text-base">View More</p> */}
      </div>
      {isdataloaded && (
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        loopFillGroupWithBlank={true}
        grabCursor={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          "@0.75": {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          "@2.00": {
            slidesPerView: 3,
            spaceBetween: 5,
          },
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >


        <section className="text-gray-600 body-font ">
          <div className=" bg-[#f5f3f4] shadow-md rounded-md">
            {layout?.map((Datanew) => (
              <SwiperSlide>
                <div className="md:p-2  w-full overflow-hidden rounded-md  ">
                  <Link
                    to={
                      Datanew?.layout_type === "Category"
                        ? `${`/catagories/${"64d5dcf784e1a862c59d0fb5"}`}`
                        : `${`/catagories/${"64d5dcf784e1a862c59d0fb5"}`}`
                    }
                  >
                    <img
                      alt="gallery"
                      className="  object-cover cursor-pointer w-full  rounded-md shadow-sm transition duration-300 hover:scale-[1.1] h-[40vh]  md:h-[30vh] lg:h-[40vh] object-center block"
                      src={Datanew?.layout_image}
                    />
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </div>

       

          
{/* **************************************************************category */}

{/* <div className="flex flex-wrap w-full md:mt-1">
        {card?.map((Cardimg) => (
          <div className="">
            <Link to={`/catagories/${Cardimg?.category}`}>
              <img
                alt="gallery"
                className="w-full object-cover cursor-pointer   transition duration-300 hover:scale-[1.1] rounded-md shadow-sm h-[16h] md:h-[26vh] lg:h-[36vh] object-center block"
                src={Cardimg?.card_image}
              />
            </Link>
          </div>
        ))}
      </div> */}


        </section>
      </Swiper>
        )}


    </div>
    
    
     <div className="flex py-3 lg:flex lg:flex-wrap lg:justify-center  md:mt-16 pb-2">
        {card?.map((Cardimg) => (
          <div className=" p-1 w-auto h-50% overflow-hidden rounded-md  border-solid border-2 border-[#F5F3F4] ">
            <Link 
            to={
              Cardimg?.layout_type === "Category"
                ? `${`/catagories/${"64d5dcd584e1a862c59d0fa9"}`}`
                : `${`/catagories/${"64d5dd3d84e1a862c59d0fc7"}`}`
            }>
              <img
                alt="gallery"
                className="w-full object-cover cursor-pointer    transition duration-300 hover:scale-[1.1] rounded-md shadow-sm h-[100px] md:h-[26vh] lg:h-[36vh] object-center block"
                src={Cardimg?.card_image}
              />
            </Link>
          </div>
        ))}
      </div>
    </div> 
  );
};

export default Sectionthree;
