import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Form, Input, Button } from "antd";
import { fetchUserOrder, orderSelector, fetchOneOrder } from "../../api/order";
import { authenticateSelector, checkAuth } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Components";

import { MdRemoveShoppingCart } from "react-icons/md";

export default function OrderDetails() {
  const [form] = Form.useForm();
  const { user } = useSelector(authenticateSelector);

  const { id } = useParams();

  const dispatch = useDispatch();
  const { currentOrder, loading } = useSelector(orderSelector);

  console.log(currentOrder);

  const nav = useNavigate();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserOrder(user?._id));
  }, [user]);

  useEffect(() => {
    dispatch(fetchOneOrder(id));
  }, [id]);

  const getStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case "Ordered":
        return "text-blue-700 "; 
      case "Order recived":
        return "text-pink-700"; 
      case "Order shipped":
        return "text-yellow-700"; 
      case "Order Delivered":
        return "text-green-700"; 
      default:
        return ""; 
    }
  };

  return (
    <Layout>
      <div class="relative max-w-screen-lg sm:px-4 sm:py-8 mx-auto   md:bg-[#ffffff] min-h-[76vh]">
        <div
          className="header flex justify-start p-2 mt-2 items-center"
          style={{ height: "45px" }}
        >
          <HiOutlineArrowNarrowLeft
            className=" text-3xl m-0 md:text-[24px] text-[16px] text-[#0B8CAD]"
            onClick={() => nav("/order")}
          />
          <h1
            className="mx-2  md:text-[24px] text-[16px] text-[#0B8CAD] mb-3 text-decoration-line: underline"
            style={{ fontFamily: "Lato" }}
          >
            Order details
          </h1>
        </div>

        <div className="z-10 border rounded-lg mb-4">
          <div className="md:flex md:justify-between  justify-start md:text-[12px] text-[8px] items-center text-black mx-3 my-2">
            <div className="md:grid md:grid-cols-2 flex justify-start  mx-3 items-start md:text-[14px] text-[10px]">
              <div>
                <h1
                  className=" text-black text-start "
                  style={{ fontFamily: "Lato" }}
                >
                  Order ID. :{" "}
                </h1>   
              </div>
              <div>
                <h1
                  className=" text-black text-start ml-2 "
                  style={{ fontFamily: "Lato" }}
                >
                  {" "}
                  {currentOrder?.orderId}
                </h1>
              </div>
            </div>

            <div
              className="md:grid md:grid-cols-2 flex justify-start ml-8 items-start mx-4 md:text-[12px] text-[10px]"
              style={{ fontFamily: "Lato" }}
            >
              <div>
                <p className="md:text-end text-start">Date : </p>
              </div>

              <div>
                <p className="text-start ml-2">
                  {" "}
                  {moment(currentOrder?.products?.date).format("MMMM Do YYYY")}
                </p>
              </div>
            </div>
          </div>

          <hr />

          <div className="md:flex md:justify-between  md:text-[12px] text-[10px] items-center text-black mx-3 my-2">
            <div>
              <div className="pb-2">
                <h1 className={`capitalize text-start   text-base font-semibold ${getStatusColor(currentOrder?.order_status)}`}>
                  <span className="text-md">Status: </span><b>{currentOrder?.order_status}</b>
                </h1>
                {currentOrder?.products?.map((item, i) => {
                  return (
                    <div className=" flex justify-start my-4 mx-3 ">
                      <div
                        className=" bg-cover "
                        style={{ backgroundImage: `url('${loading}')` }}
                      >
                        <img
                          className=" block h-full object-cover "
                          src={item?.featured_image}
                          alt="product"
                          style={{ width: "90px", height: "90px" }}
                        />
                      </div>
                      <div
                        className="  pl-4 text-left "
                        style={{ fontFamily: "Lato" }}
                      >
                        <p className="capitalize md:text-[14px] text-[12px] mb-1 ">
                          {item?.product_name}
                        </p>
                        <p className=" md:text-[12px]  text-[10px]  mb-1">
                        Variant : {item?.selectedVariant?.variant_qty}{" "}
                              {item?.selectedVariant?.stock_units}
                        </p>
                        <p className=" md:text-[12px]  text-[10px]  mb-1">
                          Quantity :{item?.selectedVariant?.qty}
                        </p>
                        <p className=" md:text-[12px] text-[10px]">
                          {" "}
                          Price : Rs {item?.selectedVariant?.total_price} 
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="ml-3 md:ml-3">
              <div
                className="  md:grid md:grid-cols-2 flex justify-start items-center md:text-[12px] text-[10px]"
                style={{ fontFamily: "Lato" }}
              >
                <div>
                  <h1
                    className="py-1 text-black text-end "
                    style={{ fontFamily: "Lato" }}
                  >
                    {" "}
                    Payment Method :{" "}
                  </h1>
                </div>
                <div className="  ">
                  <h2 className=" text-start mx-2 ">
                    {currentOrder?.payment_method}
                  </h2>
                </div>
              </div>

              <div
                className=" md:grid md:grid-cols-2 flex justify-start md:text-[12px] text-[10px]"
                style={{ fontFamily: "Lato" }}
              >
                <div>
                  <p className=" md:text-end text-start ">Delivery Address :</p>
                </div>
                <div className=" text-start mx-2 mb-2  ">
                  <h6 className=" capitalize text-black">
                    {currentOrder?.delivred_username}
                   <br/>Place:  {currentOrder?.delivred_addrees} &nbsp;{" "}
                  <br/>{currentOrder?.delivred_town}-
                    {currentOrder?.delivred_pincode}
                    <br/>Dt: {currentOrder?.delivred_district}&nbsp;&nbsp;&nbsp; State:{" "}
                  {currentOrder?.delivred_state}
                  </h6>
                  
                  <p>Phone no: {currentOrder?.delivred_phonenumber}</p>
                </div>
              </div>

              <div
                className="  md:grid md:grid-cols-2 flex justify-start md:text-[12px] items-center text-[10px]"
                style={{ fontFamily: "Lato" }}
              >
                <div>
                  <h1
                    className="text-[#0B8CAD] text-end md:text-[14px] text-[14px]"
                    style={{ fontFamily: "Lato" }}
                  >
                    {" "}
                    <b>Total Amount :</b>{" "}
                  </h1>
                </div>
                <div>
                  <h2 className="text-start mx-2 text-[14px]">
                    {" "}
                    Rs {currentOrder?.total}{" "}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Link to="/">
          <div
            className="text-center lg:text-end  rounded-lg"
            style={{ fontFamily: "Lato" }}
          >
            <button
              className="btn px-4 py-2  my-3 shadow-md rounded"
              style={{
                color: "white",
                backgroundColor: "#0B8CAD",
                fontWeight: "500",
              }}
              type="primary"
              htmlType="submit"
            >
              Continue Shopping
            </button>
          </div>
        </Link>
      </div>
    </Layout>
  );
}
