import React from 'react'
import Layout from '../../Components'
import Signinpage from './Signinpage'


const Index = () => {
  return (
    <Layout>
        <Signinpage />
    
    </Layout>
  )
}

export default Index