import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useNavigate  } from 'react-router-dom';
import { ShippingAmount } from '../../api/order';

const OrderAddressModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [pincode, setPincode] = useState('');
  const dispatch = useDispatch()
  const nav = useNavigate()


  console.log(pincode)

  const handlePincodeChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,6}$/.test(inputValue)) {
      setPincode(inputValue);
    }
  };



  const customStyles = {
    content: {
      minWidth: '100px', 
      minHeight: '100px',
      top: '50%', 
      left: '50%',
      transform: 'translate(-50%, -50%)', 
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  // const onFinish = (e) => {
  //   console.log(e);
  //   e.preventDefault()
  //   const shippingData = {

  //       pincode:pincode,
  //   };
  //   dispatch(ShippingAmount(shippingData));
  //   nav('/orderaddress')
  // };

  return (
    
      <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Order Address Modal"
      style={customStyles}
      className="fixed w-full inset-0 flex items-center justify-center z-50"
      >
      <div className="bg-white  p-6 md:p-6 rounded-lg">
      <h2 className="text-md md:text-2xl font-bold mb-4 text-sky-700">Enter Order Address Pincode</h2>
      <input
        type="text"
        value={pincode}
        onChange={handlePincodeChange}
        placeholder="Enter Pincode"
        className="w-full px-4 py-2 border rounded mb-4"
        maxLength="6"
      />

      <button
        // onClick={onFinish}
        className={`bg-[#0B8CAD] text-white px-4 py-2 rounded hover:bg-sky-500 float-right ${pincode.length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
        disabled={pincode.length !== 6}
      >
        Submit
      </button>
    </div>
    </Modal>
  );
};

export default OrderAddressModal;
