import React from 'react'
import Header from './header'
import Navbar from './navbar'
import Footer from './footer'


const Index = ( {children} ) => {
  return (
    <>

    <Navbar />

    <Header />
    { children }
    
    <Footer />
    </>
  )
}

export default Index