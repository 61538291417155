import React from 'react'
import Img1 from '../../Assets/images/p1.svg'
import Img2 from '../../Assets/images/p2.svg'
import Img3 from '../../Assets/images/p1.svg'
import Img4 from '../../Assets/images/p3.svg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";



function Sectionfour() {
  
  return (
    <div className=' py-6 bg-[#f5f3f4] h-[260px]' >
     
     
      <section class="py-6 lg:py-5 overflow-hidden">
    <div class="container px-16 md:px-40 mx-auto">
    <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        loopFillGroupWithBlank={true}
        grabCursor={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          
        }} 
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          "@0.75": {
            slidesPerView: 4,
            spaceBetween: 1,
          },
          "@1.00": {
            slidesPerView: 4,
            spaceBetween: 1,
          },
          "@2.00": {
            slidesPerView: 4,
            spaceBetween: 1,
          },
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
      <div style={{ fontFamily: "Lato" }} class="flex flex-wrap gap-6 justify-center -m-8">

          <SwiperSlide>
        <div class="w-full p-8 text-md font-semibold text-gray-800 leading-relaxed">
          <div style={{ fontFamily: "Lato" }} class="max-w-xs mx-auto text-center">
            <img class="mx-auto mb-4 border rounded-full border-gray-500 p-4 w-20 " src={Img1} alt=""/>
       
            FSSAI APPROVED
          </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="w-full  p-8 text-md font-semibold text-gray-800 leading-relaxed  ">
          <div style={{ fontFamily: "Lato" }} class="max-w-xs mx-auto text-center">
            <img class="mx-auto mb-4 border rounded-full border-gray-500 p-4 w-20 " src={Img3} alt=""/>
       
            DERMAT RECOMMENDED
          </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="w-full  p-8 text-md font-semibold text-gray-800 leading-relaxed  ">
          <div style={{ fontFamily: "Lato" }} class="max-w-xs mx-auto text-center">
            <img class="mx-auto mb-4 border rounded-full border-gray-500 p-4 w-20" src={Img2} alt=""/>
       
            RESULT ORIENTED
          </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="w-full  p-8 text-md font-semibold text-gray-800 leading-relaxed  ">
          <div style={{ fontFamily: "Lato" }} class="max-w-xs mx-auto text-center">
            <img class="mx-auto mb-4 border rounded-full border-gray-500 p-4 w-20" src={Img4} alt=""/>
       
            SAFE TO USE
          </div>
        </div>
        </SwiperSlide>
      </div>
      </Swiper>

    </div>
  </section></div>
  )
}
export default Sectionfour