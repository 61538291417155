import React, { useEffect, useLayoutEffect } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./index.css";
import { fetchOneProductUpsell, productSelector } from "../../api/products";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { addCartProducts } from "../../api/cartSlice";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { BiRupee } from "react-icons/bi";
import { Button } from "antd";
import { AiOutlineShoppingCart } from "react-icons/ai";

const Upsell = ({ date, time, cartProducts }) => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const { upsellProduct } = useSelector(productSelector);

  useEffect(() => {
    dispatch(fetchOneProductUpsell(id));
  }, [id]);

  const onAdd = (data) => {
    let newdata = { ...data, date, time };

    dispatch(addCartProducts(newdata));
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scroolUp = () => {
    window.scrollTo(0, 0);
  };

  const breakpoints = {
    // When screen width is less than 576px
    "@0.00": {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    // When screen width is between 576px and 768px
    "@0.75": {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // When screen width is between 768px and 992px
    "@1.00": {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    // When screen width is 992px or larger
    "@1.50": {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  };
  return (
    <div>
      <section className="bg-slate-50 mb-10 md:mb-0 mt-4 md:mt-10">
        <div class="md:max-w-screen-xl  md:mt-0 px-4 py-4 mx-auto md:mb-20 ">
          <div>
         

            <h2
              class=" text-2xl text-[#2B9BB8] font-extrabold tracking-wide  lg:text-3xl"
              style={{ fontFamily: "Lato" }}
            >
              You may also like
            </h2>
          </div>

          <br />

          <div className="">
            <Swiper
              breakpoints={breakpoints}
              spaceBetween={6}
              slidesPerGroup={3}
              loop={true}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
             
              showsPagination={false}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {upsellProduct?.map((item) => {
                const exist = cartProducts.findIndex(
                  (isthere) => isthere._id === item._id
                );

                return (
                  <SwiperSlide>
                    {/* <div className="flex justify-center mb-20 md:mb-0">
                      <div className="mx-0 md:h-[360px] h-60 w-96 md:w-[258px] object-cover  rounded-lg shadow-lg relative  bg-[#ffff] ">
                        {item?.stock_qty <= 0 && (
                          <p className="  bg-black  absolute flex justify-center items-end sm:pb-24 pb-12 md:h-[350px] pt-2 h-52 sm:w-[258px]  w-full  bg-opacity-30 text-center sm:text-2xl text-[12px] text-white">
                            {" "}
                            Out of Stock
                          </p>
                        )}
                        <div className="">
                          <div className=" md:w-64  w-52 h-40 sm:w-20 md:h-full  sm:h-40 mx-auto   overflow-hidden ">
                            <img
                              className=" mx-auto   transition duration-300 group-hover:scale-[1.4]"
                              src={item?.featured_image}
                              alt="woman"
                              loading="lazy"
                            />
                          </div>
                          <div className="flex items-center justify-center ">
                            <div>
                              <div className="space-y-4 text-start sm:ml-3  ml-1 mt-2">
                                <h4
                                  className=" font-semibold md:text-[16px] text-sm  text-[#0B8CAD]"
                                  style={{ fontFamily: "Lato" }}
                                >
                                  {item?.product_name}
                                </h4>
                              </div>

                              <div
                                className="  flex justify-center text-center font-heading text-[#0B8CAD]"
                                style={{ fontFamily: "Lato" }}
                              >
                                From :{" "}
                                <span className="sm:text-[14px] text-[11px] mt-2">
                                  <BiRupee />
                                </span>{" "}
                                <span className="sm:text-[14px] text-[11px] mt-1">
                                  {item?.product_variants[0]?.price}
                                </span>
                              </div>
                            </div>
          
                          </div>
                        </div>

                        <div>
                          <Link to={`/Product/${item?._id}`}>
                            <Button
                              className="w-full h-10 mt-2 flex justify-center  bg-[#0B8CAD] gap-4"
                              onClick={scroolUp}
                            >
                              <span>
                                <AiOutlineShoppingCart
                                  className="text-xl text-white mt-1 text-[16px] font-semibold"
                                  style={{ fontSize: "22px" }}
                                />
                              </span>
                              <span className="text-white mt-1 text-[16px] font-semibold">
                                Buy Now
                              </span>
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                    
      <div class="  w-full" style={{ fontFamily: "Lato" }}>
        <div class="">
          <div class="grid grid-cols-1  lg:grid-cols-1 sm:gap-x-16 gap-x-8 sm:gap-y-8 ">
          
              <div class=" w-full ">
                {item?.stock_qty <= 0 && (
                  <p className=" bg-black  absolute flex justify-center items-end md:pb-20 pb-16 sm:h-[350px] pt-2 h-56 sm:w-[265px] w-full  bg-opacity-30 text-center md:text-2xl text-xl text-white">
                    {" "}
                    <span>Out of stock </span>
                  </p>
                )}
                <div class=" h-[480px]  md:h-[550px]   bg-white rounded-3xl">
                  <a class="block mx-auto mb-8 xl:mb-20 max-w-max" href="#">
                    <img
                      class="h-72 w-96 md:h-72 md:w-96 object-cover"
                      src={item?.featured_image}
                      alt="product image"
                    />
                  </a>

                  <div className="w-full ">
                    <a href="#">
                      <p class="mb-2 text-xl md:text-2xl leading-8 text-center text-sky-700 font-heading font-medium uppercase">
                        {item?.product_name}
                      </p>
                    </a>
                    <p class=" mb-1 text-center  font-heading font-medium tracking-tighter">
                      <span className="text-red-500 text-sm">
                        % {item?.product_variants[0]?.discount}
                      </span>
                      <span class=" text-xl ml-2 text-sky-600 text-center ">
                        Rs {item?.product_variants[0]?.total_price}
                      </span>
                    </p>
                    <p className="text-center text-sm mb-2 line-through text-gray-700">
                      M.R.P{item?.product_variants[0]?.price}
                    </p>
                    <Link to={`/Product/${item?._id}`} className="mx-0"
                     onClick={scroolUp}>
                    <div className="flex justify-center items-center w-full">
                      <div class="max-w-max text-center ">
                        <a
                          class="block py-4 px-10 w-full text-lg leading-5 text-white font-medium tracking-tighter font-heading text-center bg-[#2B9BB8] hover:bg-sky-600 focus:ring-2 focus:ring-sky-500 focus:ring-opacity-50 rounded-xl"
                          href="#"
                        >
                          Buy Now
                        </a>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
          
          </div>
        </div>
      </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Upsell;
