import React, { useEffect, useState } from "react";
import {
  qtyupdate,
  cartProductSelector,
  deleteCartItem,
  dateupdate,
} from "../../api/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetchOneProduct } from "../../api/products";
import { Image } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import moment from "moment";
import {
  fetchlogin,
  authenticateSelector,
  checkAuth,
} from "../../api/authSlice";
import { MdRemoveShoppingCart } from "react-icons/md";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-calendar/dist/Calendar.css";
import Pincode from "./pincode";

const Mainpage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isAuthenticate } = useSelector(authenticateSelector);
  const nav = useNavigate();
  const { cartProducts, total } = useSelector(cartProductSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log(cartProducts);

  useEffect(() => {
    dispatch(fetchOneProduct(id));
  }, [id]);

  // useEffect(() => {
  //   dispatch(checkAuth());
  // }, [dispatch]);

  const increment = (id, item) => {
    console.log(id);
    console.log(item);
    console.log(cartProducts);
    const cartExist = cartProducts.findIndex((isthere) => id === isthere._id);
    console.log(cartExist);

    if (
      cartProducts[cartExist]?.selectedVariant.qty <
      item?.selectedVariant?.stock_qty
    ) {
      return dispatch(
        qtyupdate({ id, qty: cartProducts[cartExist]?.selectedVariant.qty + 1 })
      );
    } else {
      return toast.error("Out Of Stock", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const decrement = (id, item) => {
    const cartExist = cartProducts.findIndex((isthere) => id === isthere._id);
    if (cartProducts[cartExist].selectedVariant.qty > 1) {
      return dispatch(
        qtyupdate({
          id: id,
          qty: cartProducts[cartExist].selectedVariant.qty - 1,
        })
      );
    }
  };

  const handlePlaceOrderClick = () => {
    if (isAuthenticate) {
      // Show the modal when authenticated
      setIsModalOpen(true);
    } else {
      // Redirect to login if not authenticated
      // You can implement your login logic here
    }
  };

  const handleModalSubmit = (pincode) => {
    // Handle the pincode submission here
    // You can send it to your server or update your state as needed
    console.log("Pincode submitted:", pincode);

    // Close the modal
    setIsModalOpen(false);
  };

  const onDelete = (data) => {
    console.log(data);

    dispatch(deleteCartItem(data?._id));
  };

  return (
    <div>
      <section>
        {cartProducts.length === 0 ? (
          <div className="relative max-w-screen-xl min-h-[78vh] px-4 py-8  mx-auto">
            <div
              className="flex justify-center items-center flex-col mt-32"
              style={{ fontFamily: "Lato" }}
            >
              <MdRemoveShoppingCart
                className="text-zinc-300 opacity-30 mb-4 "
                style={{ fontSize: "9.7rem" }}
              />
              <h1
                className=" text-md font-medium text-[#2B9BB8]"
                style={{ fontFamily: "Lato" }}
              >
                Your cart is empty
              </h1>
            </div>
            <div className="flex justify-center w-full">
              <div className=" rounded items-center bg-[#2B9BB8] my-4 w-[230px] py-2 ">
                <Link to="/">
                  {" "}
                  <h1 className="text-white" style={{ fontFamily: "Lato" }}>
                    SHOP NOW!
                  </h1>{" "}
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className=" relative max-w-screen-xl min-h-[78vh] w-full md:px-4  md:my-6 mx-auto  shadow ">
            <section class=" ">
              <div class="container mx-auto px-4">
                <div class=" md:p-8 bg-white">
                  <div className=" flex justify-start gap-4 ">
                    <HiOutlineArrowNarrowLeft
                      className=" text-3xl mt-1  md:hidden text-[#2B9BB8]"
                      onClick={() => nav(-1)}
                    />
                    <h2
                      class=" md:mb-2 pb-3  md:text-[24px] text-2xl font-heading border-gray-200 md:pb-3 md:w-[65%] w-[80%] border-b text-left text-[#2B9BB8]"
                      style={{ fontFamily: "Lato" }}
                    >
                      My cart
                    </h2>
                  </div>

                  <div class="flex flex-wrap -mx-4">
                    <div class="w-full xl:w-8/12 mb-8 xl:mb-0 px-4">
                      <div class="hidden lg:flex w-full">
                        <div className=" z-30 w-full">
                          {/* <div className="grid grid-cols-5 gap-4 sm:gap-10  text-[12px] sm:text-[16px] text-[#78102C]" style={{fontFamily:'Lato', }} >
            <div className="col-span-2"><h6 className=" py-2 sm:pl-5 pl-3" style={{textAlign:'start', }} >Products</h6></div>
            <div className="col-span-1"><h6 className=" py-2 mb-0">Price</h6></div>
            <div className="col-span-1"><h6 className=" py-2 mb-0">Quantity</h6></div>
            <div className="col-span-1"><h6 className=" text-light py-2 mb-0">Remove</h6></div>              
            </div> */}
                        </div>
                      </div>
                      <div class="md:mb-12  py-3 ">
                        {cartProducts.map((item, i) => {
                          return (
                            <div key={i} className="shadow-sm my-2 py-1">
                              {/* {
                item.products.map(item=>{
                return */}
                              <div
                                className="grid grid-cols-3 md:grid-cols-2 sm:gap-6 gap-1 text-center text-light align-items-center  "
                                style={{ fontFamily: "Lato" }}
                              >
                                <div className=" flex  pl-1 justify-start w-[95%]">
                                  <Image
                                    preview={false}
                                    className=" md:h-18 md:w-10 h-10 w-10 object-cover"
                                    src={item?.featured_image}
                                    placeholder="image"
                                  />

                                  <div className=" hidden md:block  mt-6">
                                    <small
                                      className=" my-2 md:ml-4 md:my-auto font-semibold  text-[11px] sm:text-[16px] flex justify-start text-[#2B9BB8] "
                                      style={{ fontFamily: "Lato" }}
                                    >
                                      {item?.product_name} <br />{" "}
                                    </small>
                                    <h6
                                      className="text-start md:pl-4 pt-3 sm:text-[16px]"
                                      style={{ fontFamily: "Lato" }}
                                    >
                                      {" "}
                                      Rs {item?.selectedVariant?.total_price}
                                    </h6>
                                    <div className="flex justify-start ml-3 mt-4 md:ml-4 gap-4">
                                      <div
                                        className="   flex justify-start items-start  rounded border border-[#2B9BB8] "
                                        style={{ fontFamily: "Lato" }}
                                      >
                                        <button
                                          className="text-center shadow-md  bg-white rounded sm:w-8 w-11 h-5 sm:h-8 text-[#2B9BB8]"
                                          disabled={
                                            item?.selectedVariant?.qty === 1
                                              ? true
                                              : false
                                          }
                                          type="primary"
                                          onClick={() => decrement(item?._id)}
                                        >
                                          -
                                        </button>
                                        <button className=" text-center shadow-md  rounded sm:w-8 w-12 h-5 sm:h-8 text-[#2B9BB8]">
                                          {item?.selectedVariant?.qty}
                                        </button>
                                        <button
                                          className=" text-center shadow-md bg-white rounded sm:w-8 w-11 h-5 sm:h-8 text-[#2B9BB8]"
                                          onClick={() =>
                                            increment(item?._id, item)
                                          }
                                        >
                                          +
                                        </button>
                                      </div>

                                      <div
                                        className=" flex justify-center items-center  shadow-md  border border-[#0B8CAD] text-[#0B8CAD]  bg-white md:w-8 md::h-8 md:text-[24px] "
                                        style={{ fontFamily: "Lato" }}
                                      >
                                        <AiOutlineDelete
                                          onClick={() => onDelete(item)}
                                          className="close cursor-pointer"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* //Mobile responsive */}

                                <div
                                  className=" md:hidden  text-[#2B9BB8]"
                                  style={{ fontFamily: "Lato" }}
                                >
                                  <small
                                    className=" mb-2 md:ml-4 md:my-auto  font-semibold text-[16px] sm:text-[16px] flex justify-start  "
                                    style={{ fontFamily: "Lato" }}
                                  >
                                    {item.product_name} <br />{" "}
                                  </small>

                                  <div
                                    className="  flex justify-center    text-start  border rounded mr-5 border-[#2B9BB8]  py-[0.5px]"
                                    style={{ fontFamily: "Lato" }}
                                  >
                                    <button
                                      className="text-center bg-white rounded sm:w-8 w-6 h-5 sm:h-8 cursor-pointer text-black"
                                      disabled={item.qty === 1 ? true : false}
                                      type="primary"
                                      onClick={() => decrement(item?._id)}
                                    >
                                      -
                                    </button>
                                    <button className=" text-center shadow-sm pb-7  rounded sm:w-8 w-8 h-5 sm:h-8 cursor-pointer text-[#2B9BB8]">
                                      {item?.selectedVariant?.qty}
                                    </button>
                                    <button
                                      className=" text-center shadow-sm bg-white rounded sm:w-8 w-6 h-5 cursor-pointer sm:h-8 text-black"
                                      onClick={() => increment(item?._id, item)}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>

                                <div class="w-auto md:w-1/6 lg:w-2/12   md:hidden block  ">
                                  <h6 className="text-center text-[14px] text-[#2B9BB8]">
                                    {" "}
                                    Rs {item?.selectedVariant?.total_price}
                                  </h6>
                                  <div
                                    className=" md:hidden block  mt-3  sm:pt-4 text-xl ml-12 cursor-pointer   "
                                    style={{ fontFamily: "Lato" }}
                                  >
                                    <AiOutlineDelete
                                      onClick={() => onDelete(item)}
                                      className="close text-[#2B9BB8] cursor-pointer"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      class="w-full xl:w-4/12 px-4 mb-24 md:ml-0 ml-7"
                      style={{ fontFamily: "Lato" }}
                    >
                      <h2 class=" flex items-start mt-6 mb-8 md:text-[24px]  text-2xl font-bold  text-[#2B9BB8]">
                        Order Summary
                      </h2>
                      <div class="p-3 md:p-4 rounded-md lg:h-60 shadow-lg border border-[#2B9BB8] w-72">
                        <div class="flex mb-4 justify-between gap-16 pb-2 border-b  ">
                          <span class="text-[#0B8CAD] md:text-[16px] mt-5 font-bold">
                            Sub total
                          </span>
                          <span class="md:text-[16px] font-bold mt-5 font-heading text-[#2B9BB8]">
                            {" "}
                            Rs {total}/-
                          </span>
                        </div>
                        {/* <div class="flex  justify-between">
              <span class="text-blue-900 md:text-[16px]  ">Shipping Charges </span>

              <span class="text-xl font-bold font-heading text-red-900">-</span>
            </div> */}
                        {/* 
            <div class="flex mb-5 justify-between">
              <span class="text-blue-900  md:text-[16px]">Discount Coupon</span>

              <span class="text-xl font-bold font-heading text-red-900">-</span>
            </div> */}
                        <div class="flex mb-8 justify-between items-center">
                          <span class="text-[20px] font-bold mt-5 font-heading text-[#2B9BB8]">
                            Total
                          </span>
                          <span class="text-[20px] font-bold mt-5 font-heading text-[#2B9BB8]">
                            {" "}
                            Rs {total}/-
                          </span>
                        </div>
                        {isAuthenticate ? (
                          <div class="">
                            <Link to='/orderaddress'>
                            <div
                              className="text-right lg:text-right mt-5 rounded "
                              style={{ fontFamily: "Lato" }}
                            >
                              <button
                                className="btn px-2 py-2  rounded shadow-md text-[20px]"
                                onClick={handlePlaceOrderClick}
                                style={{
                                  color: "white",
                                  backgroundColor: ["#2B9BB8"],
                                  fontWeight: "500",
                                }}
                              >
                                Place Order
                              </button>
                            </div>
                            </Link>
                          </div>
                        ) : (
                          <Link to="/profile?from=cart">
                            <div
                              className="text-right lg:text-right mt-5 rounded mr-3"
                              style={{ fontFamily: "Lato" }}
                            >
                              <button
                                className="btn px-6 py-2  rounded shadow-md"
                                style={{
                                  color: "white",
                                  backgroundColor: "#0B8CAD",
                                  fontWeight: "500",
                                }}
                                type="primary"
                                htmlType="submit"
                              >
                                Log in
                              </button>
                            </div>
                          </Link>
                        )}

                        <Pincode
                          isOpen={isModalOpen}
                          onRequestClose={() => setIsModalOpen(false)}
                          onSubmit={handleModalSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </section>
    </div>
  );
};
export default Mainpage;
