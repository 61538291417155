import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/logo.png";
import Logo1 from "../Assets/dwiti.png";
import { keyUri } from "../key";
import { AiOutlineMail } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { AiOutlineGift } from "react-icons/ai";
import styled from "styled-components";
import { cartProductSelector } from "../api/cartSlice";
import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
  const [navlinks, setNavlinks] = useState(null);
  const { cartProducts } = useSelector(cartProductSelector);

  useEffect(() => {
    const Navlinks = async () => {
      const Links = await fetch(keyUri.BACKEND_URI + "/category");
      const NewLinks = await Links?.json();
      setNavlinks(NewLinks);
    };

    Navlinks();
  }, [navlinks]);

  return (
    <footer className="bg-slate-100  " style={{ fontFamily: "Lato" }}>
      <div className="md:grid grid-cols-2 gap-8 py-4 px-6 md:grid-cols-4 hidden sm:block ">
        <div className=" md:block hidden">
          <Link className="flex flex-wrap justify-center" to="/">
          <img src={Logo1} alt="logo" className=" w-32 py-1" />
            <img src={Logo} alt="logo" className=" w-32 py-2" />
            {/* <img src={Logo1} alt="logo" className=" w-32 py-1" /> */}
            {/* <p className="text-start capitalize">dwitipharma pvt ltd</p> */}

          </Link>
        </div>
     

        <div>
          <h2 className="mb-3 sm:text-[16px] text-14px font-semibold text-gray-800  text-start">
            Know more
          </h2>
          <ul className="text-gray-800 sm:text-[14px]  text-[12px] text-start">
            <Link to="/privecy">
              <p className="hover:underline ">Privacy Policy</p>
            </Link>
            <Link to="/condition" className="mb-2  ">
              <p className="hover:underline  ">Terms & Conditions</p>
            </Link>
            <Link to="/refund" className="mb-2">
              <p className="hover:underline">Refund Policy</p>
            </Link>
            <Link to="/contactus" className="mb-2">
              <p className="hover:underline">Contact Us</p>
            </Link>
            <Link to="/aboutus" className="mb-2">
              <p className="hover:underline">About Us</p>
            </Link>
          </ul>
        </div>
        <div>
          <h2 className="mb-3 text-[14px] sm:text-[16px]  font-semibold text-gray-800  text-start">
            Catagories
          </h2>
          <ul className="text-gray-800 sm:text-[14px] text-[12px] md:mb-2 text-14px text-start ">
            {navlinks?.map((items, i) => {
              return (
                i < 4 && (
                  <li key={i}>
                    <Link
                      to={`/catagories/${items?._id}`}
                      aria-label="Bouquet"
                      title="Bouquet"
                      class="font-medium text-14px tracking-wide hover:underline  text-14px text-gray-800  "
                    >
                      {items?.category_name}
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
        </div>

        <div>
          <h2 className="mb-4  font-semibold text-[14px] sm:text-[16px]  text-gray-800 text-start">
            Get in touch
          </h2>
          <ul className="text-gray-800 text-start  sm:text-[14px] text-[12px]">
            <p
              href=""
              className=" items-center mb-4 text-gray-800  rounded-lg hover:text-gray-700 "
            >
              <div className="flex items-center  mb-4">
                <h1 className="sm:text-[16px] text-2xl text-gray-800">
                  <IoCallOutline />
                </h1>
                <span className="sm:text-[14px] text-[12px] text-gray-800 pl-4">
                  {" "}
                  +91 9980178584
                </span>
              </div>
            </p>
            <div className="mb-4 flex items-center ">
              <h1 className="sm:text-[16px] text-2xl text-gray-800">
                <AiOutlineMail />
              </h1>
              <p href="#" className="hover:underline pl-4 sm:text-[14px]">
                {" "}
                emmuniti@gmail.com
              </p>
            </div>
            <li className="mb-4">
              <div className="flex mt-4 space-x-6 sm:justify-start  sm:mt-0  sm:ml-9 ml-10">
                <a
                  href="https://www.facebook.com/profile.php?id=61560802573986"
                  target="blank"
                 
                  className="text-[#1A56DB] text-[20px] hover:text-gray-800"
                >
                  <BsFacebook />
                </a>
                <a
                  href="https://www.instagram.com/emmuniti/?hl=en"
                  target="blank"
                 
                  className="text-[#EF0996] text-[24px] hover:text-gray-800"
                >
                  <AiFillInstagram />
                </a>
                {/* <a href="https://elegantgroup.com/" className="text-[#855252] text-3xl hover:text-gray-800">
               <BsTwitter/>           
                </a> */}
              </div>
            </li>
          </ul>
        </div>
        
      </div>

      <FooterWrap>
        <div
          className="flex justify-center items-center mx-auto gap-16 pt-2  footer  bg-sky-800 md:hidden sm:block "
          style={{ fontFamily: "Lato" }}
        >
          <Link to="/">
            <span className="text-2xl flex items-center justify-center text-white">
              <AiOutlineHome />{" "}
            </span>
          </Link>

          <Link to="/allproduct">
            <span className="text-2xl flex items-center justify-center text-white ">
              <AiOutlineGift />{" "}
            </span>
          </Link>

          <Link to="/cart">
            <span className="text-2xl flex items-center justify-center text-white ">
              <AiOutlineShoppingCart />{" "}
            </span>
            {cartProducts.length > 0 && (
              <div className="badge ">
                <p>{cartProducts.length}</p>
              </div>
            )}
          </Link>

          <Link to="/contactus">
            <span className="text-2xl flex items-center justify-center text-white ">
              <CgProfile />{" "}
            </span>
          </Link>
        </div>
      </FooterWrap>
    </footer>
  );
};

export default Footer;

const FooterWrap = styled.div`
  .footer {
    width: 100%;
    height: 3.0rem;
    position: fixed;
    bottom: 0%;
    left: 0%;
    margin-top: 0px;
    z-index: 40;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .navbtn {
    li {
      list-style: none;
      height: 2px;
      background-color: grey;
      margin: 4px 0;
    }
  }

  .badge,
  .notification {
    position: absolute;
    z-index: auto;
    min-width: 15px;
    height: 15px;
    padding: 0 3px;
    color: #f9f9f9;
    font-size: 10px;
    line-height: 16px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    font-weight: bold;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;
  }

  .badge {
    transform: translate(14px, -28px);
  }

  .notification {
    transform: translate(11px, -26px);
  }
`;
