import React, { useEffect } from "react";
import Layout from "../../Components";
import School from "../../Assets/logo.png";
import Red1 from "../../Assets/bg3.png";
import Red2 from "../../Assets/bg3.png"
import P1 from "../../Assets/images/Topselling/product pics-05.webp";
import P2 from "../../Assets/images/Topselling/product-05.webp";
import P3 from "../../Assets/images/Topselling/product-14.webp";
import { Parallax } from "react-parallax";

export default function Aboutus() {
  return (
    <Layout>
      <div style={{ fontFamily: "Lato" }} class="">
        <div class="relative flex items-center justify-center">
          <img class="w-full h-96 object-cover " src={Red2} alt="" />
          <p class="absolute text-2xl  md:text-5xl text-center text-white font-Montserrat font-extrabold top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            About Us
          </p>
        </div>


        <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xl font-semibold tracking-wider text-black uppercase rounded-full bg-teal-accent-400">
                What Sets Us Apart
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-sm md:text-xl font-semibold leading-none tracking-tight text-gray-700 sm:text-lg md:mx-auto">
              <span className="relative inline-block">
                {/* <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-sky-300 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="84d09fa9-a544-44bd-88b2-08fdf4cddd38"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#84d09fa9-a544-44bd-88b2-08fdf4cddd38)"
                    width="52"
                    height="24"
                  />
                </svg> */}
                <span className="relative  text-gray-700">INTENSE</span>
              </span>{" "}
              RESEARCH AND DOCTORS RECOMENDED FORMULATIONS WITH PROVEN RESULTS
            </h2>
          </div>
          <div className="grid gap-8 row-gap-5 md:row-gap-8 lg:grid-cols-3">
            <div className="p-5 duration-300 transform bg-white border-2 border-solid border-sky-400 rounded shadow-sm border-deep-purple-accent-100 hover:-translate-y-2">
              <div className="flex items-center justify-center mb-2">
                {/* <p className="flex items-center justify-center w-10 h-10  text-lg font-bold text-black rounded-full bg-deep-purple-accent-400">
              1.
            </p> */}
                <p className="text-lg font-bold leading-5 text-[#0B8CAD] text-center">
                  Quality
                </p>
              </div>
              <p className="text-sm text-gray-700">
                We take pride in delivering top-notch nutraceuticals products
                that exceed expectations.
              </p>
            </div>

            <div className="p-5 duration-300 transform bg-white border-2 border-solid border-sky-400 rounded shadow-sm border-deep-purple-accent-100 hover:-translate-y-2">
              <div className="flex items-center justify-center mb-2">
                {/* <p className="flex items-center justify-center w-10 h-10  text-lg font-bold text-black rounded-full bg-deep-purple-accent-400">
              2.
            </p> */}
                <p className="text-lg font-bold leading-5 text-[#0B8CAD] text-center">
                  Innovation
                </p>
              </div>
              <p className="text-sm text-gray-900">
                Our team is driven by a passion for innovation, constantly
                seeking new and better ways to solve nutritional deficient
                disorders.
              </p>
            </div>

            <div className="p-5 duration-300 transform bg-white border-2 border-solid border-sky-400 rounded shadow-sm border-deep-purple-accent-100 hover:-translate-y-2">
              <div className="flex items-center justify-center mb-2">
                {/* <p className="flex items-center justify-center w-10 h-10  text-lg font-bold text-black rounded-full bg-deep-purple-accent-400">
              3.
            </p> */}
                <p className="text-lg font-bold leading-5  text-[#0B8CAD] text-center">
                  Customer-Centric
                </p>
              </div>
              <p className="text-sm text-gray-900">
                Your satisfaction is at the heart of everything we do. We
                prioritize in providing good quality products to build lasting
                relationships.
              </p>
            </div>
          </div>
        </div>

        <div className="px-4 pt-8 md:pt-0 mx-auto sm:max-w-xl md:max-w-full mb-16 lg:max-w-screen-xl md:px-24 lg:px-12">
          <div className="grid  md:gap-10 lg:grid-cols-2">
            <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
              <div className="max-w-xl mb-6">
                <p className="text-base text-start text-gray-700 md:text-xl">
                  At{" "}
                  <span className="font-semibold text-[#0B8CAD] italic">
                    Dwiti pharma pvt ltd
                  </span>
                  &nbsp; we are more than just a pharmaceutical company, we are
                  a dedicated team committed to provide the best nutritional
                  supplements to all age groups and different medical scenarios,
                  boosting immunity and good health. Our products under the name
                  of EMMUNITI provide high-quality nutritional supplements.
                </p>
              </div>
            </div>
            <div className="lg:pl-8">
              <img
                className="object-cover w-full h-96 md:mt-10 rounded-lg"
                src={P1}
                alt=""
              />
            </div>
          </div>
        </div>

        {/* <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xl font-semibold tracking-wider text-sky-700 uppercase rounded-full bg-teal-accent-400">
                What Sets Us Apart
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-sm md:text-xl font-semibold leading-none tracking-tight text-gray-700 sm:text-lg md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-sky-300 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="84d09fa9-a544-44bd-88b2-08fdf4cddd38"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#84d09fa9-a544-44bd-88b2-08fdf4cddd38)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative  text-gray-700">INTENSE</span>
              </span>{" "}
              RESEARCH AND DOCTORS RECOMENDED FORMULATIONS WITH PROVEN RESULTS
            </h2>
          </div>
          <div className="grid gap-8 row-gap-5 md:row-gap-8 lg:grid-cols-3">
            <div className="p-5 duration-300 transform bg-white border-2 border-dashed border-sky-400 rounded shadow-sm border-deep-purple-accent-100 hover:-translate-y-2">
              <div className="flex items-center justify-center mb-2">
                <p className="flex items-center justify-center w-10 h-10  text-lg font-bold text-black rounded-full bg-deep-purple-accent-400">
              1.
            </p>
                <p className="text-lg font-bold leading-5 text-sky-600 text-center">
                  Quality
                </p>
              </div>
              <p className="text-sm text-gray-700">
                We take pride in delivering top-notch nutraceuticals products
                that exceed expectations.
              </p>
            </div>

            <div className="p-5 duration-300 transform bg-white border-2 border-dashed border-sky-400 rounded shadow-sm border-deep-purple-accent-100 hover:-translate-y-2">
              <div className="flex items-center justify-center mb-2">
                <p className="flex items-center justify-center w-10 h-10  text-lg font-bold text-black rounded-full bg-deep-purple-accent-400">
              2.
            </p>
                <p className="text-lg font-bold leading-5 text-sky-600 text-center">
                  Innovation
                </p>
              </div>
              <p className="text-sm text-gray-900">
                Our team is driven by a passion for innovation, constantly
                seeking new and better ways to solve nutritional deficient
                disorders.
              </p>
            </div>

            <div className="p-5 duration-300 transform bg-white border-2 border-dashed border-sky-400 rounded shadow-sm border-deep-purple-accent-100 hover:-translate-y-2">
              <div className="flex items-center justify-center mb-2">
                <p className="flex items-center justify-center w-10 h-10  text-lg font-bold text-black rounded-full bg-deep-purple-accent-400">
              3.
            </p>
                <p className="text-lg font-bold leading-5  text-sky-600 text-center">
                  Customer-Centric
                </p>
              </div>
              <p className="text-sm text-gray-900">
                Your satisfaction is at the heart of everything we do. We
                prioritize in providing good quality products to build lasting
                relationships.
              </p>
            </div>
          </div>
        </div> */}

        <Parallax
          blur={1}
          bgImage={Red1}
          bgImageAlt="the cat"
          strength={400}
          className="mb-20 md:mb-10 h-full  w-full object-cover py-4 md:py-0  "
        >
          <section
            class="relative sm:pt-14 sm:pb-12 sm:px-16  overflow-hidden"
            style={{ fontFamily: "Lato" }}
          >
            <div class="relative z-10 container px-4 mx-auto">
              <div class="md:max-w-9xl mx-auto">
                <div class="flex flex-wrap -m-5">
                  <div class="w-full md:w-1/2 p-2 md:p-5">
                    <div class=" bg-opacity-90  rounded-4xl ">
                      <div class="pt-2 md:pt-5 pb-5 sm:px-16 px-4">
                        <div class="">
                          <div class="py-4 px-9">
                            <h3 class=" text-xl text-white flex justify-center font-bold leading-snug">
                              Our Mission
                            </h3>
                          </div>
                        </div>
                        <ol class="md:mb-5 list-disc  text-white">
                          <li class="md:mb-4 flex items-center">
                            <p class="font-medium text-sm md:text-lg  leading-normal">
                              EMMUNITI is on a mission to provide good
                              nutritional supplements as we believe good
                              nutrition is essential for maintaining good
                              health. We strive to provide quality products and
                              our commitment is unwavering in nutraceuticals.
                            </p>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div class="w-full md:w-1/2 p-2 md:p-5">
                    <div class=" bg-opacity-90  rounded-4xl ">
                      <div class="pt-2 md:pt-5 pb-5 sm:px-16 px-4">
                        <div class="">
                          <div class="py-4 px-9">
                            <h3 class=" text-lg text-white flex justify-center font-bold leading-snug">
                              Join Us on Our Journey
                            </h3>
                          </div>
                        </div>
                        <ol class="md:mb-5 list-disc  text-white">
                          <li class="md:mb-4 flex items-center">
                            <p class="font-medium text-sm md:text-lg leading-normal">
                              As we continue to reach our goal on providing good
                              nutritional products, we invite you to be a part
                              of our story. As you're a customer, partner, or
                              enthusiast, we welcome you to connect with us.
                              <br />
                              Thank you for being a part of the EMMUNITI
                              community.
                            </p>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Parallax>
      </div>
    </Layout>
  );
}
