import { configureStore } from '@reduxjs/toolkit';
import productReducer from './products';
import categoryReducer from './catagories'
import cartReducer from './cartSlice';
import authSlice from './authSlice';
import orderReducer from './order'
import couponReducer from './coupon'
import Mobilebanner from "../api/mobilebanner";
import testimonial from './testimonial';
import adminReducer from './admin'

export default configureStore({
    reducer: {

        products:productReducer,
        categorys:categoryReducer,
        cart:cartReducer,
        auth:authSlice,
        order:orderReducer,
        coupon:couponReducer,
    mobbanner: Mobilebanner,
    testimonial: testimonial,
    admin:adminReducer

    },
});  