import React from 'react'

function Paymentfail() {
  return (
    <div>
        <div>
      <h1>Payment Failed</h1>
      <p>Sorry, your payment could not be processed. Please try again later.</p>
      
    </div>
    </div>
  )
}

export default Paymentfail