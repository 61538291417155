

const  keyUri = {



    // BACKEND_URI:'http://localhost:5000/api'

    // BACKEND_URI:"https://urchin-app-gcba5.ondigitalocean.app/api"
    
    BACKEND_URI:"https://coral-app-3zp7d.ondigitalocean.app/api"
}

let token = localStorage.getItem('token')
const config = {
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    }
  };

export  {keyUri, config }