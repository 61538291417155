import React from 'react'
import Layout from '../../Components'
import Mainpage from './Mainpage'

const Index = () => {
  return (
    <Layout>
        <Mainpage />
    </Layout>
  )
}

export default Index