import React, { useEffect, useState, useRef } from "react";
import { json, Link, NavLink, useParams, useNavigate } from "react-router-dom";
import { keyUri } from "../key";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import styled from "styled-components";
import Logo from "../Assets/emm logo.png";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../api/authSlice";
import { CgProfile } from "react-icons/cg";
import { cartProductSelector } from "../api/cartSlice";
import { authenticateSelector, currentUser } from "../api/authSlice";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaShippingFast } from "react-icons/fa";
import { Dropdown, Menu } from "antd";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navlinks, setNavlinks] = useState(null);
  const [drop, setDrop] = useState(false);
  const user = JSON.parse(localStorage.getItem("userinfo"));
  const { isAuthenticate } = useSelector(authenticateSelector);
  const dispatch = useDispatch();
  const { cartProducts } = useSelector(cartProductSelector);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isLoggedIn = !!user;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const dropdownRef = useRef();
  const dropdownRef1 = useRef();


  const handleMouseEnter = () => {
    setIsHovered(true);
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (!isHovered && dropdownVisible) {
      // Add a delay before hiding the dropdown
      const timeoutId = setTimeout(() => {
        setDropdownVisible(false);
      }, 3000); // Adjust the delay as needed

      return () => clearTimeout(timeoutId);
    }
  }, [isHovered, dropdownVisible]);

  const menu = (
    <Menu>
      <Menu.Item key="yourOrders">
        <Link to="/order">Your Orders</Link>
      </Menu.Item>
      <Menu.Item key="trackOrder">
        <div className="flex items-center">
          <Link to="/guest" className="flex items-center">
            Track Order
            <FaShippingFast className="rounded-md text-sky-700 ml-1" />
          </Link>
        </div>
      </Menu.Item>
      <Menu.Item key="logout">
        <div className="flex items-center">
        <Link
                  to="/"
                  onClick={() => {
                    dispatch(logOut());
                  }}
                >
                  <button className=" text-[#0B8CAD] font-semibold mt-3 border-2 border-sky-600  px-4 rounded-full transition duration-300 ease-in-out">
                    Logout
                  </button>
                </Link>
        </div>
      </Menu.Item>
      {/* Add more menu items as needed */}
    </Menu>
  );

  useEffect(() => {
    const Navlinks = async () => {
      const Links = await fetch(keyUri.BACKEND_URI + "/category");
      const NewLinks = await Links.json();
      setNavlinks(NewLinks);
    };

    Navlinks();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const { id } = useParams();
  useEffect(() => {
    id && setIsMenuOpen(false);
  }, [id]);

  return (
    <div
      style={{ fontFamily: "Lato" }}
      className="bg-[#0B8CAD] shadow-sm sticky top-0 z-30 w-full h-20 "
    >
      <div class=" w-full ">
        <div class="flex lg:justify-between w-full">
          <div class="flex items-end w-1/2">
            <Link to="/" class="flex items-end hidden md:block">
              <img src={Logo} alt="logo" class="lg:w-48  md:w-20"  />
            </Link>
          </div>

          <ul class="flex  space-x-8 py-5 justify-end md:space-x-14 text-white item-end  font-semibold text-md  font-Lato hidden  md:flex ">
            <li class="nav-item lg:py-1">
              <Link to="/" class="nav-link">
                Home
              </Link>
            </li>
            {/* <li class="nav-item">
              <Link to="/aboutus" class="nav-link">
                About Us
              </Link>
            </li> */}

            <li className="nav-item relative" ref={dropdownRef}>
              <Link
                to="#"
                className="nav-link"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <span className="flex justify-between mt-1">
                  <span> Shop </span>{" "}
                  <span className="pt-1">
                    {isDropdownOpen ? (
                      <MdKeyboardArrowUp />
                    ) : (
                      <MdKeyboardArrowDown />
                    )}
                  </span>
                </span>
              </Link>
              {isDropdownOpen && (
                <div
                  className="absolute left-0 mt-0 bg-white text-[#0B8CAD] p-2 space-y-1 shadow-md text-left text-sm my-4 border rounded-md"
                  onClick={(event) => event.stopPropagation()} // Prevent propagation
                >
                  {navlinks?.map((items, i) => (
                    <Link
                      key={i}
                      to={`/catagories/${items._id}`}
                      aria-label=""
                      title=""
                      onClick={closeDropdown}
                      className="block px-1  py-1 hover:bg-gray-300 hover:text-sky-900 cursor-pointer "
                    >
                      <div className="w-44">{items?.category_name}</div>
                    </Link>
                  ))}
                </div>
              )}
            </li>

            <div className="col-span-1 mt-2 items-center justify-center text-white mr-6 hidden md:block">
              <Link to="/cart">
                <div className="flex justify-between gap-3">
                  <AiOutlineShoppingCart
                    className="text-xl text-[#ffffff] "
                    style={{ fontSize: "22px" }}
                  />
                  {/* <span className="text-md ">Cart</span> */}
                </div>
                <Badge isVisible={cartProducts.length > 0}>
                  <p>{cartProducts.length}</p>
                </Badge>
              </Link>
            </div>

            {/* <li class="nav-item">
              <Link to="/allproduct" class="nav-link">
                All Products
              </Link>
            </li> */}

            {/* {user?.user_name && (
              <li class="nav-item">
                <Link to="/order" class="nav-link">
                  Your Orders
                </Link>
              </li>
            )} */}

            {/* <li class="nav-item">
              <Link to="/contactus" class="nav-link">
                Contact Us
              </Link>
            </li> */}
          </ul>

          <div style={{ fontFamily: "Lato" }} className="hidden md:flex">
            {isLoggedIn ? (
              <div className="flex justify-between gap-3 pt-1 mr-20">
                <Dropdown
                  overlay={menu}
                  visible={dropdownVisible}
                  onVisibleChange={(visible) => setDropdownVisible(visible)}
                  trigger={["click"]}
                >
                  <div
                    className="flex pb-2 items-center justify-center"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={dropdownRef1}
                  >
                    <CgProfile className="text-xl space-x-8 py-6  lg:text-white" />
                    <span className="text-lg capitalize text-white">
                      {user?.user_name}
                    </span>
                  </div>
                </Dropdown>

                
                {/* <Link
                  to="/"
                  onClick={() => {
                    dispatch(logOut());
                  }}
                >
                  <button className=" text-[#2B9BB8] font-semibold mt-3  px-4 rounded-full transition duration-300 ease-in-out">
                    Logout
                  </button>
                </Link> */}
              </div>
            ) : (
              <div>
                <Link to="/profile">
                  <button className=" text-white mr-36 py-5 font-semibold  rounded-full transition duration-300 ease-in-out">
                    Login
                  </button>
                </Link>
              </div>
            )}
            {/* <div className="col-span-1 mt-2 items-center justify-center text-sky-800 mr-6 hidden md:block">
              <Link to="/cart">
                <div className="flex justify-between gap-3">
                  <AiOutlineShoppingCart
                    className="text-xl text-sky-800 "
                    style={{ fontSize: "22px" }}
                  />
                  <span className="text-md ">Cart</span>
                </div>
                <Badge isVisible={cartProducts.length > 0}>
                  <p>{cartProducts.length}</p>
                </Badge>
              </Link>
            </div> */}
          </div>
          {/* {!isLoggedIn && (
            <div className=" bg-[#314387] px-4 hidden md:block rounded-md">
              <Link to="/guest" className="flex gap-1">
                <span className="text-lg font-bold text-white inline-block">
                  Track Order
                </span>{" "}
                <span className="mt-2">
                  <FaShippingFast className="rounded-md text-white" />
                </span>
              </Link>
            </div>
          )} */}
        </div>

        <div className="flex items-center justify-between z-20 md:hidden px-2">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            class="p-2 -mr-1 transition duration-200 rounded focus:outline-none  focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg class="w-5 text-black" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>

          <div class="lg:hidden">
            {isMenuOpen && (
              <SidebarWrap
                key="first"
                initial={{ x: -250, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -280, opacity: 1 }}
                transition={{
                  duration: 0.36,
                  type: "tween",
                  ease: "easeInOut",
                }}



                
              >
                <div class="absolute   w-full ">
                  <div
                    className="container  lg:w-4/5 block  "
                    style={{ fontFamily: "Lato" }}
                  >
                    <div>
                      <div className="flex gap-4 ">
                        {isAuthenticate ? (
                          <li class="dropdown inline ml-4  text-start  cursor-pointer    ">
                            <div className=" flex justify-between gap-3 py-6">
                              <CgProfile className="text-4xl text-white mt-1" />{" "}
                              <span className="text-md mt-3 text-white capitalize ">
                                {user?.user_name}
                              </span>
                            </div>
                            <div class="dropdown-menu absolute hidden h-auto mt-3  z-50   ">
                              <ul class="block w-full bg-[#FFFF] shadow px-6 py-4 white rounded"></ul>
                            </div>
                          </li>
                        ) : (
                          <Link
                            to="/profile"
                            className="flex py-6 text-white  text-[20px] font-semibold ml-4 rounded-lg  shrink-0 "
                          >
                            <span> Login</span>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <MenuWrap>
                    <nav style={{ fontFamily: "Lato" }}>
                      <ul
                        class="space-y-2   text-left   text-[14px] text-[#0B8CAD]"
                        style={{ fontFamily: "Lato" }}
                      >
                        <li className="nav-item pl-2">
                          <NavLink activeClassName="selected" exact to="/">
                            Home <MdKeyboardArrowRight />
                          </NavLink>
                        </li>
                        <li className="nav-item pl-2">
                          <NavLink
                            activeClassName="selected"
                            exact
                            to="/aboutus"
                          >
                            About Us <MdKeyboardArrowRight />
                          </NavLink>
                        </li>

                        <li
                          className="nav-item pl-2"
                          activeClassName="selected"
                        >
                          <NavLink
                            id="droptoggle"
                            to="#"
                            style={{
                              backgroundColor: drop ? "#f5f5f5" : "#fff",
                              color: drop ? "var(--brandColor)" : "grey",
                            }}
                            onClick={() => setDrop(!drop)}
                            exact
                          >
                            {" "}
                            Categories{" "}
                            {drop ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowDown />
                            )}{" "}
                          </NavLink>
                          {drop && (
                            <div className="dropdown_menu">
                              {navlinks?.map((items, i) => (
                                <Link
                                  key={i}
                                  to={`/catagories/${items._id}`}
                                  aria-label=""
                                  title=""
                                >
                                  {items?.category_name}
                                </Link>
                              ))}
                            </div>
                          )}
                        </li>

                        {/* <li className="nav-item pl-2">
                          <NavLink
                            activeClassName="selected"
                            exact
                            to="/allproduct"
                          >
                            Products <MdKeyboardArrowRight />
                          </NavLink>
                        </li> */}
                        <li className="nav-item pl-2">
                          <NavLink
                            activeClassName="selected"
                            exact
                            to="/guest"
                          >
                            Track Order <FaShippingFast className="text-sky-700" />
                          </NavLink>
                        </li>
                        {user?.user_name && (
                          <li className="nav-item pl-2">
                            <NavLink to={`/order`} activeClassName="selected">
                              My order
                              <MdKeyboardArrowRight />
                            </NavLink>
                          </li>
                        )}

                        <li className="nav-item pl-2">
                          <NavLink to={`/condition`} activeClassName="selected">
                            Terms & conditions
                            <MdKeyboardArrowRight />
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2">
                          <NavLink to={`/privecy`} activeClassName="selected">
                            Privacy plolicy
                            <MdKeyboardArrowRight />
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2">
                          <NavLink to={`/refund`} activeClassName="selected">
                            Refund
                            <MdKeyboardArrowRight />
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2">
                          <NavLink to={`/contactus`} activeClassName="selected">
                            Contact us
                            <MdKeyboardArrowRight />
                          </NavLink>
                        </li>

                        {user?.user_name && (
                          <li className="nav-item pl-2">
                            <NavLink
                              tabIndex="0"
                              href="/"
                              onClick={() => {
                                dispatch(logOut());
                              }}
                              activeClassName="selected"
                            >
                              Logout <MdKeyboardArrowRight />
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </MenuWrap>
                </div>
              </SidebarWrap>
            )}

            <div>
              <AnimatePresence mode="wait">
                {isMenuOpen && (
                  <BackdropWrap
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.36,
                      type: "tween",
                      ease: "easeInOut",
                    }}
                    onClick={() => setIsMenuOpen(false)}
                  />
                )}
              </AnimatePresence>
            </div>
          </div>

          <Link to="/">
            <img src={Logo} alt="logo" className=" w-48 mt-2 " />
          </Link>
        </div>
      </div>
    </div>
  );
}
const Badge = styled.div`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  position: absolute;
  z-index: auto;
  min-width: 15px;
  height: 15px;
  padding: 0 3px;
  color: #f9f9f9;
  font-size: 10px;
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  font-weight: bold;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
  transform: translate(14px, -28px);
`;

const BackdropWrap = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0a0a0a05;
`;

const SidebarWrap = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 49;
`;

const MenuWrap = styled.div`
  .dropdown_menu {
    text-align: left;
    /* height: 167px ; */
    max-height: 167px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;

    svg {
      font-size: 1rem;
      margin-right: 0.2rem;
    }
  }

  #subdroptoggle {
    background-color: white;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      background-color: #fdf2f8;
      color: white;
    }
  }

  ul {
    padding-bottom: 0.7px;
    background-color: #f5f5f5;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-bottom: 0.1rem;
        transition: 0.3s;

        &:hover {
          background-color: #fdf2f8;
          color: white;
        }
      }

      list-style: none;
      background-color: white;
      color: grey;
      cursor: pointer;

      svg {
        font-size: 1.3rem;
      }

      .selected {
        background-color: black;
        color: black;
        width: 100%;
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 0.9rem;
        margin-bottom: 0.3rem;
      }
    }
  }
`;
