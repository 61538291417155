import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import {message} from 'antd'
import { toast } from 'react-toastify'
import {keyUri} from '../key'
import { useNavigate} from 'react-router-dom'

export const initialState = {

    loading: false,
    order:[],
    currentOrder : [],
    orderAdresss:null,
    shippingcharge:null

}


export const orderSlice = createSlice({
    name:"order",
    initialState,
    reducers:{

        getOrder: state =>{
            state.loading = true
        },

        getOrderAddress: (state, {payload}) => {
        
            console.log(payload)

            state.loading = false
            state.orderAdresss = payload
                
          },

          getOrderShipping:(state, {payload}) => {

            state.loading = false
            state.shippingcharge = payload


          },


        getOrderSuccess: (state, {payload}) => {
        

            state.loading = false
            state.order = payload
            state.isOrderPlaced = true
            
          },

        getCurrentOrder: (state, {payload}) =>{

            state.loading = false
            state.currentOrder = payload

            
        },

        getOrderFailure: (state, {payload}) =>{

            state.loading = false
            state.order = payload
            
        },
    }
})


export const {getOrder, getCurrentOrder,getOrderSuccess,getOrderFailure, getOrderAddress, getOrderShipping} = orderSlice.actions
      
export const orderSelector = state => state.order

export default orderSlice.reducer





const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };



export const  fetchAllOrders = () => async dispatch =>{
      const key = "order"
      dispatch(getOrder())
      
    try {

        const {data} = await axios.get(keyUri.BACKEND_URI +'/order');
        
        dispatch(getOrderSuccess(data))

    } catch ({response}) {

        dispatch(getOrderFailure())
        response.data && message.error({ content: response.data.msg, key, duration: 2 });

    }
    
}


export const createOrder = (orderData,  nav ) => async (dispatch ) => {
    
    // const nav=useNavigate()

    console.log(orderData)
    
    localStorage.removeItem('emmuniticart')
    localStorage.removeItem('total')
    localStorage.removeItem('orderData')

      dispatch(getOrder())  
      const key = 'create';
      
    try {
 
     const {data} = await axios.post(keyUri.BACKEND_URI +`/orders`, orderData, config)
     toast.success ('Orderd successfully', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        
    });
    data && console.log(data);

    data && (window.location.href = `/orderconfirmation/${data?.order?._id}`)

    // data && setImmediate(()=>{
    //     console.log('fuhiuhuhiklhhouih')
    // // nav(`/orderconfirmation/${data._id}`)
    // window.location.href = `/orderconfirmation/${data._id}`
    // },2000)   

    // data && setTimeout(()=>{
    //     window.location.href = `/order`
    //     },2000)   


    } catch ({response}) {
        dispatch(getOrderFailure())
    }
}




export const  fetchOneOrder = (id) => async dispatch =>{
    const key = "order"
    dispatch(getOrder())
    
  try {
       
      const {data} = await axios.get(keyUri.BACKEND_URI +`/orders/${id}`);
console.log(data)
      dispatch(getCurrentOrder(data))

  } catch ({response}) {

      dispatch(getOrderFailure())
      response.data && message.error({ content: response.data.msg, key, duration: 2 });

  }
  
}


export const  OrderdAddress = (addressdata) => async dispatch =>{

 console.log(addressdata)   
  try {
       
      dispatch(getOrderAddress(addressdata))

      console.log(addressdata)

  } catch ({response}) {

      dispatch(getOrderFailure())
  }
  
}


export const  fetchUserOrder = (id) => async dispatch =>{

    const key = "order"
    dispatch(getOrder())
    
  try {
       
      const {data} = await axios.get(keyUri.BACKEND_URI +`/user-orders/${id}`);

    dispatch(getOrderSuccess(data))
    console.log(data)

  } catch ({response}) {

      dispatch(getOrderFailure())
    //   response.data && message.error({ content: response.data.msg, key, duration: 2 });

  }
  
}


export const  ShippingAmount = (shippingData) => async dispatch =>{

  console.log(shippingData)
  
        const key = "order"
        dispatch(getOrder())
        
        
      try {
  
          console.log(shippingData)
          const {data} = await axios.post(keyUri.BACKEND_URI +'/shippingAmount', shippingData, config);    
          console.log(data)
          
          dispatch(getOrderShipping(data))
  
      } catch ({response}) {
  
          dispatch(getOrderFailure(response.data))
          // response.data && message.error({ content: response.data.msg, key, duration: 4 });
  
      }
      
  }