import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Form, Input, Button } from "antd";
import { fetchUserOrder, orderSelector, fetchOneOrder } from "../../api/order";
import { authenticateSelector, checkAuth } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Components";

export default function OrderDetails() {
  
  const [form] = Form.useForm();
  const { user } = useSelector(authenticateSelector);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentOrder, loading } = useSelector(orderSelector);
  console.log(currentOrder);

  const nav = useNavigate();
  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserOrder(user?._id));
  }, [user]);

  useEffect(() => {
    dispatch(fetchOneOrder(id));
  }, [id]);

  return (
    <Layout>
      <div
        class="relative max-w-screen-xl sm:px-4 sm:py-8 mx-auto  md:bg-[#ffffff] min-h-[78vh]"
        style={{ fontFamily: "Lato" }}
      >
        <div class="pb-3 mb-3 mt-5 text-center border-b border-black border-opacity-5">
          <h2
            class=" md:text-[24px] text-[16px] font-semibold font-heading md:text-left text-center text-[#0B8CAD]"
            style={{ fontFamily: "Lato" }}
          >
            Thank you for your Order.
          </h2>
        </div>

        <div class="md:flex">
          <div class="w-full md:w-7/12 xl:w-9/12 md:pr-7 mb-14 md:mb-0">
            <div class="  md:flex flex md:justify-start justify-center md:items-center items-center  mb-8  ">
              <span className="text-[#079E55] md:text-[32px]">
                <AiFillCheckCircle />
              </span>
              <h3 class=" md:ml-6 ml-3 md:text-[16px] text-[14px] font-heading  text-[#0B8CAD] font-semibold text-center md:text-left">
                Order Completed Successfully!
              </h3>
            </div>
            <div class="    ">
              <p class=" mb-3 text-black md:text-[14px] text-[12px] md:justify-start justify-center font-bold md:text-left text-center">
                Order ID : {currentOrder?.orderId}
              </p>

              <div className="pb-2">
                {currentOrder?.products?.map((item, i) => {
                  return (
                    <div className=" md:flex flex pb-4 border-b md:justify-between justify-center md:w-[70%]">
                      <div>
                        <div className=" flex justify-start w-[100%] mt-4">
                          <div className=" ">
                            <img
                              className=" block h-full  "
                              src={item?.featured_image}
                              alt="product"
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>
                          <div className="   ">
                            <small
                              className=" md:ml-4 ml-3 font-semibold  text-[14px] md:text-[16px] flex justify-start text-[#0B8CAD] "
                              style={{ fontFamily: "Lato" }}
                            >
                              {item?.product_name} <br />{" "}
                            </small>
                            <h1 className=" text-start md:pl-4 pl-3 sm:text-[14px] text-[#0B8CAD]">
                              Variant : {item?.selectedVariant?.variant_qty}{" "}
                              {item?.selectedVariant?.stock_units}
                            </h1>

                            <h1
                              className="text-start md:pl-4 pl-3 sm:text-[14px] text-[#0B8CAD]"
                              style={{ fontFamily: "Lato" }}
                            >
                              {" "}
                              Rs {item?.selectedVariant?.total_price}
                            </h1>
                            <h1 className=" text-start md:pl-4 pl-3 sm:text-[14px] text-[#0B8CAD]">
                              Qty : {item?.selectedVariant?.qty}
                            </h1>

                            <div className="md:hidden ">
                              <h1
                                className="text-[#0B8CAD] md:text-[16px] text-[12px] text-start pl-3 "
                                style={{ fontFamily: "Lato" }}
                              >
                                {" "}
                                {moment(item?.date).format("MMMM Do YYYY")}
                              </h1>
                              <h2
                                className="text-[#0B8CAD] md:text-[16px] text-[12px] text-start pl-3"
                                style={{ fontFamily: "Lato" }}  
                              >
                                {" "}
                                {item?.time}{" "}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        {/* <div className=" text-start md:block hidden">
                          <h1
                            className="text-[#0B8CAD] md:text-[16px] text-[12px]  "
                            style={{ fontFamily: "Lato" }}
                          >
                            {" "}
                            {moment(item?.date).format("MMMM Do YYYY")}
                          </h1>
                          <h2
                            className="text-[#0B8CAD] md:text-[16px] text-[12px]  "
                            style={{ fontFamily: "Lato" }}
                          >
                            {" "}
                            {item?.time}{" "}
                          </h2>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* <div className=' mx-2 px-2 mb-2 md:grid md:grid-cols-4  grid grid-cols-2'>
                          <h1 className="py-1 text-[#855252] mb-2  text-left "> <b>Order Status :</b> </h1>  <h2 className=' py-1 mb-2 text-right md:col-span-3 md:text-left'>{currentOrder?.orderStatus === 'Delivered' ? 'Delivered'
                                      : moment().isBetween() ? 'Ordered' : 'Ordered'   }</h2> */}
            <div class=" md:flex md:justify-between w-[70%] md:items-center  mb-4">
              <div className=" mx-0 px-0 mb-2">
                <p className=" text-[16px]  text-black mb-2  md:text-left text-center ">
                  {" "}
                  Order Status :{" "}
                  <span className="text-[#079E55]">
                    {" "}
                    {currentOrder?.order_status}
                  </span>
                </p>
              </div>

              <div className="address mx-2 text-[#1d1c1c] md:text-left md:text-[14px] text-[12px] md:col-span-3 ml-3">
                <h6 className="capitalize mb-1 md:mb-2">
                  <b>Delivery Address :</b> <br />
                  {currentOrder?.delivred_username}
                </h6>
                <p className="capitalize mb-1 md:mb-2">
                  {currentOrder?.delivred_addrees}, &nbsp;{" "}
                  {currentOrder?.delivred_town}
                </p>
                <p className="capitalize mb-1 md:mb-2">
                  Dt: {currentOrder?.delivred_district}&nbsp;&nbsp;&nbsp; State:{" "}
                  {currentOrder?.delivred_state}-
                  {currentOrder?.delivred_pincode}
                </p>
                {/* Uncomment the following lines if you want to display the delivery date */}
                {/* <p className="capitalize mb-1 md:mb-2">
    <b>Delivery Date:</b>{" "}
    {moment(currentOrder?.deliverd_date).format("MMMM Do YYYY")}
  </p> */}
              </div>
            </div>
          </div>

          <div class=" items-center" style={{ fontFamily: "Lato" }}>
            <h2 class="  mb-4 mt-5 md:mt-0 sm:text-[20px] text-[18px] font-bold font-heading text-[#0B8CAD]">
              Order Summary
            </h2>
            <div class="p-4 ml-10 md:ml-0 rounded-md shadow-lg border border-[#0B8CAD] w-72 ">
              <div class="flex justify-between">
                <span class="text-[#0B8CAD] md:text-[14px] font-bold mb-2">
                  Sub total
                </span>
                <span class="sm:text-[14px] font-bold font-heading text-[#0B8CAD]">
                  {" "}
                  Rs {currentOrder?.sub_total}/-
                </span>
              </div>
              <div class="flex justify-between">
                <span class="text-[#0B8CAD] sm:text-[14px] font-bold">
                  Shipping charges
                </span>

                <span class="sm:text-[14px] font-bold font-heading text-[#0B8CAD]">
                  {currentOrder?.shipping_charge} /-
                </span>
              </div>

              <div class=" border-b border-blue-100 mb-6">
                <div class="flex  justify-between ">
                  <span class="text-[#0B8CAD] md:text-[14px] font-bold">
                    Coupon Discount
                  </span>

                  <span class="sm:text-xl md:text-[14px] font-bold font-heading text-[#0B8CAD]">
                    {currentOrder?.coupons?.discount} %
                  </span>
                </div>
              </div>
              <div class="flex mb-2 justify-between items-center">
                <span class="text-[16px] font-bold font-heading text-[#0B8CAD]">
                  Total
                </span>
                <span class="text-[16px] font-bold font-heading text-[#0B8CAD]">
                  {" "}
                  Rs {currentOrder?.total}/-
                </span>
              </div>
            </div>

            <Link to="/">
              <div className=" md:mt-6 mt-5 mb-12 md:text-right text-center">
                <button
                  className="btn px-7 py-2   my-1 rounded shadow-md"
                  style={{
                    color: "white",
                    backgroundColor: "#0B8CAD",
                    fontWeight: "500",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Continue Shopping
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
