import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Product from "./Pages/Products";
import Profile from "./Pages/Profile";
import Cart from "./Pages/Cart";
import Filter from "./Pages/filter";
import Catagories from "./Pages/Catagories";
import Signup from "./Pages/Signup";
import Order from "./Pages/order";
import OrderDetails from "./Pages/order/orderdetails";
import OrderConfirmation from "./Pages/order/orderconfirmation";
import OrderSummery from "./Pages/Cart/ordersummery";
import OrderAddress from "./Pages/Cart/orderaddress";
import PaymentAddress from "./Pages/Cart/paymentaddress";
import Payment from "./Pages/Cart/payment";
import Condition from "./Pages/termsPolicy/condition";
import Privecy from "./Pages/termsPolicy/privecy";
import Refund from "./Pages/termsPolicy/refund";
import Contactus from "./Pages/termsPolicy/contactus";
import Search from "./Pages/search/index";
import Install from "./Pages/pwa/install";
import Userprofile from "./Pages/userAccount/userprofile";
import AllProduct from "./Pages/Products/allproducts";
import Aboutus from "./Pages/Aboutus/aboutus";
import Blog from "./Pages/Blog/Blog";
import EditProfile from "./Pages/Profile/Editprofile";
import Paymentfail from "./Pages/Cart/Paymentfail";
import Errorpayment from './Pages/Cart/Errorpayment'
import LoginWithOTP from "./Pages/Shared/LoginwithOTP";
import GuestOrder from "./Pages/order/guestOrders";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Product/:id" element={<Product />} />
        <Route path="allproduct" element={<AllProduct />} />
        <Route path="profile" element={<Profile/>} />
        <Route path="cart" element={<Cart />} />
        {/* <Route path="filter" element={<Filter/>} /> */}
        <Route path="/catagories/:id" element={<Catagories />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/order" element={<Order />} />
        <Route path="orderdetails/:id" element={<OrderDetails />} />
        <Route path="orderconfirmation/:id" element={<OrderConfirmation />} />
        <Route path="ordersummery" element={<OrderSummery />} />
        <Route path="orderaddress" element={<OrderAddress />} />
        <Route path="paymentaddress/:emmuniti" element={<PaymentAddress />} />
        <Route path="payment" element={<Payment />} />
        <Route path="condition" element={<Condition />} />
        <Route path="privecy" element={<Privecy />} />
        <Route path="refund" element={<Refund />} />
        <Route path="contactus" element={<Contactus />} />
        <Route path="search" element={<Search />} />
        <Route path="install" element={<Install />} />
        <Route path="userprofile" element={<Userprofile />} />
        <Route path="aboutus" element={<Aboutus />} />
        <Route path="blog" element={<Blog />} />
        <Route path="editprofile" element={<EditProfile />} />

        <Route path="/payment-failure" element={< Paymentfail/>} />
        <Route path="/error" element={<Errorpayment />} />
        <Route path = "/login-otp" element={<LoginWithOTP/>}/>
        <Route path = "/guest" element={<GuestOrder/>}/>





      </Routes>
    </div>
  );
}

export default App;
