import React, {useEffect,useState} from 'react'
import Layout from '../../Components'
import ProductSectionone from './ProductSectionone'
import Upsell from './Upsell'
import {fetchOneProduct, productSelector,} from '../../api/products' 
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'


const Index = () => {
  const {id} = useParams()
  const dispatch = useDispatch()
  
  const {currentProduct} = useSelector(productSelector)

console.log(currentProduct)
  
  useEffect(()=>{

    dispatch(fetchOneProduct(id))
    // dispatch(fetchAllcategoryProduct(id))
    
    }, [id])

  return (
    <Layout>
        <ProductSectionone data={currentProduct} id={id} />
     
    </Layout>
  )
}

export default Index