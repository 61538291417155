import React,{useState, useEffect, useLayoutEffect} from 'react'
import styled from 'styled-components'
import {  useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom'
import {HiOutlineArrowNarrowLeft} from 'react-icons/hi'
import {createOrder} from '../../api/order' 
import {  qtyupdate,  cartProductSelector,deleteCartItem } from '../../api/cartSlice'
import {fetchlogin, authenticateSelector,checkAuth} from '../../api/authSlice'
import {FiChevronRight} from 'react-icons/fi'
import {FaCoins} from 'react-icons/fa'
import {fetchUserOrder,orderSelector,fetchOneOrder } from '../../api/order'
import Layout from '../../Components'






export default function Payment() {

    const dispatch = useDispatch()
    const nav=useNavigate()
    const {cartProducts, total} = useSelector(cartProductSelector)
    const { user } = useSelector(authenticateSelector)
    const {orderAdresss} = useSelector(orderSelector)
    const { allOrder, loading } = useSelector(orderSelector)   
  

    console.log(orderAdresss)
  


    const placeOrder = () =>  {

        let orderData = {
            orderProducts: cartProducts,
            user: user?._id,
            address : user?.address,
            status:'unpaid',
            payment: 'COD',
            total: total,
            sub_total:total,
            delivred_username:orderAdresss?.delivred_username,
            delivred_phonenumber:orderAdresss?.delivred_phonenumber,
            delivred_addrees:orderAdresss?.delivred_addrees,
            delivred_pincode:orderAdresss?.delivred_pincode,
            delivred_town:orderAdresss?.delivred_town,
            delivred_district:orderAdresss?.delivred_district,
            delivred_state:orderAdresss?.delivred_state,

          }
          dispatch(createOrder(orderData, nav ))
         
          console.log(orderData)
    
    }


  
    return (


<Layout>


        <OrderWrap >

        <div className='relative max-w-screen-xl px-4 py-8 mx-auto min-h-[78vh]' > 

            <h3 className="text-[10px] sm:text-[16px]  pl-2 mb-4 text-[#855252]" style={{fontFamily:'Lato'}}>
            <Link to="/ordersummery"> ORDER SUMMERY </Link>---------<Link to="/orderaddress"> ADDRESS</Link> ---------  <span className='font-bold  underline '>PAYMENT</span>
            </h3>


            <div className='py-6 bg-white shadow-lg mb-8'>

                
                <div className=" flex justify-center p-2 mt-2 items-center bg-slate-50 z-30 " style={{height:'45px'}}>
                {/* <HiOutlineArrowNarrowLeft className=" text-2xl m-0" onClick={()=> orderBtn ? setOrderBtn(false) :  nav('/')} />  */}
                <h1 className="mx-auto  my-0 font-medium  transform -translate-x-2 text-[#855252]" style={{fontFamily:'Lato'}}>ORDER SUMMARY</h1>
                </div>
                
                        
                <div className="grid grid-cols-2 mx-2 mt-1 pt-1 px-2 "style={{fontFamily:'Lato'}}>
                <p  className="text-right font-medium">Total Products</p>
                <p className="text-left ml-8 font-medium">{cartProducts.length}</p>
                </div>

                    

                
                <div className="grid grid-cols-2 mx-2 mb-1 pb-1 px-2 "style={{fontFamily:'Lato'}}>
                <p className="text-right font-medium">Total Amount</p>
                <p className="text-left ml-8 font-medium"> Rs {total}/-</p>
                </div>

                <div className=" flex justify-center mt-6 mb-1 items-center mx-2 bg-slate-50 z-30"style={{fontFamily:'Lato'}}>
                <h2 className="py-2 ">Delivery Address</h2>    
                </div>

                <div className=" mx-5 " style={{fontFamily:'Lato'}}> 
                    <h6 className=" capitalize">{orderAdresss?.delivred_username}</h6>
                    <p className="capitalize">#{orderAdresss?.delivered_address}, {orderAdresss?.delivred_town} {orderAdresss?.delivred_pincode}  <br/></p>
                    <p> {orderAdresss?.delivred_district} <br/></p>
                    <p> {orderAdresss?.delivred_state} <br/></p>
                    <p>{orderAdresss?.delivred_phonenumber}</p>
                </div>


                
                
                <div className=" flex justify-center mt-6 mb-3 items-center mx-2 bg-slate-50 z-30"style={{fontFamily:'Lato'}}>
                <h2 className="py-2 ">Payment Method</h2>    
                </div>

                <div className=" payment mx-3">
                    {/* <div className="grid grid-cols-8 cursor-pointer" onClick={() => onClickPayment('COD')}>
                    <div className=" text-yellow-500 grid grid-cols-2 p-0 text-lg m-auto py-2"><FaCoins/><FaCoins/> </div>
                    <p className="col-span-6 my-auto pl-2"style={{fontFamily:'Lato'}}>CASH ON DELIVERY</p>
                    <FiChevronRight className=" text-gray-400 ml-auto my-auto"/>
                    </div>  */}

                    <div className='flex  justify-center items-center gap-6'>

                    <div className="radio text-[#855252]">
                    <label>
                    <input type="radio" className='text-[#855252] gap-4' value="option1" checked={true} />
                    <span className='pl-5'>Cash On Delivery</span>
                   </label>
                   </div>


                   <div className="radio text-[#855252]">
                    <label>                   
                    <input type="radio" className='text-[#855252]' value="option1" checked={true} />               
                    <span className='pl-5'>Pay Online</span>
                    </label>
                   </div>

                   </div>

                    {/* <div className="grid grid-cols-8 cursor-pointer mb-3"  >
                    <p className="mx-auto px-1 rounded font-semibold border-2 border-gray-500  italic"> UPI 
                    <hr className="m-0 mb-1 border-none bg-yellow-400" style={{ height:'2px'}}/>
                    </p>
                    <p className="col-span-6  my-auto pl-2 text-xl "style={{fontFamily:'Lato'}}> PAY ONLINE </p>
                    <FiChevronRight className=" text-gray-400 ml-auto my-auto"/>
                </div> */}



                    {/* <Link to='/orderconfirmation'> */}
                    <div className="text-center lg:text-right ml-8"> 
                    <button className="btn px-9 py-2  w-64 my-1 rounded shadow-md"  style={{color:'white', backgroundColor:'#855252', fontWeight:'500'}}
                    type="primary" htmlType="submit"
                    onClick={placeOrder}   >                   
                    Place Order 
                    </button>

                    </div>                
                    {/* </Link> */}


                </div>
            </div>
        </div>

        </OrderWrap>
        </Layout>
    )
}


const OrderWrap = styled.div`
    
   

    .total{
        background-color: #fcfcfc;
        font-size: 0.8rem;
        letter-spacing: 0.3px;
        line-height: 2.3;

        input, optgroup, select {
        background-color: transparent;
        font-size: 0.72rem;
        border: 1px solid grey;
        border-radius: 0.2rem;
        padding: 0rem;
        margin: 0;
        }
    }

    .payment{
       p{ font-size: 0.8rem;
        letter-spacing: 0.3px;
        color:grey;}
    }

  
 


`