import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { keyUri } from "../../key";
import { fetchAllcategoryProduct, productSelector } from "../../api/products";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Imag2 from "../../Assets/images/p2.svg"

function Sectionfive() {
  const [category, setCategory] = useState({});
  const { products } = useSelector(productSelector);
  const [isdataloaded, setisdataloaded] = useState(false);

  console.log(products);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categorydata = await fetch(keyUri.BACKEND_URI + `/category`, {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        });
        const category = await categorydata.json();
        setCategory(category);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };
    fetchData();
  }, []);

  console.log(category[6]);

  useEffect(() => {
    dispatch(fetchAllcategoryProduct(category[0]?._id));
    setisdataloaded(true);
  }, [category]);

  return (
    <div className="bg-[#f5f3f4] md:px-44">
       {/* <div class="w-full text-md font-semibold text-gray-800 leading-relaxed  ">
          <div style={{ fontFamily: "Lato" }} class="max-w-xs mx-auto text-center">
            <img class="mx-auto h-20 mb-2 border rounded-full p-4 w-20" src={Imag2} alt=""/>
       
            RESULT ORIENTED
          </div>
        </div> */}
      <h2
        className="relative inline-block px-4 text-2xl md:text-4xl text-md   text-center py-2  text-block-700  "
        style={{ fontFamily: "Lato" }}
      >
        {category[0]?.category_name}
      </h2>
      {isdataloaded && (
        <Swiper
          slidesPerView={1}
          spaceBetween={2}
          loop={true}
          loopFillGroupWithBlank={true}
          grabCursor={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            "@0.75": {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            "@1.00": {
              slidesPerView: 4,
              spaceBetween: 0,
            },
            "@2.00": {
              slidesPerView: 4,
              spaceBetween: 0,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          <div className="grid grid-cols-1 lg:grid-cols-4">
            {products?.map((items, index) => (
              <SwiperSlide>
                <div className="p-3 w-full " key={index}>
                  {items?.stock_qty <= 0 && (
                    <p className="bg-black absolute flex justify-center items-end md:pb-20 pb-16 sm:h-[350px] pt-2 h-56 sm:w-[265px] w-full bg-opacity-30 text-center md:text-2xl text-xl text-white">
                      <span>Out of stock</span>
                    </p>
                  )}
                  <div className=" h-[480px] md:h-[500px] bg-white rounded-3xl md:mb-0">
                    <a className="block mx-auto max-w-max ">
                      <img
                        className="h-72 w-96 md:h-80 md:w-96 object-cover"
                        src={items?.featured_image}
                        alt="product image"
                      />
                    </a>

                    <div className="w-full mt-4">
                      <p style={{ fontFamily: "Lato" }} className="mb-2 text-xl md:text-2xl leading-8 text-center text-block-700 font-heading font-medium uppercase">
                        {items?.product_name}
                      </p>

                      <p className="mb-1 text-center font-heading font-medium tracking-tighter">
                        <span style={{ fontFamily: "Lato" }} className="text-red-500 text-sm">
                          % {items?.product_variants[0]?.discount}
                        </span>
                        <span style={{ fontFamily: "Lato" }} className="text-xl ml-2 text-block-600 text-center">
                          Rs {items?.product_variants[0]?.total_price}
                        </span>
                      </p>
                      <p style={{ fontFamily: "Lato" }} className="text-center text-sm mb-2 line-through text-gray-700">
                        M.R.P {items?.product_variants[0]?.price}
                      </p>
                      <Link to={`/Product/${items?._id}`} className="mx-0">
                        <div className="flex justify-center items-center w-full">
                          <div style={{ fontFamily: "Lato" }} className="max-w-max text-center block py-4 px-10 w-full text-lg leading-5 text-white font-medium tracking-tighter font-heading  bg-[#2B9BB8] hover:bg-sky-600 focus:ring-2 focus-ring-sky-500 focus-ring-opacity-50 rounded-xl">
                            Buy Now
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      )}
    </div>
  );
}

export default Sectionfive;
