import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authenticateSelector, checkAuth } from "../../api/authSlice";
import { useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { fetchUserOrder } from "../../api/order";
import Component from '../../Components'
import {EditTwoTone} from '@ant-design/icons'


const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user,isAuthenticate } = useSelector(authenticateSelector);
 

  useEffect(() => {
    dispatch(fetchUserOrder(user?._id));
  }, [user]);

  // useEffect(() => {
  //   dispatch(checkAuth());
  // }, []);

  useEffect(() => {
    if (!isAuthenticate) {
      navigate("/profile");
    }
  }, [isAuthenticate, navigate]);

  return (
    <div>
      <Component>
      <div className="container mx-auto mt-2">
        <header className="text-center mb-3">
          {user ? (
            <h1 className="text-xl text-[#314387] font-bold capitalize">
              Welcome, {user.user_name}
            </h1>
          ) : (
            <h1 className="text-md text-[#314387] font-bold capitalize">
              Hi,Greeting on your return
            </h1>
          )}
        </header>
        <main className="flex flex-col md:flex-row space-y-4 md:space-y-0">
          {user ? (
            <section className="bg-white rounded-md shadow-md p-4 md:w-1/2">
              <div className="flex">
                <h2 className="text-lg font-semibold mb-4 underline">
                  Profile Information
                </h2>
                <Link to="/editprofile" className="ml-2 mb-2 text-blue-500">
                  {/* <FontAwesomeIcon icon={faEdit} /> */}
                  <EditTwoTone />
                </Link>
              </div>
              <div className="text-start">
              <p>
                <strong>UserName:</strong> {user.user_name}
              </p>
              <p>
                <strong>Email:</strong> {user.email}
              </p>
              <p>
                <strong>Phone number:</strong> {user.phone_number}
              </p>
              <p>
                <strong>Address:</strong> {user.address}
              </p>
              </div>
            </section>
          ) : (
            // Render login prompt or redirect to login page
            <p className="text-center">
              Please log in to view your profile.{" "}
              <Link className="text-blue-700" to="/profile">
                Login
              </Link>
            </p>
          )}
        </main>
      </div>
      </Component>
     
    </div>
  );
};

export default UserProfile;
