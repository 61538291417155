import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

const user = localStorage.getItem("userinfo")
  ? JSON.parse(localStorage.getItem("userinfo"))
  : null;

console.log(user);

export const initialState = {
  loading: false,
  hasErrors: false,
  isAuthenticate: token ? true : false,
  user: null,
  access: null,
  token: token,
  current: [],
  filter: [],
  currentUser: null,
};

console.log(initialState.user);


export const authenticateSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getlogin: (state) => {
      state.loading = true;
    },

    // getAuthenticate: (state, { payload }) => {
    //   console.log(payload);

    //   state.loading = false;
    //   state.isAuthenticate = true;
    //   state.user = payload.user;
    //   state.token = payload.token;
    //   console.log(user);
    // },


    getAuthenticate: (state, { payload }) => {
      console.log(payload);
      state.user= payload.user
      state.loading = false;
      state.isAuthenticate = true;
    
      // Check if payload includes user information
      if (payload.user) {
        state.user = payload.user;
      }
    
      state.token = payload.token;
    },

    getFilterData: (state, { payload }) => {
      console.log(payload);

      state.loading = false;
      state.current = payload;
    },

    isAuthenticateError: (state) => {
      state.hasErrors = true;
      state.loading = false;
      state.isAuthenticate = false;
    },
    getCurrentUser: (state, { payload }) => {
      console.log({ k: payload });

      state.loading = false;
      state.currentUser = payload;
    },

    getUserProfile: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.user = payload.user;
      state.access = payload.access;
      state.isAuthenticate = true;

      console.log(user);
    },
    getFilter: (state, { payload }) => {
      state.loading = false;
      state.filter = payload;
    },
  },
});

export const {
  getlogin,
  getuser,
  getFilter,
  getUserProfile,
  getAuthenticate,
  isAuthenticateError,
} = authenticateSlice.actions;

export const authenticateSelector = (state) => state.auth;

export default authenticateSlice.reducer;

export const logOut = () => async (dispatch) => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("userinfo");
    window.location.href = "/";

    // Show a toast message for successful logout
    toast.success('Logout successful!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // 2 seconds
    });
  } catch (error) {
    dispatch(isAuthenticateError());
    // Show a toast message for logout error
    toast.error('Logout failed. Please try again.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // 2 seconds
    });
  }
};

export const fetchlogin = (logindata) => async (dispatch) => {
  console.log(logindata);
  const key = "login";
  dispatch(getlogin());
  message.loading({ content: "Loading...", key });

  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/auth",
      logindata,
      config
    );
    dispatch(getAuthenticate(data));
    localStorage.setItem("token", data.token);
    localStorage.setItem("userinfo", JSON.stringify(data.user));
    data && message.success({ content: data.msg, key, duration: 2 });
    // Display success message using react-toastify
  } catch (error) {
    // Display error message using react-toastify

    error &&
      message.error({ content: error.response.data.msg, key, duration: 1 });
    dispatch(isAuthenticateError());
  }
};



export const fetchUserRegister = (Regdata) => async (dispatch) => {
  console.log(Regdata);
  const key = "login";
  dispatch(getlogin());
  message.loading({ content: "Loading...", key });
  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/register",
      Regdata,
      config
    );

    console.log(data);
    dispatch(getAuthenticate(data));

    localStorage.setItem("token", data.token);
    localStorage.setItem("userinfo", JSON.stringify(data.curr_user));
    data && message.success({ content: data.msg, key, duration: 2 });
  } catch (error) {
    console.log(error)
    error &&
      message.error({ content: error.response.data.msg, key, duration: 1 });
    dispatch(isAuthenticateError());
  }
};

export const updateUser = (id, values) => async (dispatch) => {
  console.log(id);

  const key = "admin";
  dispatch(getlogin());
  message.loading({ content: "loading...", key });

  try {
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/user/${id}`,
      values,
      config
    );

    console.log(data);

    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(getAuthenticate());
  } catch ({ response }) {
    dispatch(isAuthenticateError());
    // response.data && message.success({ content: response.data.msg, key, duration: 2 });
  }
};

export const fetchAdminProfile = (token) => async (dispatch) => {
  const loginConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  dispatch(getlogin());
  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + "/profile",
      loginConfig
    );
    dispatch(getUserProfile(data));
  } catch (error) {
    error && message.error("Authentication Failure");
    dispatch(logOut());
  }
};

export const fethFilter = (value, filter) => async (dispatch) => {
  let a = [];
  if (!value) {
    return dispatch(getFilter(a));
  }
  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + `/${value}?search=${filter}`,
      config
    );
    dispatch(getFilter(data));
  } catch (error) {
    dispatch(isAuthenticateError());
  }
};

export const checkAuth = () => async (dispatch) => {
  const token = localStorage.getItem("token");

  const loginConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + "/profile",
      loginConfig
    );
    dispatch(getUserProfile(data));
  } catch (error) {
    console.log(error);
    // error && message.error("Authentication Failure");
    // error && localStorage.removeItem("token");
    // window.location.href = "/";
    // dispatch(logOut());
  }
};


export const findUserOTP = (values) =>async dispatch =>{
  console.log(values)
    dispatch(getlogin())
    try{
      const {data} = await axios.get(keyUri.BACKEND_URI+`/findOneUser/${values.phone_number}`)
      console.log(data)
      dispatch(getAuthenticate(data));
      localStorage.setItem('token', data.accessToken)
      localStorage.setItem("userinfo", JSON.stringify(data.user));
      data && message.success({ content: `OTP Sent Succesfully to ${data?.user?.phone_number}`,duration: 2 });

    }catch(error){
      error && message.error({ content: error.response.data.msg, duration: 5 });
      dispatch(isAuthenticateError())
     }
}
