import React, {useEffect,useState} from 'react'
import Layout from '../../Components'
import Catagoriespage from './Catagoriespage'
import {fetchOneCategory, categorySelector } from '../../api/catagories' 
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {fetchAllcategoryProduct, productSelector} from '../../api/products'

const Index = () => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const {products} = useSelector(productSelector)
  const {currentCategory } = useSelector(categorySelector)
  
  // console.log(currentCategory);
console.log(products);

  useEffect(()=>{

    dispatch(fetchOneCategory(id))
    dispatch(fetchAllcategoryProduct(id))
    
    }, [dispatch, id])


  return (
    <Layout>
        <Catagoriespage data={currentCategory} products={products} />
    </Layout>
  )
}

export default Index